// Template of Promise request for API
/**
 * call API to check validity of num siret by INSEE API
 * @param numSiret
 * @returns {Promise<unknown>}
 */
export default function checkNumSiretValidity(numSiret) {
    return new Promise((resolve, reject) => {
        const req = new Request(
            `${process.env.REACT_APP_API_URL}/user/insee-api/verif-siret`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({numSiret}),
            }
        );
        fetch(req)
            .then((res) => {
                res.json()
                    .then((json) => {
                        resolve(json);
                    });
            })
            .catch((e) => {
                console.error(e);
                reject(e);
            });
    });
}
