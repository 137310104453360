// used for inscription
export const individualType = "individual";
export const independentType = "independent";
export const sportsorganizationType = "sportsorganization";
export const organizationType = "organization";

export const POLE_EMPLOI_ANNOUNCEMENT_TYPE = "PoleEmploi"

// used everywhere else
const AccountType = {
  INDIVIDUAL: "IndividualUser", // PA
  INDEPENDENT_PROFESSIONAL: "IndependentProfessionalUser", // PS
  ORGANIZATION: "OrganizationUser", // ONS
  SPORTS_ORGANIZATION: "SportsOrganizationUser", // OS
};

export default AccountType;
