export default class Sponsorship {
    static get (code) {
        return new Promise((resolve, reject) => {
            const req = new Request(
                `${process.env.REACT_APP_API_URL}/user/sponsorship/get/${code}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            fetch(req)
                .then((res) => {
                    res.json()
                        .then((json) => {
                            if (json.success) {
                                resolve({
                                    success: json.success,
                                    code: json.code
                                });
                            } else {
                                reject({ success: false });
                            }
                        });
                })
                .catch((e) => {
                    console.error(e);
                    reject(e);
                });
        });
    }
}
