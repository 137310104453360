import {Avatar, Grid, ListItemText} from "@mui/material";
import { styled } from '@mui/material/styles';
import { ExpandMore } from "@mui/icons-material";
import React from "react";
import {getUserAlt} from "../utils/general/imgAltDynamic";
import theme from "../theme";
import {generateUrlImage} from "../utils/general/generateUrlImage";

const PREFIX = 'AvatarContainer';

const classes = {
    avatar: `${PREFIX}-avatar`,
    avatarContainer: `${PREFIX}-avatarContainer`,
    username: `${PREFIX}-username`,
    usernameIsLarge: `${PREFIX}-usernameIsLarge`,
};

const Root = styled(Grid)((
    {
        theme
    }
) => ({
    [`& .${classes.avatar}`]: {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        height: theme.spacing(4),
        width: theme.spacing(4),
    },

    [`&.${classes.avatarContainer}`]: {
        padding: theme.spacing(0),
    },

    [`& .${classes.username}`]: {
        fontWeight: "bold",
        fontSize: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    [`& .${classes.usernameIsLarge}`]: {
        fontWeight: "bold",
        fontSize: theme.spacing(3),
        marginLeft: theme.spacing(1),
    }
}));


/**
 * Container for the avatar component
 * @param user user's avatar
 * @param isLarge if the avatar is large
 * @param expandIcon
 * @param usernameColor color of the text under the avatar
 * @returns
 */
function AvatarContainer({
    user,
    isLarge = false,
    expandIcon = false,
    usernameColor = "black",
    withText = true
}) {

    const avatarUrl = generateUrlImage(user.profileImageName);
    if (isLarge){
        return (
            <Root
                container
                sx={{
                    minWidth: "300px",
                    maxWidth: '320px',
                    p: (theme)=> theme.spacing(3),
                    mb: (theme)=> theme.spacing(2)
                }}
            >
                <Grid item display="flex" alignItems="center" xs={4}>
                    <Avatar
                        alt={getUserAlt(user)}
                        src={avatarUrl}
                        sx={{
                            height: theme.spacing(10),
                            width:  theme.spacing(10),
                            border: 2,
                            borderColor: 'primary.main'
                        }}
                    />
                </Grid>

                {withText &&
                    <Grid item display="flex" alignItems="center" xs={7}>
                        <ListItemText
                            primary={
                                user.name.length > 40 ?
                                    user.name.substring(0, 30) + '...' :
                                    user.name
                            }
                            classes={{ primary: classes.usernameIsLarge }}
                            style={{ color: usernameColor }}
                        />
                    </Grid>

                }
                {(expandIcon && withText) && <ExpandMore style={{ color: usernameColor }} />}
            </Root>
        );
    } else {
        return (
            <Root
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
                className={classes.avatarContainer}
            >
                <Avatar alt={getUserAlt(user)} src={avatarUrl} className={classes.avatar} />
                {withText &&
                <ListItemText
                    primary={
                        user.name.length >30 ?
                            user.name.substring(0, 30) + '...' :
                            user.name
                    }
                    classes={{ primary: classes.username }}
                    style={{ color: usernameColor }}
                />
                }
                {(expandIcon && withText) && <ExpandMore style={{ color: usernameColor }} />}
            </Root>
        );
    }

}

export default AvatarContainer;
