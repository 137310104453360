import {getDateDiff} from "../general/date_manipulation";

export const arrayIsValid = (array) => {
	return array !== null && array.length !== 0;
};

export const stringIsValid = (string, minLength= 1) => {
	return string !== null && string !== ""  && string.length >= minLength;
};

export const startDateIsValid = (startDate) => {
	return startDate !== null && startDate instanceof Date;
};

/**
 * fct to check if date end selected is Valid
 * @param {Date} endDate
 * @param {Date} startDate
 * @returns {boolean}
 */
export const endDateIsValid = (endDate, startDate) => {
	return (endDate instanceof Date && (endDate > startDate || getDateDiff( startDate, endDate, "D") === 0));
};

export const titleIsValid = (title) => {
	return title.length > 3;
};

export const urlIsValid = (url) => {
	return url.length > 2 && /^[a-zA-Z0-9-]+$/.test(url);
}

export const typeIsValid = (type) => {
	return type !== "";
};

export const activityIsValid = (activity) => {
	return activity.length !== 0;
};

export const periodIsValid = (periodSelected) => {
	return periodSelected.length !== 0;
};

export const contractTypeIsValid = (contractType) => {
	return contractType.length !== 0;
};

export const priceIsValid = (price) => {
	return price !== null && price !== "";
};

export const addressIsValid = (country) => {
	return country !== "";
};

export const addressCompleteIsValid = (address) => {
	if (address === null){
		return false;
	}
	return address.geometry.coordinates.length !== 0 && address.properties.label !== "" && address.properties.country !== "";
};

export const descriptionIsValid = (description) => {
	if (description[0].children[0].text.length > 49){
		return true;
	}else {
		let countTotalLetter = 0;
		for (let i =0; i<description.length ;i++){
			for (let j =0; j<description[i].children.length; j++){
				if (description[i].type === "paragraph"){
					countTotalLetter += description[i].children[j].text.length;
				} else if (description[i].type === "numbered-list"){
					countTotalLetter += description[i].children[j].children[0].text.length;
				}

				if (countTotalLetter > 49){
					return true;
				}
			}
		}
		return false;
	}

};

export const descriptionFAQAdminIsValid = (description) => {
	if (description[0].children[0].text.length > 20){
		return true;
	}else {
		let countTotalLetter = 0;
		for (let i =0; i<description.length ;i++){
			for (let j =0; j<description[i].children.length; j++){
				if (description[i].type === "paragraph"){
					countTotalLetter +=  typeof description[i].children[j].text === "undefined" ?
						description[i].children[j].children[0].text.length :
						description[i].children[j].text.length
					;
				} else if (description[i].type === "numbered-list"){
					countTotalLetter += description[i].children[j].children[0].text.length;
				}

				if (countTotalLetter > 20){
					return true;
				}
			}
		}
		return false;
	}

};

export const imageIsValid = (image) => {
	return image !== null;
};

export const imagesIsValid = (images) => {
	return images.length !== 0;
};


export const tagsIsValid = (tags) => {
	return tags != null && tags.length > 1;
}

export const completeLinkUrlIsValid = (url) => {
	try {
		new URL(url);
		return true;
	} catch (err) {
		return false;
	}
}
export const telIsValid = (phoneNumber) => {
	return /^[0-9]{4,12}$/.test(phoneNumber);
};
