import {DEFAULT_USER_IMAGE_PROFIL} from "../../constants/globalConstant";

export const generateUrlImage = (image, isPoleEmploi = false, isStaticImg= false, isBlogImage = false) => {
    if (isStaticImg){
        return process.env.REACT_APP_API_URL + "/static/static-img/" + image;
    }
    if (isPoleEmploi){
        if (image.includes('https')){
            return image
        } else {
            if (image === 'logo-pole-emploi.png') {
                return process.env.REACT_APP_API_URL + "/static/logo/" + image;
            } else {
                return process.env.REACT_APP_API_URL + '/static/img/pole-emploi/' + image;
            }
        }
    } else if (isBlogImage){
        return process.env.REACT_APP_API_URL +
            "/static/img/blog/" +
            image;
    } else {
        if (image === DEFAULT_USER_IMAGE_PROFIL){
            return process.env.REACT_APP_API_URL +
            "/static/logo/" +
            image;
        }else {
            return process.env.REACT_APP_API_URL +
            "/static/img/" +
            image;
        }
    }
}
