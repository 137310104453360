import {TextField, Typography} from "@mui/material";
import React from "react";
import theme from "../../../theme";

export default function TextInputContent({
    name,
    type,
    labelText,
    labelShrink,
    placeholder,
    fullWidth,
    margin,
    InputProps,
    value,
    handleChange,
    error,
    warn,
    helperText,
    disabled,
    required,
    rows =  null,
    ...props
}) {
    const inputLabelPropsValue = labelShrink ? { shrink: true } : null;
    return <>
        <TextField
            {...props}
            name={name}
            label={labelText}
            placeholder={placeholder}
            fullWidth={fullWidth}
            margin={margin}
            InputLabelProps={inputLabelPropsValue}
            InputProps={InputProps}
            type={type}
            value={value}
            onChange={handleChange}
            error={error}
            helperText={helperText}
            disabled={disabled}
            required={required}
            multiline={!!rows}
            rows={4}
        />
        {warn &&
            <Typography paragraph sx={{color: theme.palette.warning.main}}>{warn}</Typography>
        }
    </>;
}