import React, { useEffect, useState } from "react";
import HomeBetaAlert from "./components/PopUpAlert/HomeBetaAlert";
import Routes from "./routes";
import Chat from "./services/chat/index";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { parseJwt } from "./config/AuthVerifyJWTValidity";
import { setUserDisconnected, setUserProfileInformation } from "./actions";
import getUserInfoAPI from "./services/getUserInfoAPI";
import Loader from "./components/Loader/Loader";
import { Skeleton } from "@mui/material";
import theme from "./theme";

/**
 * Global component
 * @returns
 */
const App = () => {

    const dispatch = useDispatch();
	const [ready, setReady] = useState(false);
	
	//initialyse the chat and the sockets
	useEffect(() => {
		Chat.init();
	}, []);

    useEffect(() => {
        const token = Cookies.get("token");
        if (typeof token !== "undefined") {
            const decodedJwt = parseJwt(token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                dispatch(setUserDisconnected());
				setReady(true);
            } else {
                const decodedJwt = parseJwt(token);
                getUserInfoAPI(decodedJwt.id)
                    .then((res) => {
                        dispatch(setUserProfileInformation(res.data));
						setReady(true);
                    })
                    .catch((error) => {
						console.error(error);
						dispatch(setUserDisconnected());
						setReady(true);
					});
            }
        } else {
			setReady(true);
		}
    }, [dispatch]);

	if (ready) {
		return <>
			{/* Beta Dialog */}
			{localStorage.getItem("homeBetaAlert") === null ? <HomeBetaAlert /> : null}
			{/* Navigation with react-router-dom v6 */}
			<Routes />
		</>
	} else {
		return <>
			<Skeleton animation="wave" variant="rectangular" width={'100%'} height={64} sx={{ bgcolor: theme.palette.primary.main }} />
			<Loader />
		</>
	}
};

export default App;
