import * as React from "react";
import {Autocomplete, Checkbox, TextField} from "@mui/material";
import {AllCountryCode} from "../../../constants/typeToText";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { search } from "country-js";

export const CountryNameOrIndicatorChoiceInputContent = ({
                                name,
                                labelText,
                                labelShrink,
                                fullWidth,
                                value,
                                handleChange,
                                isIndicatorInput = false,
                                InputProps = null
                            }) => {

    const inputLabelPropsValue = labelShrink ? { shrink: true } : null;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <Autocomplete
            options={AllCountryCode}
            getOptionLabel={(country) => isIndicatorInput ?
                    search(country.code)[0].phone :
                    country.value
            }
            renderOption={(props, country, {selected}) => {

                const code = country.code
                return (
                    <li {...props} key={Math.random()*1000}>
                        <Checkbox
                            id={code.toUpperCase()}
                            className={"autocomplete-" + country}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8}}
                            checked={selected}
                        />
                        <img
                            style={{paddingRight: '0.5rem'}}
                            loading="lazy"
                            src={`https://flagcdn.com/20x15/${code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/40x30/${code.toLowerCase()}.png 2x, 
                        https://flagcdn.com/60x45/${code.toLowerCase()}.png 3x`}
                            width="20"
                            height="15"
                            alt={country.value + " flag"} />
                        { isIndicatorInput ?
                            search(code)[0].phone :
                            country.value
                        }
                    </li>
                )}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    label={labelText}
                    fullWidth={fullWidth}
                    InputLabelProps={inputLabelPropsValue}
                    type="text"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: null,
                        startAdornment :
                            InputProps?.startAdornment
                    }}
                />
            )}
            value={value}
            onChange={(e, newValue) => {

                if (newValue != null){
                    handleChange(newValue);
                }
            }}
        />
    );
}
