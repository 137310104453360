import React from "react";
import {useParams} from "react-router-dom";
import ArticleDetailContainer from "./container/ArticleDetailContainer";

const ArticleDetail = () => {
    const { url } = useParams();

    return (
        <ArticleDetailContainer
            url={url}
        />
    )
}

export default ArticleDetail