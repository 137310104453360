import React, {useEffect, useState} from "react";
import ArticleContent from "../components/ArticleContent";
import getArticleDetailByUrlAPI from "../../../services/Blog/getArticleDetailByUrlAPI";
import {useSnackbar} from "notistack";
import {globalConfigDurationNotistack} from "../../../constants/globalConstant";
import {useNavigate} from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import {Helmet} from "react-helmet";

const ArticleDetailContainer = ({url}) => {
    const pathname = window.location.pathname;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [article, setArticle] = useState(null);
    const [similarArticle, setSimilarArticle] = useState([]);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        getArticleDetailByUrlAPI(url)
            .then((data) => {
                setArticle(data.article);
                setSimilarArticle(data.similarArticle)
                setLoad(false)
            })
            .catch((err) => {
                console.error("Get detail article ",err)
                enqueueSnackbar('L\'article recherché n\'existe pas', {
                    variant: 'error',
                    autoHideDuration: globalConfigDurationNotistack
                });
                navigate('/404');
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    if (load){
        return <Loader />
    }
    else {
        return (
            <>
                <Helmet>
                    <title>{article.title} - WGY</title>
                    <meta name="description" content={`${article.shortDescription} - ${article.title}`}/>
                    <meta property="og:title" content={article.title}/>
                    <meta property="og:description" content={article.shortDescription}/>
                    <meta property="og:url" content={window.location.href}/>
                </Helmet>
                <ArticleContent
                    article={article}
                    similarArticle={similarArticle}
                />
            </>
        )
    }
}

export default ArticleDetailContainer