import React from "react";
import {Grid, Typography} from "@mui/material";
import ArticleCard from "../../Blog/components/ArticleCard";

const SimilarArticle = ({similarArticle}) => {

    return (
        <>
            <Typography variant={"h3"} marginY={2}>
                Ces articles pourraient vous intéresser
            </Typography>
            <Grid container spacing={2}>
                {similarArticle.map((article, index) => (
                    <ArticleCard
                        article={article}
                        key={index}
                    />
                ))}

            </Grid>
        </>
    )
}

export default SimilarArticle