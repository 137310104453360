//-----------------------------------------
//     ROUTES NO NEED TO BE CONNECTED     |
//-----------------------------------------

export const ROUTE_HOME = "/";

export const ROUTE_TERMS_OF_USE = "/CGU";

export const ROUTE_TERMS_OF_SALE = "/CGV";

export const ROUTE_COOKIE_POLICY = "/politique-des-cookies";

export const ROUTE_RIGHTS_AND_OBLIGATIONS = "/droits-et-obligations";

export const ROUTE_PRIVACY_POLICY = "/charte-de-confidentialite";

export const ROUTE_ACCESS_ACCOUNT = "/login";

export const ROUTE_PROFESSIONAL_ANNOUNCEMENTS = "/professionnel/offres/emploi-sport/liste";

export const ROUTE_PARTICULAR_ANNOUNCEMENTS = "/particulier/offres/activite-sport/liste";

/**
 * Announce for professional user
 * > localhost/emploi-sport/:activity/:id
 * @activity {string} use for better SEO
 * @id {string} of the announcement
 */
export const ROUTE_PROFESSIONAL_ANNOUNCEMENT_INFO = "/offres/emploi-sport/moniteur";
/**
 * Announce for particular user
 * > localhost/activite-sport/:activity/:id
 * @activity {string} use for better SEO
 * @id {string} of the announcement
 */
export const ROUTE_PARTICULAR_ANNOUNCEMENT_INFO = "/offres/activite-sport";

/**
 * Validate an announcement
 * > localhost/annonce/validite/:id
 * @id {string} of the announcement validate
 */
export const ROUTE_ANNOUNCEMENT_VERIFICATION = "/annonce/validite";

/**
 * Validate an account
 * > localhost/annonce/validite/:id
 * @id {string} of account validate
 */
export const ROUTE_MAIL_VERIFICATION = "/verification/mail";

/**
 * Show a profile
 * > localhost/profile/:id
 * @id {string} of the user
 */
export const ROUTE_PROFILE = "/profile";

/**
 * Show announcements of user
 * > localhost/profile/annonces/:id
 * @id {string} of the user
 */
export const ROUTE_USER_ANNOUNCEMENTS = "/profile/annonces";

/**
 * Forgotten our password
 * > localhost/user/forgotten-password/:id
 * @id {string} of the user
 */
export const ROUTE_FORGOTTEN_PASSWORD = "/user/forgotten-password";

/**
 * Deletion our account
 * > localhost/user/forgotten-password/:id
 * @id {string} of the user
 */
export const ROUTE_ACCOUNT_DELETION= "/user/account-deletion";

/**
 * localhost/FAQ/:name
 * @name {string} of the page to show
 */
export const ROUTE_FAQ = "/FAQ";

/**
 * localhost/FAQ/:name
 * @name {string} of the page to show
 */
export const ROUTE_VERIFY = "/verify";

// BLOG

export const ROUTE_BLOG_HOME = '/blog';

export const ROUTE_ARTICLE_DETAIL = '/blog/:url';

//---------------------------
//     ROUTES CONNECTED     |
//---------------------------

export const ROUTE_FAVORIS = "/favoris";

export const ROUTE_CREATE_ANNOUNCEMENT = "/creer-annonce";

export const ROUTE_BOOST_ANNOUNCEMENT = "/boost-annonce";

export const ROUTE_ACCOUNT_ANNOUNCEMENTS = "/compte/annonces";

export const ROUTE_ACCOUNT_PROFILE = "/compte/profile";

export const ROUTE_ACCOUNT_SETTINGS = "/compte/parametres";

/**
 * localhost/messagerie/:id
 * @type {string}
 */
export const ROUTE_ACCOUNT_RESERVATIONS = "/compte/reservations";

/**
 * localhost/messagerie
 * localhost/messagerie/:type
 * @type {string} of the announcement
 */
export const ROUTE_UPDATE_ANNOUNCEMENT = "/compte/annonces/modifier";

/**
 * localhost/messagerie
 * localhost/messagerie/:id
 * @id {string} of the room
 */
export const ROUTE_CHAT = "/messagerie";

/**
 * /boost-annonce/paiement
 */
export const ROUTE_STRIPE_PAYEMENT = "/boost-annonce/paiement";

/**
 * /boost-annonce/paiement/status
 */
export const ROUTE_STRIPE_CHECK_STATUS = "/boost-annonce/paiement/status";


/**
 * /test/payment/succeed/{idAd}
 */
export const ROUTE_STRIPE_PAYMENT_SUCCEED = "/boost-annonce/paiement/recapitulatif";

export const ROUTE_INVOICES = "/factures";

/**
 *
 */
export const ROUTE_ACCOUNT_SPONSORSHIP = "/compte/parrainage";
//---------------------------
//     ROUTES ADMIN     |
//---------------------------

/** @type {string} /dashboard OR /dashboard/{view} */
export const ROUTE_ADMIN_DASHBOARD = "/dashboard/"
