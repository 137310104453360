import {MenuItem, TextField} from "@mui/material";
import React from "react";

export default function ChoiceInputContent({
    name,
    value,
    labelText,
    labelShrink,
    fullWidth,
    margin,
    values,
    handleChange,
    error,
    helperText,
    disabled,
    ...props
}) {
    const inputLabelPropsValue = labelShrink ? { shrink: true } : null;
    return (
        <TextField
            {...props}
            name={name}
            select
            label={labelText}
            fullWidth={fullWidth}
            margin={margin}
            InputLabelProps={inputLabelPropsValue}
            type="text"
            value={value}
            onChange={handleChange}
            error={error}
            helperText={helperText}
            disabled={disabled}>
            {values.map((option, index) => (
                <MenuItem id={index} key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    );
}