import React, {useEffect, useState} from 'react'
import {Box, Skeleton} from "@mui/material";
import config from "./config-pub.json";

export default function PubDisplayInFeed (props) {

    const {currentPath} = props

    const [loaded, setLoaded] = useState(true);


    useEffect(() => {
        document.addEventListener('mdl-componentupgraded', function() {
            if (!document.getElementById('adsbygooglejs')) {
                setLoaded(false);
            }
        });
    }, []);

    useEffect(() => {
        window.adsbygoogle = window.adsbygoogle || []
        window.adsbygoogle.push({});
        setLoaded(false);
    }, [currentPath])

    return (
        <Box key={currentPath} sx={{maxHeight: "200px", overflow: "hidden"}}>
            {loaded &&
                <Skeleton sx={{ bgcolor: 'grey.100', borderRadius: "1rem" }} variant="rounded" animation="wave" height={200}  />
            }
            <ins className="adsbygoogle"
                 style={{display: "block"}}
                 data-ad-format="fluid"
                 data-ad-layout-key={JSON.parse(JSON.stringify(config.layoutKey))}
                 data-ad-client={JSON.parse(JSON.stringify(config.client))}
                 data-ad-slot={JSON.parse(JSON.stringify(config.inFeedsSlot))}/>
        </Box>)
}
