import React from "react";
import {TextInput} from "../../Inputs";

const MetaDescriptionTextInputContent = ({
    labelText,
    value,
    name,
    handleChange,
    error,
    helperText,
}) => {
    return (
        <TextInput
            fullWidth
            labelText={labelText}
            value={value}
            name={name}
            handleChange={handleChange}
            error={error}
            helperText={
                error &&
                helperText
            }
            rows={2}
            warn={
                typeof value === "undefined" || value.length < 120 || value.length > 158 ?
                    `${typeof value !== "undefined" ? 
                        `(${value.length} caractères / 158 caractères)` :
                        ''
                    } 
                     La longueur de votre méta description doit être comprise entre 150 et 160 
                     caractères pour une longueur optimale, et cela inclut les espaces.`
                : null
            }
        />
    )

}

export default MetaDescriptionTextInputContent