import React from "react";
import {Box, Chip, Container, Grid, Hidden, Stack, Typography} from "@mui/material";
import TitlePage from "../../../components/TitlePage";
import {generateUrlImage} from "../../../utils/general/generateUrlImage";
import getArticleAlt from "../../../utils/general/getArticleAlt";
import {useNavigate} from "react-router-dom";
import PubDisplayVertical from "../../ListAnnouncements/components/PubDisplayVertical";
import {serializeHTML} from "../../../components/AdminTextEditor/utils/serializeHTML";
import SimilarArticle from "./SimilarArticle";

const ArticleContent = ({article, similarArticle}) => {

    const descriptionInHtml = serializeHTML({children: article.content});
    const navigate = useNavigate();

    const redirectToListArticleOfCategory = () => {
        navigate(`/blog?category=${article.category}`)
    }

    return (
        <>
            <Hidden lgDown>
                <Box
                    sx={{
                        position: "absolute",
                        left: "5vw",
                        top: "30vh",
                        width: "250px",
                        height: "600px",
                    }}
                >
                    <PubDisplayVertical/>
                </Box>
            </Hidden>
            <Container sx={{minHeight: "80vh"}}>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} sm={10} md={8} lg={6} xl={8} container flexDirection={"column"} alignItems={"center"}>
                        <TitlePage
                            title={article.title}
                           // subTitle={`Posté le ${new Date(article.publishAt).toLocaleDateString()} - ${article.category}`}
                        />
                        <Typography component="h2" variant="subtitle">
                            Posté le {new Date(article.publishAt).toLocaleDateString()} -{" "}
                            <Typography
                                component={"span"}
                                variant={"subtitle"}
                                sx={{
                                    ml: '0px!important',
                                    textDecoration: 'underline',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    }
                                }}
                                onClick={redirectToListArticleOfCategory}
                            >
                                {article.category}
                            </Typography>
                        </Typography>
                        <Stack direction="row" flexWrap={"wrap"} >
                            {article.tags.map((tag, index) => (
                                <Chip label={tag} key={index} sx={{mr:1, mb:1}} color={"primary"}/>
                            ))}
                        </Stack>
                        <img
                            src={generateUrlImage(article.imageMain, false, false,true)}
                            alt={getArticleAlt(article)}
                            style={{minHeight: "250px", maxHeight: "500px", maxWidth: "100%"}}
                        />
                        <div style={{fontFamily: '"Roboto","Helvetica","Arial",sans-serif', marginTop: '16px'}} dangerouslySetInnerHTML={{__html:descriptionInHtml}}></div>

                    </Grid>
                    {article.urlVideo &&
                        <Grid item xs={12} container justifyContent={"center"} mt={2}>
                            <iframe width="560" height="315" src={article.urlVideo}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen></iframe>
                        </Grid>
                    }
                </Grid>

                <SimilarArticle
                    similarArticle={similarArticle}
                />
            </Container>
            <Hidden lgDown>
                <Box
                    sx={{
                        position: "absolute",
                        right: "5vw",
                        top: "30vh",
                        width: "250px",
                        height: "600px",
                    }}
                >
                    <PubDisplayVertical/>
                </Box>
            </Hidden>
        </>
    )
}

export default ArticleContent
