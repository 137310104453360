import { SET_CONNECTION } from "../constants/ActionTypes";

const initialState = {
  mail: "",
  psw: "",
};

const ConnectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONNECTION:
      return action.payload.connection;
    default:
      return state;
  }
};

export default ConnectionReducer;
