import {
	ADD_CHAT_ROOM,
	NEW_CHAT_MESSAGE,
	SET_CHAT_CANCEL_RESERVATION,
	SET_CHAT_CANCEL_RESERVATION_WITHOUT_CHATROOM,
	SET_CHAT_OFFER,
	SET_CHAT_RESERVATION,
	SET_CHAT_ROOMS,
	SET_READ_ALL_MESSAGE,
} from '../constants/ActionTypes';

const initialState = {
	rooms: [],
};

const ChatReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CHAT_ROOMS: {
			return {
				...state,
				rooms: action.payload.rooms,
			};
		}
		case ADD_CHAT_ROOM: {
			if (state.rooms.filter((room) => room._id === action.payload.room._id).length > 0) {
				return state;
			} else {
				return {
					...state,
					rooms: [action.payload.room, ...state.rooms],
				};
			}
		}

		case NEW_CHAT_MESSAGE: {
			const { message, user } = action.payload;
			// find or create room
			const room = state.rooms.find((room) => room._id === message.chatRoom) || {
				_id: message.chatRoom,
				messages: [],
			};
			// add message to room
			const roomWithMessage = {
				...room,
				messages: [...room.messages, message],
			};

			if (room.lastRead) {
				if (message.sender !== user.data._id) room.lastRead[user.data._id].number += 1;
				else room.lastRead[user.data._id].number = 0;
			}

			return {
				...state,
				// set room as first in rooms
				rooms: [
					// room with message is the first
					roomWithMessage,
					// other rooms without the room with message
					...state.rooms.filter((room) => room._id !== roomWithMessage._id),
				],
			};
		}
		case SET_READ_ALL_MESSAGE: {
			const {
				payload: { chatRoomId, user },
			} = action;
			const chatRoomIndex = state.rooms.findIndex((room) => room._id === chatRoomId);
			const chatRoom = state.rooms[chatRoomIndex];
			chatRoom.lastRead[user.data._id].number = 0;

			let res = [...state.rooms];
			res[chatRoomIndex] = chatRoom;

			return {
				...state,
				rooms: res,
			};
		}

		case SET_CHAT_OFFER: {
			const {
				payload: { chatRoomId, offer },
			} = action;

			// find offer's room
			const chatRoom = state.rooms.find((room) => room._id === chatRoomId);

			// update offer
			const chatRoomWithNewOffer = {
				...chatRoom,
				offer: {
					...chatRoom.offer,
					actual: offer,
				},
			};

			return {
				...state,
				rooms: [
					// room with new offer
					chatRoomWithNewOffer,
					// other rooms without the room with message
					...state.rooms.filter((room) => room._id !== chatRoomWithNewOffer._id),
				],
			};
		}

		case SET_CHAT_RESERVATION: {
			const {
				payload: { chatRoomId, reservation },
			} = action;

			// find offer's room
			const chatRoom = state.rooms.find((room) => room._id === chatRoomId);

			return {
				...state,
				rooms: [
					{
						...chatRoom,
						reservation: reservation
					},
					...state.rooms.filter((room) => room._id !== chatRoom._id),
				],
			};
		}

		case SET_CHAT_CANCEL_RESERVATION: {
			const {
				payload: { chatRoomId, reservation },
			} = action;

			const chatRoom = state.rooms.find((room) => room._id === chatRoomId);

			return {
				...state,
				rooms: [
					{
						...chatRoom,
						reservation: reservation
					},
					...state.rooms.filter((room) => room._id !== chatRoom._id),
				],
			};
		}

		case SET_CHAT_CANCEL_RESERVATION_WITHOUT_CHATROOM: {
			const {payload: {reservation}} = action;

			const chatRoom = state.rooms.find((room) => room.reservation._id === reservation._id);

			return {
				...state,
				rooms: [
					{
						...chatRoom,
						reservation: reservation,
					},
						...state.rooms.filter((room) => room._id !== chatRoom._id),
				]
			};
		}

		default:
			return state;
	}
};

export default ChatReducer;
