import {InputAdornment, TextField} from "@mui/material";
import MailSharpIcon from "@mui/icons-material/MailSharp";
import React from "react";

export default function MailInputContent({
    name,
    value,
    labelText,
    labelShrink,
    placeholder,
    fullWidth,
    margin,
    handleChange,
    error,
    helperText,
    ...props
}) {
    const inputLabelPropsValue = labelShrink ? { shrink: true } : null;

    return (
        <TextField
            {...props}
            name={name}
            label={labelText}
            placeholder={placeholder}
            fullWidth={fullWidth}
            margin={margin}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <MailSharpIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
            InputLabelProps={inputLabelPropsValue}
            type="email"
            value={value}
            onChange={handleChange}
            error={error}
            helperText={helperText}
            disabled={handleChange ? false : true}
        />
    );
}