import {
	Box,
	Divider,
	Drawer,
	Hidden,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import ReceiptIcon from '@mui/icons-material/Receipt';
import AnnouncementSharpIcon from "@mui/icons-material/AnnouncementSharp";
import HistorySharpIcon from "@mui/icons-material/HistorySharp";
import HourglassFullSharpIcon from "@mui/icons-material/HourglassFullSharp";
import MessageSharpIcon from "@mui/icons-material/MessageSharp";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import PowerSettingsNewSharpIcon from "@mui/icons-material/PowerSettingsNewSharp";
import RecentActorsSharpIcon from "@mui/icons-material/RecentActorsSharp";
import SchoolSharpIcon from "@mui/icons-material/SchoolSharp";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import SecurityIcon from '@mui/icons-material/Security';
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate  } from "react-router-dom";
import { setUserDisconnected } from "../../actions";
import AccountType from "../../constants/AccountType";
import AvatarContainer from "../AvatarContainer";
import {
	ROUTE_ACCOUNT_ANNOUNCEMENTS,
	ROUTE_ACCOUNT_PROFILE,
	ROUTE_ACCOUNT_RESERVATIONS,
	ROUTE_ACCOUNT_SETTINGS,
	ROUTE_ACCOUNT_SPONSORSHIP,
	ROUTE_ADMIN_DASHBOARD,
	ROUTE_CHAT,
	ROUTE_FAVORIS,
	ROUTE_HOME,
	ROUTE_INVOICES
} from "../../config/routesConstant";
import {globalConfigDurationNotistack} from "../../constants/globalConstant";
import HandshakeIcon from '@mui/icons-material/Handshake';
import FavoriteSharpIcon from "@mui/icons-material/FavoriteSharp";

/**
 * Component to display drawer menu
 * @param user current user
 * @param isShown if true, the drawer is open
 * @param onClose callback to call when user want to close drawer
 * @param classes css classes
 */
function DrawerComponent({ user, isShown = false, onClose, classes }) {
	// get the browser history
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// to generate a popup
	const { enqueueSnackbar } = useSnackbar();

	const items = [
		{
			text: "Profil",
			hasBorders: false,
			onClick: () => {
				navigate(ROUTE_ACCOUNT_PROFILE);
			},
			icon: <PersonSharpIcon />,
			for: [
				AccountType.INDIVIDUAL,
				AccountType.INDEPENDENT_PROFESSIONAL,
				AccountType.ORGANIZATION,
				AccountType.SPORTS_ORGANIZATION,
			],
		},
		{
			text: "Messagerie",
			hasBorders: false,
			onClick: () => {
				navigate(ROUTE_CHAT);
			},
			icon: <MessageSharpIcon />,
			for: [
				AccountType.INDIVIDUAL,
				AccountType.INDEPENDENT_PROFESSIONAL,
				AccountType.ORGANIZATION,
				AccountType.SPORTS_ORGANIZATION,
			],
		},
		{
			text: "Mes annonces",
			hasBorders: false,
			onClick: () => {
				navigate(ROUTE_ACCOUNT_ANNOUNCEMENTS);
			},
			icon: <AnnouncementSharpIcon />,
			for: [
				AccountType.INDEPENDENT_PROFESSIONAL,
				AccountType.ORGANIZATION,
				AccountType.SPORTS_ORGANIZATION,
			],
		},
		{
			text: "Mes favoris",
			hasBorders: false,
			onClick: () => {
				navigate(ROUTE_FAVORIS);
			},
			icon: <FavoriteSharpIcon />,
			for: [
				AccountType.INDIVIDUAL,
				AccountType.INDEPENDENT_PROFESSIONAL,
				AccountType.ORGANIZATION,
				AccountType.SPORTS_ORGANIZATION,
			],
		},
		{
			text: "Mes réservations",
			hasBorders: false,
			onClick: () => {
				navigate(ROUTE_ACCOUNT_RESERVATIONS);
			},
			icon: <HistorySharpIcon />,
			for: [AccountType.INDIVIDUAL],
		},
		{
			text: "Mes factures",
			hasBorders: false,
			onClick: () => {
				navigate(ROUTE_INVOICES);
			},
			icon: <ReceiptIcon />,
			for: [
				AccountType.INDEPENDENT_PROFESSIONAL,
				AccountType.SPORTS_ORGANIZATION,
				AccountType.ORGANIZATION,
			],
		},
		{
			text: "Réservations de mes cours",
			hasBorders: false,
			onClick: () => {
				navigate(ROUTE_ACCOUNT_RESERVATIONS + "/mes-cours");
			},
			icon: <HourglassFullSharpIcon />,
			for: [
				AccountType.INDEPENDENT_PROFESSIONAL,
				AccountType.SPORTS_ORGANIZATION,
			],
		},
		{
			text: "Professionnels recrutés",
			hasBorders: false,
			onClick: () => {
				navigate(ROUTE_ACCOUNT_RESERVATIONS + "/professionnels-recrutes");
			},
			icon: <RecentActorsSharpIcon />,
			for: [
				AccountType.ORGANIZATION,
				AccountType.SPORTS_ORGANIZATION,
				AccountType.INDEPENDENT_PROFESSIONAL,
			],
		},
		{
			text: "Emplois acceptés",
			hasBorders: false,
			onClick: () => {
				navigate(ROUTE_ACCOUNT_RESERVATIONS + "/emplois-acceptes");
			},
			icon: <SchoolSharpIcon />,
			for: [
				AccountType.SPORTS_ORGANIZATION,
				AccountType.INDEPENDENT_PROFESSIONAL,
			],
		},
		{
			text: "Parrainage",
			hasBorders: false,
			onClick: () => {
				navigate(ROUTE_ACCOUNT_SPONSORSHIP);
			},
			icon: <HandshakeIcon />,
			for: [
				AccountType.INDEPENDENT_PROFESSIONAL,
				AccountType.ORGANIZATION,
				AccountType.SPORTS_ORGANIZATION,
			],
		},
		{
			text: "Paramètres",
			hasBorders: true,
			onClick: () => {
				navigate(ROUTE_ACCOUNT_SETTINGS);
			},
			icon: <SettingsSharpIcon />,
			for: [
				AccountType.INDIVIDUAL,
				AccountType.INDEPENDENT_PROFESSIONAL,
				AccountType.ORGANIZATION,
				AccountType.SPORTS_ORGANIZATION,
			],
		},
		{
			id: "logout",
			text: "Déconnexion",
			hasBorders: false,
			onClick: () => {
				dispatch(setUserDisconnected());
				navigate(ROUTE_HOME);
				// variant could be success, error, warning, info, or default
				enqueueSnackbar(`Vous êtes déconnecté!`, {
					variant: "info",
					autoHideDuration: globalConfigDurationNotistack
				});
			},
			icon: <PowerSettingsNewSharpIcon />,
			for: [
				AccountType.INDIVIDUAL,
				AccountType.INDEPENDENT_PROFESSIONAL,
				AccountType.ORGANIZATION,
				AccountType.SPORTS_ORGANIZATION,
			],
		},
		{
			text: "Administration",
			hasBorders: true,
			onClick: () => {
				navigate(ROUTE_ADMIN_DASHBOARD);
			},
			icon: <SecurityIcon />,
			for: [
			],
		}
	].map((item, index) => {
		const isPremium = item.text.toUpperCase() === "PREMIUM";
		const textStyle = isPremium ? { primary: classes.premium } : {};
		if (user && item.for.includes(user.kind)){
			return (
				<Box key={`${index}`} id={item.id ? item.id : "user-account-button-" + index}>
					{item.hasBorders && <Divider />}
					<ListItem
						button
						key={index}
						onClick={(e) => {
							item.onClick();
							onClose(e);
						}}
					>
						<ListItemIcon>{item.icon}</ListItemIcon>
						<ListItemText
							primary={item.text}
							classes={textStyle}
							style={{ primary: { marginLeft: "5px" } }}
						/>
					</ListItem>
					{item.hasBorders && <Divider />}
				</Box>
			);
		}else if (user && item.for.length === 0 && user.role === "admin"){
			return (
				<Box key={`${index}`} id={item.id ? item.id : "user-account-button-" + index}>
					{item.hasBorders && <Divider />}
					<ListItem
						button
						key={index}
						onClick={(e) => {
							item.onClick();
							onClose(e);
						}}
					>
						<ListItemIcon>{item.icon}</ListItemIcon>
						<ListItemText
							primary={item.text}
							classes={textStyle}
							style={{ primary: { marginLeft: "5px" } }}
						/>
					</ListItem>
					{item.hasBorders && <Divider />}
				</Box>
			);
		}else {
			return null;
		}


	});

	const content = (
		<List sx={{ height: "100%"}}>
			<ListItem key={-1} classes={{ root: classes.largeAvatarContainer }}>
				<ListItemAvatar>
					<AvatarContainer user={user} isLarge />
				</ListItemAvatar>
			</ListItem>
			{items}
		</List>
	);

	return( <>
        <Hidden lgUp>
            <Drawer
                anchor="right"
                open={isShown}
                onClose={onClose}
				sx={{
					pt: 0,
					pr: (theme) => theme.spacing(4),
					pb: (theme) => theme.spacing(3),
					pl: (theme) => theme.spacing(2)
				}}
            >
                {content}
            </Drawer>
        </Hidden>
        <Hidden lgDown>
            <Drawer
                anchor="right"
                open={isShown}
                onClose={onClose}
				sx={{
					paddingTop: 0,
					paddingRight: (theme) => theme.spacing(4),
					paddingBottom: (theme) => theme.spacing(3),
					paddingLeft: (theme) => theme.spacing(2)
				}}
            >
                {content}
            </Drawer>
        </Hidden>
    </>
    );
}

export default DrawerComponent;
