import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import theme from '../../theme';
import {
    Button,
    DialogActions,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch
} from '@mui/material';
import {TextInput} from '../Inputs';
import {useSnackbar} from 'notistack';
import {useSelector} from 'react-redux';
import {UserInformationsSelector} from '../../selectors';
import sendMailAPI from './service/SendMailAPI';
import AccessAccountPopUp, {AccessAccountForms} from "../AccessAccountPopUp/AccessAccountPopUp";
import {globalConfigDurationNotistack} from "../../constants/globalConstant";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BugBetaAlert({open, setOpen, defaultReason = null, defaultComment = null }) {
    const user = useSelector(UserInformationsSelector);
    const [reason, setReason] = React.useState(defaultReason !== null ? defaultReason: "");
    const [comment, setComment] = React.useState(defaultComment !== null ? defaultComment:"");
    const [canCall, setCanCall] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const handleCanCall = (event) => {
        setCanCall(!canCall);
    };

    const [accessAccountState, setAccessAccountState] = React.useState({
        isActive: false,
        activeForm: AccessAccountForms.CONNECTION,
    });

    const handleClose = () => {
        setOpen(false);
    };

    //submit the report to the server
    const sendMail = (reason, comment) => {
        if (user.data === null) {
            enqueueSnackbar(`Veuillez-vous connectez`, {
                variant: "error",
                autoHideDuration: globalConfigDurationNotistack
            });
        } else if (reason !== "" && comment !== "") {
            sendMailAPI(user, reason, comment, canCall)
                .then(() => {
                    enqueueSnackbar(`Votre message a bien été envoyé`, {
                        variant: "success",
                        autoHideDuration: globalConfigDurationNotistack
                    });
                    handleClose();
                })
                .catch((err) => {
                    enqueueSnackbar(err, {
                        variant: "error",
                        autoHideDuration: globalConfigDurationNotistack
                    });
                });
        } else {
            enqueueSnackbar(`Veuillez remplir les champs "Type de retour" et "Message"`, {
                variant: "error",
                autoHideDuration: globalConfigDurationNotistack
            });
        }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const handleAccessAccountChangeActive = (event, newValue) => {
        setAccessAccountState({
            ...accessAccountState,
            activeForm: newValue,
        });
    };
    const handleCloseAccessAccountPopUp = () => {
        setAccessAccountState({
            ...accessAccountState,
            isActive: false,
        });
    };

    function onConnect() {
        setAccessAccountState({
            isActive: true,
            activeForm: AccessAccountForms.CONNECTION,
        });
    }

    return (
        <div>
            <AccessAccountPopUp
                isOpen={accessAccountState.isActive}
                activeSide={accessAccountState.activeForm}
                handleChangeSide={handleAccessAccountChangeActive}
                handleClose={handleCloseAccessAccountPopUp}
            />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    sx={{textAlign: "center"}}>
                    Avez-vous constaté un bug ou<br/>avez-vous une idée d'amélioration ?
                </DialogTitle>

                <DialogContent>

                    {user.data === null &&
                        <DialogContentText sx={{textAlign: "center"}}>
                            <Button
                                sx={{color: theme.palette.primary.main, fontSize: "17px"}}
                                onClick={onConnect}>
                                Connectez-vous
                            </Button>
                            pour pouvoir signaler un bug ou une idée d'amélioration
                        </DialogContentText>
                    }

                    <FormControl fullWidth sx={{marginTop: "6px"}}>
                        <InputLabel id="reason">Type de retour</InputLabel>
                        <Select
                            labelId="reason"
                            // id="demo-simple-select"
                            sx={{width: "100%"}}
                            label="Type de retour"
                            value={reason}
                            disabled={user.data === null ? true : false}
                            onChange={(e) => setReason(e.target.value)}
                        >
                            <MenuItem value="J'ai rencontré un bug">J'ai rencontré un bug</MenuItem>
                            <MenuItem value="J'ai une idée d'amélioration">J'ai une idée d'amélioration</MenuItem>
                            <MenuItem value="Autre">Autre</MenuItem>
                        </Select>
                    </FormControl>
                    <TextInput
                        sx={{marginTop: "12px", width: "100%"}}
                        multiline
                        rows={4}
                        fullWidth
                        labelText="Message"
                        value={comment}
                        disabled={user.data === null ? true : false}
                        handleChange={(e) => setComment(e.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Switch checked={canCall}
                                    onChange={handleCanCall}
                                    inputProps={{'aria-label': 'controlled'}}/>
                        }
                        label="Souhaitez-vous être contacté par appel téléphonique ?"/>
                    <DialogContentText sx={{margin: "12px"}}>
                        Merci de renseigner le sujet, la page ou l'URL et toute autre information utile
                    </DialogContentText>

                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            sx={{color: theme.palette.primary.main}}>
                            Annuler
                        </Button>
                        <Button
                            onClick={() => {
                                sendMail(reason, comment);
                            }}
                            sx={{color: theme.palette.primary.main}}
                        >
                            Envoyer
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}