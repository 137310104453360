/**
 * request an account creation
 * @param {*} accountType account type to create
 * @param {*} accountToSend data to send for the account
 * @returns
 */
const createAccountAPI = (accountType, accountToSend) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/${accountType}/create`,
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(accountToSend),
			}
		);

		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({ user: json.user, token: json.token });
					} else {
						reject(json.error);
						console.error(json.error);
					}
				});
			})
			.catch((err) => {
				reject(err);
				console.error(err);
			});
	});
};

export default createAccountAPI;
