import {lazy} from "react";
import AccountType from "../constants/AccountType";
import {
	ROUTE_ACCOUNT_ANNOUNCEMENTS,
	ROUTE_ACCOUNT_DELETION,
	ROUTE_ACCOUNT_PROFILE,
	ROUTE_ACCOUNT_RESERVATIONS,
	ROUTE_ACCOUNT_SETTINGS,
	ROUTE_CHAT,
	ROUTE_COOKIE_POLICY,
	ROUTE_CREATE_ANNOUNCEMENT,
	ROUTE_FAQ,
	ROUTE_FAVORIS,
	ROUTE_FORGOTTEN_PASSWORD,
	ROUTE_HOME,
	ROUTE_MAIL_VERIFICATION,
	ROUTE_PARTICULAR_ANNOUNCEMENT_INFO,
	ROUTE_PARTICULAR_ANNOUNCEMENTS,
	ROUTE_PRIVACY_POLICY,
	ROUTE_PROFESSIONAL_ANNOUNCEMENT_INFO,
	ROUTE_PROFESSIONAL_ANNOUNCEMENTS,
	ROUTE_PROFILE,
	ROUTE_RIGHTS_AND_OBLIGATIONS,
	ROUTE_TERMS_OF_SALE,
	ROUTE_TERMS_OF_USE,
	ROUTE_UPDATE_ANNOUNCEMENT,
	ROUTE_USER_ANNOUNCEMENTS,
	ROUTE_BOOST_ANNOUNCEMENT,
	ROUTE_STRIPE_PAYEMENT,
	ROUTE_STRIPE_CHECK_STATUS,
	ROUTE_STRIPE_PAYMENT_SUCCEED,
	ROUTE_INVOICES,
	ROUTE_ACCESS_ACCOUNT,
	ROUTE_ACCOUNT_SPONSORSHIP,
	ROUTE_ADMIN_DASHBOARD,
	ROUTE_VERIFY, ROUTE_BLOG_HOME, ROUTE_ARTICLE_DETAIL
} from "./routesConstant";
import Blog from "../scenes/Blog";
import ArticleDetail from "../scenes/ArticleDetail";

const ElementsStripeContainer = lazy(() => import('../scenes/StripeView/containers/ElementsStripeContainer'));
const PayementSucceedViewContainer = lazy(() => import('../scenes/StripeView/containers/PayementSucceedViewContainer'))
const AnnouncementInfo = lazy(() => import('../scenes/AnnouncementInfo'));
const CGUPage = lazy(() => import('../scenes/CGU'));
const CGVPage = lazy(() => import('../scenes/CGV'));
const CharteConfidentialitePage = lazy(() => import('../scenes/CharteConfidentialite/CharteConfidentialite'));
const Chat = lazy(() => import('../scenes/Chat'));
const CookiesPolicyPage = lazy(() => import('../scenes/CookiesPoliticy'));
const CreateAnnouncement = lazy(() => import('../scenes/CreateAnnouncement'));
const BoostAnnouncement = lazy(() => import("../scenes/AnnouncementBoost"))
const FAQ = lazy(() => import('../scenes/FAQ'));
const ForgottenPasswordPage = lazy(() => import('../scenes/ForgottenPassword/index'));
const MailVerification = lazy(() => import('../scenes/MailVerification'));
const Profile = lazy(() => import('../scenes/Profile'));
const UserReservationsCustomerPage  = lazy(() => import('../scenes/Reservation/UserReservationsCustomerPage'));
const UserReservationsCreatorPage= lazy(() => import('../scenes/Reservation/UserReservationsCreatorPage'));
const RightsAndObligationsPage = lazy(() => import('../scenes/RightsAndObligations'));
const UserAnnouncementsPage = lazy(() => import('../scenes/UserAnnouncementsPage'));
const UserFavouritesPage = lazy(() => import('../scenes/UserFavouritesPage'));
const UpdateAnnouncement = lazy(() => import('../scenes/UpdateAnnouncement'));
const Account = lazy(() => import('../scenes/Account'));
const AccountDeletionPage = lazy(() => import("../scenes/AccountDeletion"));
const Home = lazy(() => import("../scenes/Home"));
const ListAnnouncements = lazy(() => import("../scenes/ListAnnouncements"));
const Invoices = lazy(() => import("../scenes/Invoices"));
const AccessAccount = lazy(() => import("../scenes/Login"));
const AdminDashboard = lazy(() => import('../scenes/Admin/Dashboard'));
const SponsorshipAccount = lazy(() => import('../scenes/Sponsorship'));
const VerifyPage = lazy(() => import('../scenes/Verify'));

const routing = [
	{ component: <Home />, path: ROUTE_HOME },
	{ component: <ListAnnouncements />, path: ROUTE_PROFESSIONAL_ANNOUNCEMENTS },
	{ component: <ListAnnouncements />, path: ROUTE_PARTICULAR_ANNOUNCEMENTS },
	{ component: <AnnouncementInfo />, path: `${ROUTE_PROFESSIONAL_ANNOUNCEMENT_INFO}/:activity/:city/:id` },
	{ component: <AnnouncementInfo />, path: `${ROUTE_PARTICULAR_ANNOUNCEMENT_INFO}/:activity/:city/:id` },
	{ component: <Profile />, path: `${ROUTE_PROFILE}/:id` },
	{ component: <UserAnnouncementsPage />, path: `${ROUTE_USER_ANNOUNCEMENTS}/:id` },
	{ component: <MailVerification />, path: `${ROUTE_MAIL_VERIFICATION}/:userId/:userSecretCode` },
	{ component: <ForgottenPasswordPage />, path: `${ROUTE_FORGOTTEN_PASSWORD}/:secret` },
	{ component: <AccountDeletionPage />, path: `${ROUTE_ACCOUNT_DELETION}/:secret` },
	{ component: <FAQ />, path: `${ROUTE_FAQ}/:id` },
	{ component: <CGUPage />, path: ROUTE_TERMS_OF_USE },
	{ component: <CGVPage />, path: ROUTE_TERMS_OF_SALE },
	{ component: <CookiesPolicyPage />, path: ROUTE_COOKIE_POLICY },
	{ component: <RightsAndObligationsPage />, path: ROUTE_RIGHTS_AND_OBLIGATIONS },
	{ component: <CharteConfidentialitePage />, path: ROUTE_PRIVACY_POLICY },
	{ component: <AccessAccount />, path: ROUTE_ACCESS_ACCOUNT },
	{ component: <VerifyPage />, path: ROUTE_VERIFY },
	{ component: <Blog />, path: ROUTE_BLOG_HOME},
	{component: <ArticleDetail />, path: ROUTE_ARTICLE_DETAIL}
];

const routingConnected = [
	{
		component: <CreateAnnouncement />,
		path: ROUTE_CREATE_ANNOUNCEMENT,
		isAccessibleFor: [
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <BoostAnnouncement />,
		path: ROUTE_BOOST_ANNOUNCEMENT,
		isAccessibleFor: [
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <UpdateAnnouncement />,
		path: ROUTE_UPDATE_ANNOUNCEMENT + "/:idAd",
		isAccessibleFor: [
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <Profile />,
		path: ROUTE_ACCOUNT_PROFILE,
		isAccessibleFor: [
			AccountType.INDIVIDUAL,
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <Account />,
		path: ROUTE_ACCOUNT_SETTINGS,
		isAccessibleFor: [
			AccountType.INDIVIDUAL,
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <Chat />,
		path: ROUTE_CHAT,
		isAccessibleFor: [
			AccountType.INDIVIDUAL,
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <UserAnnouncementsPage />,
		path: ROUTE_ACCOUNT_ANNOUNCEMENTS,
		isAccessibleFor: [
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	/*	{
            // types
            //  > 'customer' for Professionals recruited
            //  > 'creator' for Jobs accepted
            component: <UserReservationsCreatorPage />,
			path: "/course-reservation",
            isAccessibleFor: [
                AccountType.INDEPENDENT_PROFESSIONAL,
                AccountType.ORGANIZATION,
                AccountType.SPORTS_ORGANIZATION,
            ],
        },*/
	{
		component: <UserReservationsCustomerPage />,
		path: ROUTE_ACCOUNT_RESERVATIONS,
		isAccessibleFor: [AccountType.INDIVIDUAL],
	},
	{
		/**
		 * list of types
		 * "mes-cours" for my course
		 * "professionnels-recrutes" for Professionals recruited
		 * "emplois-acceptes" for Jobs accepted
		 */
		component: <UserReservationsCreatorPage />,
		path: ROUTE_ACCOUNT_RESERVATIONS + "/:type",
		isAccessibleFor: [
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <UserFavouritesPage />,
		path: ROUTE_FAVORIS,
		isAccessibleFor: [
			AccountType.INDIVIDUAL,
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <Chat />,
		path: ROUTE_CHAT + "/:roomId",
		isAccessibleFor: [
			AccountType.INDIVIDUAL,
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <ElementsStripeContainer />,
		path: ROUTE_STRIPE_PAYEMENT,
		isAccessibleFor: [
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <ElementsStripeContainer />,
		path: ROUTE_STRIPE_CHECK_STATUS + "/:id",
		isAccessibleFor: [
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <PayementSucceedViewContainer />,
		path: ROUTE_STRIPE_PAYMENT_SUCCEED + "/:idInvoice",
		isAccessibleFor: [
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <Invoices />,
		path: ROUTE_INVOICES,
		isAccessibleFor: [
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	},
	{
		component: <SponsorshipAccount />,
		path: ROUTE_ACCOUNT_SPONSORSHIP,
		isAccessibleFor: [
			AccountType.INDEPENDENT_PROFESSIONAL,
			AccountType.ORGANIZATION,
			AccountType.SPORTS_ORGANIZATION,
		],
	}
];

const routingAdmin = [
	{component: <AdminDashboard />, path: ROUTE_ADMIN_DASHBOARD},
	{component: <AdminDashboard />, path: ROUTE_ADMIN_DASHBOARD + ":selected"}
]

export { routing, routingConnected, routingAdmin };

