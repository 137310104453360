const {
    sportsorganizationType,
    independentType,
    organizationType,
    individualType
} = require("../../constants/AccountType");
  
const AccountTypeData = [
    {
        "id": "OS",
        "value": sportsorganizationType,
        "label": "Organisme sportif",
        "details": "Ecole de ski, club de sport, bureau des guides, association sportive, club de parapente ...",
    },
    {
        "id": "PS",
        "value": independentType,
        "label": "Éducateur sportif",
        "details": "Moniteur de ski, coach sportif, maître nageur, professeur de tennis ...",
    },
    {
        "id": "ONS",
        "value": organizationType,
        "label": "Organisme non sportif",
        "details": "Hôtel, agence immobilière, agence événementielle, centre de formation ...",
    },
    {
        "id": "PA",
        "value": individualType,
        "label": "Particulier",
        "details": "Pour les amateurs de sport ...",
    }
];
  
export default AccountTypeData;