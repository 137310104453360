/**
 * fetch all articles active
 * @returns Promise
 */
const getArticleDetailByUrlAPI = (url) => {
    return new Promise((resolve, reject) => {
        const request = new Request(
            `${process.env.REACT_APP_API_URL}/blog/article/${url}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            }
        );

        fetch(request)
            .then((res) => {
                res.json()
                    .then((json) => {
                        if (json.success) {
                            resolve({
                                article: json.article,
                                similarArticle: json.similarArticle
                            });
                        } else {
                            console.error(json.error);
                            reject(json.error);
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                        reject(err);
                    });
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });
};

export default getArticleDetailByUrlAPI;
