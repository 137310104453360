import {
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountTypeData from "./AccountTypeData";

/**
 * Component to display account type form
 * @param accountTypeValue form state, handle all inputs
 * @param onChange callback to call when value change
 * @param setValid change form validity
 */
function AccountTypeForm({ accountTypeValue, onChange, setValid }) {
	const [accountType, setAccountType] = useState(accountTypeValue);

	useEffect(() => {
		// if variable undefined or null, it is invalid
		setValid(accountType);
		onChange(accountType);
	}, [onChange, accountType, setValid]);

	const displayChoices = AccountTypeData.map((item, index) => {
		return (
			<Box key={index}>
				<FormControlLabel
					id={item.id && `user-type-${item.id}`}
					value={item.value}
					control={<Radio color="primary" />}
					label={item.label}
				/>
				<FormHelperText>{item.details}</FormHelperText>
			</Box>
		);
	});
	return <>
		<FormControl component="fieldset">
			<FormLabel component="h2">
				Quel type de compte souhaitez-vous ?
			</FormLabel>
			<RadioGroup
				aria-label="account-type"
				name="account-type"
				value={accountType}
				onChange={(e) => setAccountType(e.target.value)}
			>
				{displayChoices}
			</RadioGroup>
		</FormControl>
	</>
}

export default AccountTypeForm;
