/**
 * Connect a user to the app
 * @param {*} email email of the user
 * @param {*} password  password of the user
 * @returns
 */
const handleConnectionAPI = (email, password) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/signin`,
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email, password }),
			}
		);

		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({
							user: json.user,
							token: json.token,
							message: `Bienvenue sur WeGuideYou!`,
						});
					} else {
						reject(json.error);
						console.error(json.error);
					}
				});
			})
			.catch((err) => {
				reject(err);
				console.error(err);
			});
	});
};

export default handleConnectionAPI;
