// In this file you can find processus of
// * drop images
// * switch between template, loading and image
// * drag and drop for change position of an image
// this file call views

import {useEffect, useState} from "react";
import { ContainerImages, DivImage, DivInput, DivLoader, DivTemplate } from "./Images.view";

/**
 *
 * @param id | the id in the array of the image
 * @param images | the array of all images
 * @param setImage For drag&Drop set an array
 * @param numberImages | the number of max images
 * @param updateImages | update images array (sort or add)
 * @param removeImage | remove one image with the close button
 * @param update | compoenent call on update page, not create page
 * @param error | is on error mod
 * @param manyImages stock option with date available if this option subscribe
 * @returns HTML
 */
export default function ImagesInputContent({
    id,
    images,
    setImage,
    numberImages,
    updateImages,
    removeImage,
    update,
    error,
    manyImages = null,
}) {

    useEffect(() => {
        if (images.length > 1) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [images.length]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    // premium option
    const [checked, setChecked] = useState(images.length > 1 ? true : false);
    // save ref for dragImage
    const [dragSelect, setDragSelect] = useState();
    const [dragEnter, setDragEnter] = useState();

    // activate of disactivate premium options
    const handleChange = () => {
        return
    };

    const handleDragSelect = (event, item) => {
        // event.dataTransfer.setData("blob", event.target.id);
        event.dataTransfer.effectAllowed = "move";
        if(item !== dragSelect) setDragSelect(item)
    };

    const handleDragEnter = (event, item) => {
        if(item !== dragEnter && item !== dragSelect) {
            event.preventDefault();
            event.dataTransfer.dropEffect = "move"
            setDragEnter(item)
        }
    };

    /**
     * Change place of an element on an array
     * @param {*} array use
     * @param {*} old_index of the element to move
     * @param {*} new_index of the element you want
     * @returns
     */
    function arrayMove(arr, old_index, new_index) {
        let newArray = arr
        // switch position
        newArray.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return newArray
    };

    const drop = () => {
        // event.preventDefault();
        // const img = document.getElementById("img-" + index);
        // img.style.visibility = "visible";
        if(typeof dragEnter !== 'undefined' && typeof dragSelect !== 'undefined' ) {
            setImageAsync()
        }
    }

    async function setImageAsync () {
        setLoading(true)
        await setImage(arrayMove( images, dragSelect, dragEnter ));
        setLoading(false)
    }

    async function handleUpdateImages (files) {
        setLoading(true);
        updateImages(files);
    }

    async function handleRemoveimage(i) {
        await removeImage(i);
    }

    function ImagesInputContentItem(images) {
        const res = [];
        for (let i = 0; i < numberImages; i++) {
            if (typeof images !== "undefined") {
                if (images.length > i) {
                    // Image
                    res.push(DivImage(
                        i,
                        handleDragSelect,
                        handleDragEnter,
                        drop,
                        images,
                        update,
                        handleRemoveimage,
                    ));
                } else if (loading && images.length >= i) {
                    // Loader
                    res.push(DivLoader( i ));
                } else if ((!loading && images.length === i) || (loading && images.length + 1 === i)) {
                    // Input
                    res.push(DivInput(
                        i,
                        id,
                        error,
                        images,
                        checked,
                        setChecked,
                        setLoading,
                        handleUpdateImages
                    ));
                } else {
                    // Empty template
                    res.push(DivTemplate( i ));
                }
            } else {
                // Empty template
                res.push(DivTemplate( i ));
            }
        }
        return res
    }

    return ContainerImages(ImagesInputContentItem(images), dialogOpen, setDialogOpen, checked, handleChange, manyImages)
}