import io from 'socket.io-client';
import {
  addChatRoom,
  newChatMessage,
  setChatRooms,
  setNewReservation,
  setCancelReservation,
} from '../../actions/index';
import store from '../../reducers';
import {UserInformationsSelector} from '../../selectors/index';
import callMethod from './callMethod';

class Chat {
  static init() {
    // get user from the store
    Chat.user = UserInformationsSelector(store.getState());

    // if the user have a toker
    if (Chat.user.token) Chat.connect();

    // when the store is modified ...
    store.subscribe(() => {
      let previousUser = Chat.user;
      Chat.user = UserInformationsSelector(store.getState());

      // if user token havec changed ...
      if (Chat.user.token !== previousUser.token) {
        if (Chat.user.token) {
          Chat.connect();
        } else {
          Chat.disconnect();
        }
      }
    });
  }

  static connect() {
    if (!Chat.socket) {
      Chat.socket = io(process.env.REACT_APP_CHAT_API_URL, {
        query: {token: Chat.user.token},
      });

      Chat.socket.on('unauthorized', (data) => {
        console.error(data);
      });

      Chat.socket.on('authorization_error', (data) => {
        console.error(data);
      });

      Chat.socket.on('newMessage', (data) => {
        store.dispatch(newChatMessage(data.body.message, Chat.user));
      });

      Chat.socket.on('newRoom', (data) => {
        store.dispatch(addChatRoom(data.body.room));
      });

      // Chat.socket.on('newOffer', (data) => {
      //   const {chatRoomId, offer} = data.body;
      //   store.dispatch(setChatOffer(chatRoomId, offer));
      // });

      Chat.socket.on('newReservation', (data) => {
        const {chatRoomId, reservation} = data.body;
        store.dispatch(setNewReservation(chatRoomId, reservation));
      });

      Chat.socket.on('cancelReservation', (data) => {
        const {chatRoomId, reservation} = data.body;
        store.dispatch(setCancelReservation(chatRoomId, reservation));
      });

      Chat.getRooms()
          .then((res) => {
            store.dispatch(setChatRooms(res.body.chatRooms));
          })
          .catch((err) => {
            console.error(err);
          });
    }
  }

  static disconnect() {
    Chat.socket.disconnect();
    Chat.socket = null;
  }

  static getRoom(announcementId) {
    return callMethod(
        'getRoom',
        {
          announcementId,
        },
        Chat.socket,
    );
  }

  static getRooms() {
    return callMethod('getRooms', {}, Chat.socket);
  }

  static sendMessage(text, chatRoomId) {
    return callMethod(
        'sendMessage',
        {
          text,
          chatRoomId,
        },
        Chat.socket,
    ).then((res) => {
      store.dispatch(newChatMessage(res.body.message, Chat.user));
    });
  }

  // static sendOffer(offer, chatRoomId, offerId) {
  //   console.log(offer);
  //   return callMethod(
  //       'sendOffer',
  //       {
  //         offer,
  //         offerId,
  //         chatRoomId,
  //       },
  //       Chat.socket,
  //   ).then((res) => {
  //     const {chatRoomId, offer} = res.body;
  //     store.dispatch(setChatOffer(chatRoomId, offer));
  //   });
  // }

  static sendReservation(chatRoomId, reservation) {
    return callMethod(
        'sendReservation',
        {
          chatRoomId,
          reservation
        },
        Chat.socket,
    ).then((res) => {
      const {chatRoomId, reservation} = res.body;
      store.dispatch(setNewReservation(chatRoomId, reservation));
    });
  }

  static cancelReservation(chatRoomId, reservation) {
    return callMethod(
        'cancelReservation',
        {
          chatRoomId,
          reservation
        },
        Chat.socket
    ).then((res) => {
      const {chatRoomId, reservation} = res.body;
      store.dispatch(setCancelReservation(chatRoomId, reservation));
    });
  }

  static readMessage(roomId) {
    return callMethod(
        'readMessage',
        {
          roomId,
        },
        Chat.socket,
    );
  }
}

export default Chat;
