/**
 * Fct top add or subtract a nb days to a date
 * @param {Date} date
 * @param {number} days nb days
 * @param {boolean} isPositiveOperation boolean to check we need to add or substract, default true
 * @returns {Date}
 */
export function updateDateByNbDays(date, days, isPositiveOperation = true) {
    let result = new Date(date);
    if (isPositiveOperation){
        result.setDate(result.getDate() + days);
    } else {
        result.setDate(result.getDate() - days);
    }

    return result;
}

/**
 * Fct to get diff between two dates in number of days, hour, month or year
 * @param {Date} littleDate
 * @param {Date} biggerDate
 * @param {string} type D for day, H for hour, M for month, Y for year
 * @returns {number} nbr days Between two dates
 */
export function getDateDiff (littleDate, biggerDate, type) {
    const diff = biggerDate.getTime() - littleDate.getTime();
    if ( type === "D" ) {
        if ((diff / (1000 * 3600 *24)) < 1){
            return 0
        }else {
            return Math.trunc(diff / (1000 * 3600 * 24));
        }
    } else if ( type === "H" ) {
        return Math.trunc(diff / (1000 * 3600));
    } else if ( type === "M" ) {
        return Math.trunc(diff / 60000);
    } else if (type === "Y"){
        return Math.abs(biggerDate.getFullYear() - littleDate.getFullYear());
    } else {
        return Math.trunc(diff);
    }
}

export function DateRangeStringify (date) {
    const dateFormatDay = {day: 'numeric'}
    const dateFormatMouth = {month: 'short', day: 'numeric'}
    const dateFormatYear = {year: '2-digit', month: 'short', day: 'numeric'}
    const start = new Date(date.start)
    const end = new Date(date.end)

    if(start.getFullYear() === end.getFullYear()) {
        if(start.getMonth() === end.getMonth()) {
            return `${start.toLocaleDateString("fr-FR", dateFormatDay)} - 
            ${end.toLocaleDateString("fr-FR", dateFormatYear)}`
        } else {
            return `${start.toLocaleDateString("fr-FR", dateFormatMouth)} - 
            ${end.toLocaleDateString("fr-FR", dateFormatYear)}`
        }
    } else {
        return `${start.toLocaleDateString("fr-FR", dateFormatYear)} - 
        ${end.toLocaleDateString("fr-FR", dateFormatYear)}`
    }
}

export function calculateSignUpDate(date) {
    const dateFormat  = {year: 'numeric', month: 'long'}
    return date.toLocaleDateString("fr-FR", dateFormat)
}
