/**
 * Types of labelType ->
 * DURATION -> "pendant " + {numberOfDay} + " jours"
 * CUSTOM -> {label}
 */
export const MIN_AMOUNT_STRIPE_PAYMENT = 0.59
export const PAID_OPTIONS_TYPE_MANY_IMAGES_DETAIL = "MANY_IMAGES_30";
export const PAID_OPTIONS_TYPE_MANY_IMAGES_7_DAYS = "ANNOUNCEMENT_MANY_IMAGES_7";
export const PAID_OPTIONS_TYPE_MANY_IMAGES_30_DAYS = "ANNOUNCEMENT_MANY_IMAGES";

export const PAID_OPTIONS_TYPE_URGENT_ANNOUNCEMENT_15_DAYS = "URGENT_ANNOUNCEMENT_15";
export const PAID_OPTIONS_TYPE_URGENT_ANNOUNCEMENT_30_DAYS = "URGENT_ANNOUNCEMENT_30";

export const PAID_OPTIONS_TYPE_ANNOUNCEMENT_IN_CORNER_TOP_RIGHT_15_DAYS = "ANNOUNCEMENT_IN_CORNER_TOP_RIGHT_15";
export const PAID_OPTIONS_TYPE_ANNOUNCEMENT_IN_CORNER_TOP_RIGHT_30_DAYS = "ANNOUNCEMENT_IN_CORNER_TOP_RIGHT_30";

export const PAID_OPTIONS_TYPE_AUTO_REPLACE_TO_TOP_EACH_DAYS_DURING_7_DAYS = "AUTO_REPLACE_TO_TOP_7";
export const PAID_OPTIONS_TYPE_AUTO_REPLACE_TO_TOP_EACH_DAYS_DURING_15_DAYS = "AUTO_REPLACE_TO_TOP_15";
export const PAID_OPTIONS_TYPE_AUTO_REPLACE_TO_TOP_EACH_DAYS_DURING_30_DAYS = "AUTO_REPLACE_TO_TOP_30";


//----------------------------------------------------
//     ANNOUNCEMENT IMAGES IN ANNOUNCEMENT DETAIL     |
//----------------------------------------------------
export const AnnounceImagesDetail = {
    "title": "Announcement creation",
    "kind": PAID_OPTIONS_TYPE_MANY_IMAGES_DETAIL,
    "available": false,
    "display": true,
    "labelType": "CUSTOM",
    "label": "Pack 5 photos supplémentaires 8.90€",
    "durationNbDays": 30,
    "price": 4.59
};

//-------------------------------------------------
//     URGENT ANNOUNCEMENT UPGRADE VISIBILITY     |
//-------------------------------------------------
export const AnnounceIncreaseVisibilityFirstDuration = {
    "kind": PAID_OPTIONS_TYPE_URGENT_ANNOUNCEMENT_15_DAYS,
    "available": true,
    "display": true,
    "labelType": "DURATION",
    "label": "",
    "durationNbDays": 15,
    "price": 3.99
};

export const AnnounceIncreaseVisibilitySecondDuration = {
    "kind": PAID_OPTIONS_TYPE_URGENT_ANNOUNCEMENT_30_DAYS,
    "available": true,
    "display": true,
    "labelType": "DURATION",
    "label": "",
    "durationNbDays": 30,
    "price": 6.99
};

//------------------------------------------------
//     ANNOUNCEMENT IN THE CORNER TOP RIGHT      |
//------------------------------------------------
export const AnnounceSpotlightFirstDuration = {
    "kind": PAID_OPTIONS_TYPE_ANNOUNCEMENT_IN_CORNER_TOP_RIGHT_15_DAYS,
    "available": true,
    "display": true,
    "labelType": "DURATION",
    "label": "",
    "durationNbDays": 15,
    "price": 2.99
};

export const AnnounceSpotlightSecondDuration = {
    "kind": PAID_OPTIONS_TYPE_ANNOUNCEMENT_IN_CORNER_TOP_RIGHT_30_DAYS,
    "available": true,
    "display": true,
    "labelType": "DURATION",
    "label": "",
    "durationNbDays": 30,
    "price": 3.59
};

//------------------------------------------------
//     AUTO REPLACE TO TOP AN ANNOUNCEMENT      |
//------------------------------------------------

export const AnnounceReplaceToTopFirstDuration = {
    "kind": PAID_OPTIONS_TYPE_AUTO_REPLACE_TO_TOP_EACH_DAYS_DURING_7_DAYS,
    "available": true,
    "display": true,
    "labelType": "CUSTOM",
    "label": "chaque jour pendant 7 jours",
    "durationNbDays": 7,
    "price": 3.99
};

export const AnnounceReplaceToTopSecondDuration = {
    "kind": PAID_OPTIONS_TYPE_AUTO_REPLACE_TO_TOP_EACH_DAYS_DURING_15_DAYS,
    "available": true,
    "display": true,
    "labelType": "CUSTOM",
    "label": "chaque jour pendant 15 jours",
    "durationNbDays": 15,
    "price": 5.99
};

export const AnnounceReplaceToTopThirdDuration = {
    "kind": PAID_OPTIONS_TYPE_AUTO_REPLACE_TO_TOP_EACH_DAYS_DURING_30_DAYS,
    "available": true,
    "display": true,
    "labelType": "CUSTOM",
    "label": "chaque jour pendant 30 jours",
    "durationNbDays": 30,
    "price": 9.99
};
