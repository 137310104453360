import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserDisconnected } from "../actions";

export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch {
        return null;
    }
};

const AuthVerifyJwtValidity = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const token = Cookies.get("token");
        if (typeof token !== "undefined") {
            const decodedJwt = parseJwt(token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                dispatch(setUserDisconnected());
            }
        }
    }, [location, dispatch])

    return <></>;
};

export default AuthVerifyJwtValidity;