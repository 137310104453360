import React, { Suspense, lazy } from "react";
import { createBrowserHistory } from "history";
import { useSelector } from "react-redux";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop";
import config from "../config";
import { UserInformationsSelector } from "../selectors";
import CircularProgress from '@mui/material/CircularProgress';
import { ROUTE_HOME } from "../config/routesConstant";
import FABScrollToTop from "../components/FABScrollToTop";
import FABBetaReport from "../components/FABBetaReport";
import AuthVerifyJwtValidity from "../config/AuthVerifyJWTValidity";
import TopBarContainer from '../containers/TopBarContainer';
import { Box } from "@mui/material";
const Error404 = lazy(() => import("../scenes/Error404"));

const history = createBrowserHistory();

/**
 * Component for the routing of the app
 * @returns
 */
function RoutesIndex() {
    const user = useSelector(UserInformationsSelector);

    return (
        <BrowserRouter history={history}>
            <ScrollToTop />
            <Box>
                {/** TOPBAR */}
                <TopBarContainer />
                {/** CONTENT */}
                <Box sx={{ marginTop: { xs: "54px", sm: "64px" }, minHeight: "60vh", overflow: "hidden" }}>
                    <Suspense fallback={<CircularProgress sx={{ position: "absolute", top: "200px", left: "50vw", height: "200px", width: "200px" }} />}>
                        <Routes>
                            {/* Anonymous routes */}
                            {config.routes.map((route) => (
                                <Route
                                    key={route.path}
                                    exact
                                    path={route.path}
                                    element={route.component}
                                />

                            ))}
                            {/* Must be connected routes */}
                            {config.routesConnected.map((route) => (
                                <Route
                                    key={route.path}
                                    exact
                                    path={route.path}
                                    element={
                                        !user.data ?
                                            <Navigate to={ROUTE_HOME} state={{ path: window.location.pathname }} />
                                            : !route.isAccessibleFor.includes(user.data.kind) ?
                                                <Navigate to="/404" />
                                                : route.component
                                    }
                                />
                            ))}
                            {/* Only for admin routes */}
                            {config.routesAdmin.map((route) => (
                                <Route
                                    key={route.path}
                                    exact
                                    path={route.path}
                                    element={
                                        !user.data ?
                                            <Navigate to={ROUTE_HOME} state={{ path: window.location.pathname }} />
                                            : user.data.role !== "admin" ?
                                                <Navigate to="/404" />
                                                : route.component
                                    }
                                />
                            ))}
                            <Route path="/*" element={<Error404 />} />
                        </Routes>
                    </Suspense>
                </Box>
                <AuthVerifyJwtValidity />
            </Box>
            {/* FOOTER */}
            <Footer />
            <FABBetaReport />
            <FABScrollToTop />
            {/* TAB BAR */}
            {/* <Hidden smUp>
                <TabBar />
            </Hidden> */}
        </BrowserRouter>
    );
}

export default RoutesIndex;
