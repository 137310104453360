import {AllCountryCode} from "../../constants/typeToText";
import {search} from "country-js";

/**
 * Get countryCode Object corresponding to a given country name
 * @param {string} countryName
 * @returns {{code: string, value: string, key: string}|{code: string, value: string, key: string}|{code: string, value: string, key: string}|{code: string, value: string, key: string}|{code: string, value: string, key: string}}
 */
export const parseNameCountryToObject = (countryName) => {

    return AllCountryCode.filter((item) => {
        return item.value === countryName;
    })[0]
}

export const parseIndicatorCountryToObject = (countryIndicator) => {
  return AllCountryCode.filter((item) => {
      return item.code === search(countryIndicator)[0].code;
  })[0]
}
