import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import ForgottenPassword from "../../components/AccessAccountPopUp/ForgottenPassword";
import createForgottenPasswordRequestAPI from "../../services/createForgottenPasswordRequestAPI";
import {globalConfigDurationNotistack} from "../../constants/globalConstant";

/**
 * Container to display forgotten password dialog
 */
function ForgottenPasswordContainer() {
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const onSubmit = useCallback((email) => {
		createForgottenPasswordRequestAPI(email)
			.then(() => {
				enqueueSnackbar("Un email vous a été envoyé.", {
					variant: "success",
					autoHideDuration: globalConfigDurationNotistack
				});
				setOpen(false);
			})
			.catch((_) => {
				enqueueSnackbar("Oups il y'a un problème.", {
					variant: "error",
					autoHideDuration: globalConfigDurationNotistack
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ForgottenPassword onSubmit={onSubmit} setOpen={setOpen} open={open} />
	);
}

export default ForgottenPasswordContainer;
