import React from "react";
import BugBetaAlert from "./PopUpAlert/BugBetaAlert";
import { Button, Hidden } from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";
import theme from "../theme";

function FABBetaReport() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <BugBetaAlert open={open} setOpen={setOpen} />
      <Hidden mdDown>
        <Button
          variant="text"
          onClick={handleOpen}
          sx={{
            zIndex: 1,
            color: theme.palette.primary.main,
            backgroundColor: "white !important",
            position: "fixed",
            bottom: 8,
            left: 8,
            paddingLeft: 2,
            paddingRight: 2,
          }}
          startIcon={<BugReportIcon />}
        >
          Signaler une erreur
        </Button>
      </Hidden>
    </>
  );
}

export default FABBetaReport;
