import {
	Box,
	CardMedia,
	Dialog,
	DialogContent,
	Grid,
	Hidden,
	Tab,
	Tabs,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import InputSharpIcon from "@mui/icons-material/InputSharp";
import PersonAddSharpIcon from "@mui/icons-material/PersonAddSharp";
import React from "react";
import ConnectionFormContainer from "../../containers/AccessAccountPopUp/ConnectionFormContainer";
import InscriptionFormContainer from "../../containers/AccessAccountPopUp/InscriptionFormContainer";
import backgroundPic from "../../assets/img/inscription-connexion.jpg";

const PREFIX = 'AccessAccountPopUp';

const classes = {
    dialogPaper: `${PREFIX}-dialogPaper`,
    dialogContent: `${PREFIX}-dialogContent`,
    container: `${PREFIX}-container`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({
    [`& .${classes.dialogPaper}`]: {
		minHeight: DIALOG_HEIGHT,
		maxHeight: DIALOG_HEIGHT,
	},

    [`& .${classes.dialogContent}`]: {
		padding: 0,

		"&:first-of-type": {
			padding: 0,
		},
	},

    [`& .${classes.container}`]: {
		height: "100%",
	}
}));

const DIALOG_HEIGHT = "93vh";

export const AccessAccountForms = {
	CONNECTION: 0,
	INSCRIPTION: 1,
};

/**
 * Component to display tabs at top of the popup
 */
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

/**
 * Component to display co
 */
function AccessAccountPopUp({
	isOpen = false,
	activeSide = AccessAccountForms.CONNECTION,
	handleChangeSide,
	handleClose,
	nextPath
}) {
	const allyProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	};



	return (
        <StyledDialog
			onClose={handleClose}
			aria-labelledby="access-account-dialog"
			open={isOpen}
			maxWidth="xl"
			fullWidth={true}
			classes={{ paper: classes.dialogPaper }}
		>
			<DialogContent classes={{ root: classes.dialogContent }}>
				<Grid container classes={{ container: classes.container }}>
					<Hidden mdDown>
						<Grid item md={4} style={{ height: DIALOG_HEIGHT }}>
							<CardMedia
								component="img"
								alt="Inscrivez vous ou connectez vous"
								image={backgroundPic}
								style={{ height: "100%" }}
							/>
						</Grid>
					</Hidden>
					<Grid
						item
						xs={12}
						md={8}
						style={{ height: DIALOG_HEIGHT, overflowY: "auto" }}
					>
						<Tabs
							value={activeSide}
							indicatorColor="primary"
							textColor="primary"
							onChange={handleChangeSide}
							centered
							variant="fullWidth"
						>
							<Tab
								label="Connexion"
								icon={<InputSharpIcon />}
								{...allyProps(0)}
							/>
							<Tab
								label="Inscription"
								icon={<PersonAddSharpIcon />}
								{...allyProps(1)}
							/>
						</Tabs>
						<TabPanel value={activeSide} index={0}>
							<ConnectionFormContainer
								handleClose={handleClose}
								nextPath={nextPath}
							/>
						</TabPanel>
						<TabPanel value={activeSide} index={1}>
							<InscriptionFormContainer
								handleClose={handleClose}
							/>
						</TabPanel>
					</Grid>
				</Grid>
			</DialogContent>
		</StyledDialog>
    );
}

export default AccessAccountPopUp;
