import { createTheme } from "@mui/material/styles";

const breakpoints = {
	key: {
		0: "xs",
		1: "sm",
		2: "md",
		3: "lg",
		4: "xl"
	},
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1536
	}
};

const defaultTheme = createTheme({breakpoints: {breakpoints}});
const theme = createTheme(defaultTheme, {
	breakpoints: {breakpoints},
	palette: {
		mode: "light",
		primary: {
			//TODO define light and dark colors
			main: '#2ab1ea',
			light: '#2ab1ea',
			dark: '#2ab1ea',
			contrastText: '#ffffff',
		},
		secondary: {
			//TODO define light and dark colors
			main: '#5dbbe5',
			light: '#5dbbe5',
			dark: '#5dbbe5',
			contrastText: '#ffffff',
		},
		error: {
			main: '#d32f2f',
			light: '#ef5350',
			dark: '#c62828',
			contrastText: '#ffffff'
		},
		light: {
			main: '#ffffff'
		}
	},
	shape: {
		borderRadius: 16,
	},
	typography: {
		fontFamily: [
			'Roboto',
			'Helvetica',
			'Arial',
			'sans-serif',
		].join(','),
		title: {
			fontFamily: "Roboto, Helvetica, Arial, sans-serif",
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: "1.5rem",
				margin: defaultTheme.spacing(1),
			},
			[defaultTheme.breakpoints.up("sm")]: {
				fontSize: "3.1rem",
				margin: defaultTheme.spacing(3),
			},
			textAlign: "center",
		},
		subtitle: {
			fontWeight: "normal",
			marginTop: "0px !important",
			fontFamily: "Roboto, Helvetica, Arial, sans-serif",
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: "0.75rem",
				margin: defaultTheme.spacing(1),
			},
			[defaultTheme.breakpoints.up("sm")]: {
				fontSize: "1.5rem",
				margin: defaultTheme.spacing(3),
			},
			textAlign: "center",
		},
		h1: {
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: "1.5rem",
			},
			[defaultTheme.breakpoints.up("sm")]: {
				fontSize: "3rem",
			},
			fontWeight: 600
		},
		h3: {
			[defaultTheme.breakpoints.up("sm")]: {
				fontSize: "3em",
			},
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: "1.5em",
			}
		},
		h4: {
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: "1.2em"
			},
			[defaultTheme.breakpoints.up("sm")]: {
				fontSize: "1.8em",
			},
			fontWeight: 'bold',
		},
		h6: {
			[defaultTheme.breakpoints.up("sm")]: {
				fontSize: "1.25rem",
			},
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: "1rem",
			},
		}
	},
	components: {
		MuiButton: {
			variants: [{
				props: { variant: 'float' },
				style: {
					position: "fixed",
					zIndex: 100,
					color: "white !important",
					backgroundColor: "rgb(34, 34, 34) !important",
					padding: "0.5rem 1rem",
					transitionProperty: "bottom",
					transitionDuration: "0.3s"
				}
			}],
			styleOverrides: {
				root:{
					borderRadius: '48px',
				}
			}
		},
	}
});

export default theme;
