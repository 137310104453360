import {
  SET_ACCOUNT_GENERAL,
  SET_ACCOUNT_SHARED,
  SET_ACCOUNT_SPECIFIC,
  SET_ACCOUNT_TYPE,
  SET_SPONSORED,
} from "../constants/ActionTypes";

const initialState = {
  accountType: null,
  accountGeneral: {
    mail: "",
    psw: "",
    verif_psw: "",
  },
  sponsored: {
    code: null
  },
  accountShared: {
    address: "",
    city: null,
    zipCode: "",
    country: "France",
    telephone: {
      indicator: "33",
      number: "",
    },
  },
  accountSpecific: {
    independent: {
      urlProfilEaps : "",
      firstName: "",
      lastName: "",
      sex: "",
      birthDate: "",
      lang: ["Français"],
      numCardPro: "",
      cardProExpiredAt: "",
      cardProDeliveryBy: "",
      allQualification: [],
      //siret: "",
      //activities: [],
    },
    individual: {
      firstName: "",
      lastName: "",
      sex: "",
      birthDate: "",
      lang: [],
    },
    organization: {
      isFrenchCompany: true,
      siret: "",
      vatNumber: "",
      name: "",
      status: "",
      managerFirstName: "",
      managerLastName: "",
      managerMail: "",
    },
    sportsorganization: {
      isFrenchCompany: true,
      siret: "",
      vatNumber: "",
      name: "",
      status: "",
      managerFirstName: "",
      managerLastName: "",
      managerMail: "",
      managerLang: [],
      //activities: [],
    },
  },
};

const InscriptionReducer = (state = initialState, action) => {
  // if set_account_specific, redirect to correct role
  const isAccountTypeChanged = action.type === SET_ACCOUNT_TYPE;
  const accountType = isAccountTypeChanged ? action.payload.accountType : state.accountType;

  switch (action.type) {
    case SET_ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.payload.accountType,
      };
    case SET_ACCOUNT_GENERAL:
      return {
        ...state,
        accountGeneral: action.payload.accountGeneral,
      };
    case SET_SPONSORED:
      return {
        ...state,
        sponsored: action.payload.accountSponsored,
      };
    case SET_ACCOUNT_SHARED:
      return {
        ...state,
        accountShared: action.payload.accountShared,
      };
    case SET_ACCOUNT_SPECIFIC:
      return {
        ...state,
        accountSpecific: {
          ...state.accountSpecific,
          [accountType]: action.payload.accountSpecific,
        },
      };
    default:
      return state;
  }
};

export default InscriptionReducer;
