import React, { useState } from "react";
import AccountGeneralFormContainer from "../../containers/AccessAccountPopUp/AccountGeneralFormContainer";
import AccountSharedFormContainer from "../../containers/AccessAccountPopUp/AccountSharedFormContainer";
import AccountSpecificFormContainer from "../../containers/AccessAccountPopUp/AccountSpecificFormManagerContainer";
import AccountTypeFormContainer from "../../containers/AccessAccountPopUp/AccountTypeFormContainer";
import AccountSponsorShipFormContainer from "../../containers/AccessAccountPopUp/AccountSponsorShipFormContainer";
import MultiPageForm from "./MultiPageForm";
import {individualType} from "../../constants/AccountType";
import {useSelector} from "react-redux";
import {InscriptionSelector} from "../../selectors";

/**
 * Component to display inscription form
 * @param handleFinish callback to call when user finish his inscription
 * @param handleClose callback to call when user close the popup
 */
function InscriptionForm({ handleFinish, handleClose }) {
	const [index, setIndex] = useState(0);
	const inscription = useSelector(InscriptionSelector);

	const handlePrevious = () => {

		// Sponsorship for individual user
		if (index === 2 && inscription.accountType === individualType) {
			setIndex(index - 2);
		} else { setIndex(index - 1); }
	};
	const handleNext = () => {
		// Sponsorship for individual user
		if (index === 0 && inscription.accountType === individualType) {
			setIndex(index + 2);
		} else { setIndex(index + 1); }
	};

	const content = [
		{
			component: <AccountTypeFormContainer inscription={inscription} />,
			buttonsAreShown: true,
			canSkip: false
		},
		{
			component: <AccountSponsorShipFormContainer inscription={inscription} />,
			buttonsAreShown: true,
			canSkip: true
		},
		{
			component: <AccountSpecificFormContainer inscription={inscription} />,
			buttonsAreShown: true,
			canSkip: false
		},
		{
			component: <AccountSharedFormContainer inscription={inscription} />,
			buttonsAreShown: true,
			canSkip: false
		},

		{
			component: <AccountGeneralFormContainer inscription={inscription}/>,
			buttonsAreShown: true,
			canSkip: false
		},
	];

	return (
		<MultiPageForm
			content={content}
			index={index}
			handlePrevious={handlePrevious}
			handleNext={handleNext}
			handleFinish={handleFinish}
		/>
	);
}

export default InscriptionForm;
