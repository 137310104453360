import Cookies from "js-cookie";
import {
	SET_ROLE,
	SET_USER_DISCONNECTED,
	SET_USER_FAVOURITE,
	SET_USER_INFORMATIONS,
	SET_USER_PROFILE_INFORMATIONS,
	SET_USER_TOKEN
} from "../../constants/ActionTypes";
import roles from "../../constants/Role";
import AccountType from "../../constants/AccountType";
import UserSettingsSwitch from "./UserSettingsSwitch";

const initialState = {
	token: Cookies.get("token") || "",
	role: roles.PROFESSIONAL,
};

let role = Cookies.get("role") || null;
if (!role ) {
	role =
		Cookies.get("role") && JSON.parse(Cookies.get("role")).kind === AccountType.INDIVIDUAL
			? roles.INDIVIDUAL
			: roles.PROFESSIONAL;
}
if (role) initialState.role = role;

const UserInformationsReducer = (state = initialState, action) => {
	UserSettingsSwitch(action.type, state, action, Cookies);
	switch (action.type) {
		// Set the role of the user, it can be switch on the home page when the user is not connected
		case SET_USER_INFORMATIONS:
			let role = action.payload.data.kind === AccountType.INDIVIDUAL ? roles.INDIVIDUAL : roles.PROFESSIONAL;
			Cookies.set("role", role, {secure: true, sameSite: 'none'});
			action.payload.data.qualifications = [];
			return { ...state, data: action.payload.data, role };
		// Set the data in the redux store
		case SET_USER_PROFILE_INFORMATIONS:
			action.payload.data.qualifications = [];
			return { ...state, data: action.payload.data };
		case SET_USER_TOKEN:
			Cookies.set("token", action.payload.token, {secure: true, sameSite: 'none'});
			return { ...state, token: action.payload.token };
		case SET_USER_DISCONNECTED:
			Cookies.remove("token");
			return { ...state, token: "", data: null };

		case SET_USER_FAVOURITE:
			let data = {};
			if (action.payload.value)
				data = {
					...state.data,
					favourites: [...state.data.favourites, action.payload.announcementId],
				};
			else
				data = {
					...state.data,
					favourites: state.data.favourites.filter((id) => id !== action.payload.announcementId),
				};
			return { ...state, data };
		case SET_ROLE:
			Cookies.set("role", action.payload.role, {secure: true, sameSite: 'none'});
			return { ...state, role: action.payload.role };
		default:
			return state;
	}
};

export default UserInformationsReducer;
