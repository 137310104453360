import { DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
// Style
import { ManyImagesDialog } from "./style.ManyImagesPopUp";
// Images
import { ANNOUNCEMENT_VALIDITY_DURATION} from "../../../constants/globalConstant"
import {generateUrlImage} from "../../../utils/general/generateUrlImage";

const ManyImagesTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            ) : null}
        </DialogTitle>
    );
};

ManyImagesTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export function ViewManyImagesDialog (handleClose, dialogOpen) {
    return <ManyImagesDialog
        onClose={handleClose}
        aria-labelledby="many-images"
        open={dialogOpen}
    >
        <ManyImagesTitle id="many-images" onClose={handleClose}>
          {"Augmentez votre visibilité"}
        </ManyImagesTitle>
        <DialogContent dividers>
            <Typography variant="h5" className="sub-title">Démarquez-vous en mettant non pas une mais six photos et gagnez en visibilité</Typography>
            <div className="images-container">
                <div className="image-container">
                    <Typography>Sans pack</Typography>
                    {/* eslint-disable-next-line */}
                    <img
                        style={{width:"100%"}}
                        src={generateUrlImage('paid-options/no-pack.svg', false, true)}
                        alt="Booster votre visibilité avec le kit multi-image, sans pack"
                    />
                </div>
                <div className="image-container">
                    <Typography>Avec pack</Typography>
                    {/* eslint-disable-next-line */}
                    <img
                        style={{width:"100%"}}
                        src={generateUrlImage('paid-options/with-pack.svg', false, true)}
                        alt="Booster votre visibilité avec le kit multi-image, avec pack"
                    />
                </div>
            </div>
            <Typography>Avec le Pack 5 photos supplémentaires, votre annonce bénéficiera de :</Typography>
            <ul>
                <li>3 photos visibles dans la liste des annonces</li>
                <li>6 photos visibles sur le détail de l'annonce</li>
                <li>Cette option payante sera active jusqu'à la fin de votre annonce ( maximum {ANNOUNCEMENT_VALIDITY_DURATION} jours)</li>
            </ul>
        </DialogContent>
    </ManyImagesDialog>
}
