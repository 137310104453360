import {
	SET_ANNOUNCEMENT_DATA,
	SET_PAYEMENT_OPTION,
	CLEAR_ANNOUNCEMENT
} from "../constants/ActionTypes";

const initialState = { announcement: {}, listOptionSubscribe: [], totalPrice: 0};

const AnnouncementReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ANNOUNCEMENT_DATA :
			return {
				...state,
				announcement: {...action.payload.announcement}
			}
		case SET_PAYEMENT_OPTION :
			return {
				...state,
				listOptionSubscribe: [...action.payload.listOptionSubscribe],
				totalPrice: action.payload.totalPrice
			}
		case CLEAR_ANNOUNCEMENT :
			return {
				...initialState
			}
		/*case ADD_ANNOUNCEMENT:
			return {
				...state,
				list: [...state.list, ...action.payload.announcements],
			};
		case ADD_ANNOUNCEMENT_FEATURED:
			return {
				...state,
				featuredList: [...state.featuredList, ...action.payload.announcements],
			};
		case REMOVE_ALL_ANNOUNCEMENT:
			return {
				...state,
				list: [],
			};
		case REMOVE_ALL_ANNOUNCEMENT_FEATURED:
			return {
				...state,
				featuredList: [],
			};
		case ADD_MY_ANNOUNCEMENT:
			return {
				...state,
				myAnnouncements: [...state.myAnnouncements, ...action.payload.announcements],
			};
		case REMOVE_MY_ANNOUNCEMENT:
			return {
				...state,
				myAnnouncements: [],
			};*/
		default:
			return state;
	}
};

export default AnnouncementReducer;
