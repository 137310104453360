import { SET_ROLE } from "../constants/ActionTypes";
import roles from "../constants/Role";

const initialState = {
	role: roles.PROFESSIONAL,
};

const RoleReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ROLE:
			return { ...state, role: action.payload.role };
		default:
			return state;
	}
};

export default RoleReducer;
