import { combineReducers, createStore } from "redux";
import AnnouncementReducer from "./AnnouncementReducer";
import ChatReducer from "./ChatReducer";
import ConnectionReducer from "./ConnectionReducer";
import FilterReducer from "./FilterReducer";
import InscriptionReducer from "./InscriptionReducer";
import RoleReducer from "./RoleReducer";
import UserInformationsReducer from "./User/UserInformationsReducer";

const store = createStore(
	combineReducers({
		role: RoleReducer,
		announcement: AnnouncementReducer,
		filter: FilterReducer,
		user: UserInformationsReducer,
		connection: ConnectionReducer,
		inscription: InscriptionReducer,
		chat: ChatReducer,
	}),
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
