import React, {useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import LockSharpIcon from "@mui/icons-material/LockSharp";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function PasswordInputContent({
    name,
    labelText,
    labelShrink,
    fullWidth,
    margin,
    pswValue,
    handleChange,
    error,
    helperText,
    ...props
}) {
    const [passwordIsVisible, setPasswordVisibility] = useState(false);

    const handleClickShowPassword = () => {
        setPasswordVisibility(!passwordIsVisible);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const inputLabelPropsValue = labelShrink ? { shrink: true } : null;

    return (
        <TextField
            {...props}
            name={name}
            label={labelText}
            placeholder="*******"
            fullWidth={fullWidth}
            margin={margin}
            InputLabelProps={inputLabelPropsValue}
            type={passwordIsVisible ? "text" : "password"}
            value={pswValue}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <LockSharpIcon color="disabled" />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="change la visibilité du mot de passe"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large">
                            {passwordIsVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            error={error}
            helperText={helperText}
            disabled={handleChange ? false : true}
        />
    );
}