import {Text} from "slate";
import escapeHtml from "escape-html";
import theme from "../../../theme";

export const serializeHTML = (node) => {
    let style = '';
    if (node['align']) style += `text-align: ${node['align']};`;
    // transform children proprieties
    if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        if (node['primary']) {
            string = `<span style="color: ${theme.palette.primary.main}">${string}</span>`
        }
        if (node['error']) {
            string = `<span style="color: ${theme.palette.error.main}">${string}</span>`
        }
        if (node.bold) {
            string = `<strong>${string}</strong>`
        }
        if (node.italic) {
            string = `<i>${string}</i>`
        }
        if (node.underline) {
            string = `<u>${string}</u>`
        }
        return string
    }
    const children = node.children.map(n => serializeHTML(n)).join('')
    // transform parent proprieties
    switch (node.type) {
        case 'heading-one':
            if (children === ""){
                return `<br>`;
            }
            return `<h1 style="${style}">${children}</h1>`
        case 'heading-two':
            if (children === ""){
                return `<br>`;
            }
            return `<h2 style="${style}">${children}</h2>`
        case 'heading-three':
            if (children === ""){
                return `<br>`;
            }
            return `<h3 style="${style}">${children}</h3>`
        case 'heading-four':
            if (children === ""){
                return `<br>`;
            }
            return `<h4 style="${style}">${children}</h4>`
        case 'heading-five':
            if (children === ""){
                return `<br>`;
            }
            return `<h5 style="${style}">${children}</h5>`
        case 'heading-six':
            if (children === ""){
                return `<br>`;
            }
            return `<h6 style="${style}">${children}</h6>`
        case 'paragraph':
            if (children === ""){
                return `<br>`;
            }
            return `<div style="${style}">${children}</div>`
        case 'numbered-list':
            return `<ol style="${style}">${children}</ol>`
        case 'bulleted-list':
            return `<ul style="${style}">${children}</ul>`
        case 'list-item':
            if (children === ""){
                return `<br>`;
            }
            return `<li style="${style}">${children}</li>`
        case 'link':
            style += `text-decoration: underline;`
            return `<a href="${node.href}" style="${style}" rel="noreferrer" target="_blank"> ${children}</a>`
        case 'image':
            return `<img src="${node.url}" style="${style} max-width: 50vw;" alt=""/>`
        case 'pdf':
            return `<div style="display: flex; justify-content: center">
                <a
                    href="${node.url}"
                    target="_blank"
                    rel="noreferrer"
                    style="
                        display: inline-flex;
                        -webkit-box-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        justify-content: center;
                        position: relative;
                        box-sizing: border-box;
                        outline: 0;
                        border: 0;
                        margin: 0;
                        cursor: pointer;
                        user-select: none;
                        vertical-align: middle;
                        -webkit-appearance: none;
                        text-decoration: none;
                        font-family: Roboto,Helvetica,Arial,sans-serif;
                        font-weight: 500;
                        line-height: 1.75;
                        letter-spacing: 0.02857em;
                        text-transform: uppercase;
                        min-width: 64px;
                        padding: 6px 16px;
                        transition:
                            background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                            border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                            color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                        color: #ffffff;
                        background-color: #2ab1ea;
                        box-shadow:
                            0px 3px 1px -2px rgba(0,0,0,0.2),
                            0px 2px 2px 0px rgba(0,0,0,0.14),
                            0px 1px 5px 0px rgba(0,0,0,0.12);
                        border-radius: 48px;
                    ">
                     Télécharger le PDF
                </a>
            </div>`
        default:
            return children
    }
}