/**
 * fetch all articles
 * @returns Promise
 */
const getArticlesByParamsAPI = (category = "", tags = [], page) => {
    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_API_URL}/blog/articles/by-params?page=${page}`
        if (category !== ""){
            url += `&category=${category}`
        }
        if (tags.length > 0){
            url += `&tags=${tags.toString()}`
        }
        const request = new Request(
            url,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            }
        );

        fetch(request)
            .then((res) => {
                res.json()
                    .then((json) => {
                        if (json.success) {
                            resolve({
                                articles: json.articles,
                                categories: json.categories,
                                totalPages: Number(json.totalPages),
                                currentPage: Number(json.currentPage),
                                tagsSelectable: json.tagsSelectable
                            });
                        } else {
                            console.error(json.error);
                            reject(json.error);
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                        reject(err);
                    });
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });
};

export default getArticlesByParamsAPI;
