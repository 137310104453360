import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInformation, setUserToken } from "../../actions/index";
import InscriptionForm from "../../components/AccessAccountPopUp/InscriptionForm";
import {
	independentType,
	individualType,
	organizationType,
	sportsorganizationType,
} from "../../constants/AccountType";
import { InscriptionSelector } from "../../selectors/index";
import createAccountAPI from "../../services/createAccountAPI";
import sendVerificationEmailAPI from "../../services/sendVerificationEmailAPI";
import {globalConfigDurationNotistack} from "../../constants/globalConstant";
import {validUrlEAPS} from "../../utils/validator/checkIfValid";


/**
 * Container to display inscription form
 * @param handleClose handle popup close
 */
function InscriptionFormContainer({ handleClose }) {
	const inscription = useSelector(InscriptionSelector);
	const accountType = inscription.accountType;

	const dispatch = useDispatch();

	const { enqueueSnackbar } = useSnackbar();

	const handleFinish = () => {
		const { mail, psw, address, city, zipCode, country, telephone, code } = {
			...inscription.accountShared,
			...inscription.accountGeneral,
			...inscription.sponsored
		};

		const genders = {
			Homme: "male",
			Femme: "female",
			Autre: "other",
		};

		let accountToSend = {
			email: mail,
			password: psw,
			address,
			city: city.properties.city,
			country,
			zipCode,
			telephone,
		};

		switch (accountType) {
			case independentType: {
				const {
					firstName,
					lastName,
					sex,
					birthDate,
					lang,
					siret,
					numCardPro,
					cardProExpiredAt,
					cardProDeliveryBy,
					allQualification,
				} = inscription.accountSpecific[accountType];
				let {
					urlProfilEaps
				} = inscription.accountSpecific[accountType];
				if (!validUrlEAPS(urlProfilEaps)) urlProfilEaps = '';

				for (let i =0; i<allQualification.length; i++){
					allQualification[i].gettingAt = new Date(allQualification[i].gettingAt);
					if (allQualification[i].lastRevisionAt !== null){
						allQualification[i].lastRevisionAt = new Date(allQualification[i].lastRevisionAt);
					}

					if (allQualification[i].doRecyclingBeforeAt !== null){
						allQualification[i].doRecyclingBeforeAt = new Date(allQualification[i].doRecyclingBeforeAt);
					}
				}


				accountToSend = {
					...accountToSend,
					name: firstName,
					lastName,
					gender: genders[sex],
					birthDate,
					siret,
					language: lang,
					urlProfilEaps,
					professionalCardNumber: numCardPro,
					professionalCardExpirationDate: new Date(cardProExpiredAt),
					professionalCardGivenBy: cardProDeliveryBy,
					qualifications: allQualification,
					sponsor: code
				};
				break;
			}
			case individualType: {
				const {
					firstName,
					lastName,
					sex,
					birthDate,
					lang,
				} = inscription.accountSpecific[accountType];

				accountToSend = {
					...accountToSend,
					name: firstName,
					lastName,
					gender: genders[sex],
					birthDate,
					language: lang,
				};
				break;
			}

			case organizationType: {
				const {
					name,
					status,
					managerFirstName,
					managerLastName,
					managerMail,
					siret,
					isFrenchCompany,
					vatNumber
				} = inscription.accountSpecific[accountType];
				if (isFrenchCompany){
					accountToSend = {
						...accountToSend,
						name,
						status,
						managerFirstName,
						managerLastName,
						managerMail,
						siret,
						sponsor: code
					};
				} else {
					accountToSend = {
						...accountToSend,
						name,
						status,
						managerFirstName,
						managerLastName,
						managerMail,
						vatNumber,
						sponsor: code
					};
				}
				break;
			}

			case sportsorganizationType: {
				const {
					name,
					status,
					managerFirstName,
					managerLastName,
					managerMail,
					managerLang,
					siret,
					isFrenchCompany,
					vatNumber
				} = inscription.accountSpecific[accountType];
				if (isFrenchCompany){
					accountToSend = {
						...accountToSend,
						name,
						status,
						managerFirstName,
						managerLastName,
						managerMail,
						language: managerLang,
						siret,
						sponsor: code
					};
				} else {
					accountToSend = {
						...accountToSend,
						name,
						status,
						managerFirstName,
						managerLastName,
						managerMail,
						language: managerLang,
						vatNumber,
						sponsor: code
					};
				}
				break;
			}
			default:
				break;
		}

		createAccountAPI(accountType, accountToSend)
			.then(({ user, token }) => {
				dispatch(setUserInformation(user));
				dispatch(setUserToken(token));
				handleClose();
				sendVerificationEmailAPI(token)
					.then((message) => {
						// variant could be success, error, warning, info, or default
						enqueueSnackbar(message, {
							variant: "success",
							autoHideDuration: globalConfigDurationNotistack
						});
					})
					.catch((err) => {
						// variant could be success, error, warning, info, or default
						enqueueSnackbar(err, {
							variant: "error",
							autoHideDuration: globalConfigDurationNotistack
						});
					});
				// variant could be success, error, warning, info, or default
				enqueueSnackbar(`Bienvenue sur WeGuideYou!`, {
					variant: "success",
					autoHideDuration: globalConfigDurationNotistack
				});
			})
			.catch((_) => {
				// variant could be success, error, warning, info, or default
				enqueueSnackbar("L'adresse mail a déjà été utilisée!", {
					variant: "error",
					autoHideDuration: globalConfigDurationNotistack
				});
			});
	};

	return (
		<InscriptionForm
			handleFinish={handleFinish}
			handleClose={handleClose}
		/>
	);
}

export default InscriptionFormContainer;
