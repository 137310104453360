import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import validator from "validator";
import ForgottenPasswordContainer from "../../containers/AccessAccountPopUp/ForgottenPasswordContainer";
import { MailInput, PasswordInput } from "../Inputs";
import LoadingButton from '@mui/lab/LoadingButton';

/**
 * Component to display connection form
 * @param connectionValue value of connection fields
 * @param onChange callback to call when values change
 * @param handleConnection callback to call when user wants to connect
 * @param googleNoRegister
 */
function ConnectionForm({ connectionValue, onChange, handleConnection }) {
	const [state, setState] = useState(connectionValue);
	const [loginClicked, setLoginClicked] = useState(false);

	//const [googleNoRegister, setSocialNetwork] = useState(false) ;
	//Check if user try ton connect with google without register before
	if (localStorage.getItem('googleNoRegister')){
		//display alert message
	//	setSocialNetwork( true);
		localStorage.removeItem('googleNoRegister');
	}

	const mailIsValid = () => {
		return validator.isEmail(state.mail);
	};

	useEffect(() => {
		onChange(state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	const handleInputChange = async (e) => {
		const target = e.target;
		const value = target.value;
		const name = target.name;

		setState({
			...state,
			[name]: value,
		});
	};

//	const urlFacebookConnect = `${process.env.REACT_APP_API_URL}/auth/facebook`;

//	const urlGoogleConnect = `${process.env.REACT_APP_API_URL}/auth/google`;


	return (
		<Grid container direction="column" spacing={3}>
			<Grid item xs>
				<MailInput
					id={'login-mail'}
					name="mail"
					value={state.mail}
					labelText="Adresse mail"
					labelShrink
					placeholder="johndoe@gmail.com"
					fullWidth
					handleChange={handleInputChange}
					error={state.mail !== "" && !mailIsValid()}
					helperText={
						state.mail !== "" && !mailIsValid()
							? "L'adresse mail doit être valide !"
							: " "
					}
				/>
				<PasswordInput
					id={'login-password'}
					name="psw"
					labelText="Mot de passe"
					labelShrink
					fullWidth
					pswValue={state.psw}
					handleChange={handleInputChange}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							handleConnection(state.mail, state.psw);
						}
					}}
				/>
				<ForgottenPasswordContainer />
			</Grid>

			<Grid item container xs direction="row" justifyContent="center">
				<Grid item xs>
					<LoadingButton
						id={"login-button"}
						loading={loginClicked}
						variant="contained"
						color="primary"
						onClick={() => handleConnection(state.mail, state.psw, setLoginClicked)}
					>
						SE CONNECTER
					</LoadingButton>
				</Grid>
			</Grid>
			{/*<Grid item xs={12}>

				<Divider>
					<Typography variant="h3" color="primary.main" textAlign="center">OU</Typography>
				</Divider>
				<Typography variant="h5" color="primary.main" textAlign="center">Connectez-vous avec vos réseaux sociaux</Typography>
				<Stack sx={{ width: '100%', mt: 4 }} spacing={2}>
					<Alert severity="info">Pour vous connecter avec vos réseaux sociaux, vous devez dabord vous créer
						votre compte avec l'adresse mail lié à votre réseau social
					</Alert>
				</Stack>
				<Grid container spacing={4} justifyContent='center' sx={{mt: 2}}>
					<Grid item container xs={12} md={6} lg={5} xl={4} justifyContent='center'>
						<a href={urlGoogleConnect}>
							<Button variant="contained" startIcon={<GoogleIcon />} color="error" >
								Continuer avec Google
							</Button>
						</a>
					</Grid>
					<Grid item container xs={12} md={6} lg={5} xl={4} justifyContent='center'>
						<a href={urlFacebookConnect}>
							<Button variant="contained" startIcon={<FacebookIcon />} sx={{backgroundColor: "#1265a6"}} >
								Continuer avec Facebook
							</Button>
						</a>
					</Grid>
				</Grid>

				{googleNoRegister &&
					<Stack sx={{ width: '100%', mt: 4 }} spacing={2}>
						<Alert severity="error">L'adresse mail du réseau sociaux utilisés n'est associé à aucun compte.
							Merci de d'abord créer votre compte avec cette adresse mail pour pouvoir vous connecter par
							le biais de votre réseau social préféré
						</Alert>
					</Stack>
				}
			</Grid>*/}
		</Grid>
	);
}

export default ConnectionForm;
