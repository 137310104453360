/**
 * Where every action type is.
 * Action type example:
 * export const ADD_TODO = "ADD_TODO";
 */

/**
 * Actions of Role Reducer
 */
export const SET_ROLE = "SET_ROLE";

/**
 * Actions of Filter Reducer
 */
export const SET_FILTER_ANNOUNCEMENT_TYPE = "SET_FILTER_ANNOUNCEMENT_TYPE";
export const SET_FILTER_CREATOR_TYPE = "SET_FILTER_CREATOR_TYPE";
export const SET_FILTER_ACTIVITY = "SET_FILTER_ACTIVITY";
export const SET_FILTER_START_DATE = "SET_FILTER_START_DATE";
export const SET_FILTER_END_DATE = "SET_FILTER_END_DATE";
export const SET_FILTER_PLACE = "SET_FILTER_PLACE";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_FILTER_SECONDARY_COURSE_TYPE = "SET_FILTER_SECONDARY_COURSE_TYPE";
export const SET_FILTER_SECONDARY_CREATED_DATE = "SET_FILTER_SECONDARY_CREATED_DATE";
export const SET_FILTER_SECONDARY_PERIOD = "SET_FILTER_SECONDARY_PERIOD";
export const SET_FILTER_SECONDARY_COURSE_LEVEL = "SET_FILTER_SECONDARY_COURSE_LEVEL";
export const SET_FILTER_SECONDARY_LANGUAGE = "SET_FILTER_SECONDARY_LANGUAGE";
export const SET_FILTER_SECONDARY_COUNTRY = "SET_FILTER_SECONDARY_COUNTRY";
export const SET_FILTER_SECONDARY_CONTRACT_TYPE = "SET_FILTER_SECONDARY_CONTRACT_TYPE";
export const SET_FILTER_SECONDARY_GRADUATED = "SET_FILTER_SECONDARY_GRADUATED";
export const SET_FILTER_SECONDARY_PROVIDED_OUTFIT = "SET_FILTER_SECONDARY_PROVIDED_OUTFIT";
export const SET_FILTER_SECONDARY_HOUSING = "SET_FILTER_SECONDARY_HOUSING";
export const SET_FILTER_SECONDARY_PROVIDED_WORK_CLOTHES = "SET_FILTER_SECONDARY_PROVIDED_WORK_CLOTHES";

/**
 * Actions of Create Announcement Reducer
 */
export const SET_CONNECTION = "SET_CONNECTION";


/**
 * Actions of Inscription Reducer
 */
export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE";
export const SET_ACCOUNT_GENERAL = "SET_ACCOUNT_GENERAL";
export const SET_SPONSORED = "SET_SPONSORED";
export const SET_ACCOUNT_SHARED = "SET_ACCOUNT_SHARED";
export const SET_ACCOUNT_SPECIFIC = "SET_ACCOUNT_SPECIFIC";

/**
 * Actions of User Information Reducer
 */
export const SET_USER_INFORMATIONS = "SET_USER_INFORMATIONS";
export const SET_USER_PROFILE_INFORMATIONS = "SET_USER_PROFILE_INFORMATIONS";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_DISCONNECTED = "SET_USER_DISCONNECTED";
export const SET_USER_FAVOURITE = "SET_USER_FAVOURITE";

/**
 * Actions of User settings
 */
export const SET_USER_INDIVIDUAL_INFO = "SET_USER_INDIVIDUAL_INFO";
export const SET_USER_PROFESSIONAL_INFO = "SET_USER_PROFESSIONAL_INFO";
export const SET_USER_ORGANIZATION_INFO = "SET_USER_PROFESSIONAL_INFO";
export const SET_USER_ACTIVITIES = "SET_USER_ACTIVITIES";
export const SET_USER_ADDRESS = "SET_USER_ADDRESS";
export const SET_USER_IDENTITY_CARD = "SET_USER_IDENTITY_CARD";
export const SET_USER_MAIL = "SET_USER_MAIL";
export const SET_USER_RCPRO = "SET_USER_RCPRO";
export const SET_USER_URSAFF = "SET_USER_URSAFF";
export const SET_USER_PASSWORD = "SET_USER_PASSWORD";
export const SET_USER_SIRET = "SET_USER_SIRET";
export const SET_USER_WEBSITE = "SET_USER_WEBSITE";

/**
 * Actions of Announcement Reducer
 */
export const ADD_ANNOUNCEMENT = "ADD_ANNOUNCEMENT";
export const REMOVE_ALL_ANNOUNCEMENT = "REMOVE_ALL_ANNOUNCEMENT";
export const ADD_ANNOUNCEMENT_FEATURED = "ADD_ANNOUNCEMENT_FEATURED";
export const REMOVE_ALL_ANNOUNCEMENT_FEATURED = "REMOVE_ALL_ANNOUNCEMENT_FEATURED";
export const ADD_MY_ANNOUNCEMENT = "ADD_MY_ANNOUNCEMENT";
export const REMOVE_MY_ANNOUNCEMENT = "REMOVE_MY_ANNOUNCEMENT";
export const SET_ANNOUNCEMENT_DATA = "SET_ANNOUNCEMENT_DATA";
export const SET_PAYEMENT_OPTION = "SET_PAYEMENT_OPTION";
export const CLEAR_ANNOUNCEMENT = "CLEAR_ANNOUNCEMENT"

/**
 * Actions of Chat Reducer
 */
export const SET_CHAT_ROOMS = "SET_CHAT_ROOMS";
export const ADD_CHAT_ROOM = "ADD_CHAT_ROOM";
export const NEW_CHAT_MESSAGE = "NEW_CHAT_MESSAGE";
export const SET_READ_ALL_MESSAGE = "SET_READ_ALL_MESSAGE";
export const SET_CHAT_OFFER = "SET_CHAT_OFFER";
export const SET_CHAT_RESERVATION = "SET_CHAT_RESERVATION";
export const SET_CHAT_CANCEL_RESERVATION = "SET_CHAT_CANCEL_RESERVATION";
export const SET_CHAT_CANCEL_RESERVATION_WITHOUT_CHATROOM = "SET_CHAT_CANCEL_RESERVATION_WITHOUT_CHATROOM";
