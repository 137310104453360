import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setAccountGeneral } from "../../actions";
import AccountGeneralForm from "../../components/AccessAccountPopUp/AccountGeneralForm";

/**
 * Container to display account general form
 * @param setValid change form validity
 * @param inscription redux store data of register
 */
function AccountGeneralFormContainer({ setValid, inscription }) {
	const dispatch = useDispatch();
	const callback = useCallback(
		(accountGeneral) => {
			dispatch(setAccountGeneral(accountGeneral));
		},
		[dispatch]
	);

	const accountGeneral = inscription.accountGeneral;

	return (
		<AccountGeneralForm
			accountGeneralValue={accountGeneral}
			onChange={callback}
			setValid={setValid}
		/>
	);
}

export default AccountGeneralFormContainer;
