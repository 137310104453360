import React, {useEffect, useMemo, useState} from "react";
import {Grid, InputAdornment} from "@mui/material";
import {search} from "country-js";
import CityAutocomplete from "../Autocomplete/CityAutocomplete";
import {TextInput} from "../Inputs";
import { telIsValid} from "../../utils/validator/isValid";
import {CountryNameOrIndicatorChoiceInput} from "../Inputs";
import {parseIndicatorCountryToObject, parseNameCountryToObject} from "../../utils/user/parseNameOrIndicatorCountry";

/**
 * Component to display shared form
 * @param accountSharedValue form state, handle all inputs
 * @param onChange callback to call when value change
 * @param setValid change form validity
 */
function AccountSharedForm({accountSharedValue, onChange, setValid}) {
    // Init state with account sharedValue &  set country & phone indicator with object corresponding on ALLCountryCode
    // Field autocomplete for indicator & name country work with this object
    const [state, setState] = useState({
        ...accountSharedValue,
        country: parseNameCountryToObject(accountSharedValue.country),
        telephone: {
            ...accountSharedValue.telephone,
            indicator: parseIndicatorCountryToObject(accountSharedValue.telephone.indicator)
        }
    });

    const cityIsValid = () => {
        return !!state.city;
    };

    useEffect(() => {
        setValid(
            cityIsValid() &&
            telIsValid(state.telephone.number)
        );
        onChange({
            ...state,
            country: state.country.value,
            telephone: {
                ...state.telephone,
                indicator: search(state.telephone.indicator.code)[0].phone
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, setValid]);

    const handleTelNumberChange = async (e) => {
        const target = e.target;
        const value = target.value;

        setState({
            ...state,
            telephone: {
                ...state.telephone,
                number: value,
            },
        });
    };

    const handleTelIndicatorChange = async (newValue) => {

        setState({
            ...state,
            telephone: {
                ...state.telephone,
                indicator: newValue,
            },
        });
    };

    const handleCityChange = async (e) => {
        setState({
            ...state,
            city: e,
        });
    };

    const handleCountryChange = async (newValue) => {
        //When country change set by default indicator with indicator of this country
        setState({
            ...state,
            country: newValue,
            telephone: {
                ...state.telephone,
                indicator: newValue
            }
        })
    }

    useMemo(
        () => {
            //On each change on state.city, set zipCode & adresse corresponding
            if (state.city) {
                setState({
                    ...state,
                    zipCode: state.city.properties.postcode,
                    address: state.city.properties.address,
                });
            } else {
                setState({
                    ...state,
                    zipCode: "",
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state.city]
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CountryNameOrIndicatorChoiceInput
                    handleChange={handleCountryChange}
                    value={state.country}
                    name={"country-input"}
                    labelText={"Pays"}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={12}>
                <CityAutocomplete
                    label="Rue, Code Postal, Ville"
                    city={state.city}
                    setCity={handleCityChange}
                    createAnnouncement={true}
                    countryValue={[state.country.value]}
                    //displayCountry
                />
            </Grid>

            <Grid item xs={12} md={3}>
                <CountryNameOrIndicatorChoiceInput
                    handleChange={handleTelIndicatorChange}
                    value={state.telephone.indicator}
                    name={"indicatif-input"}
                    labelText={"Indicatif"}
                    fullWidth={true}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">+</InputAdornment>
                        ),
                    }}
                    isIndicatorInput={true}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput
                    id={"phone-number"}
                    name="number"
                    fullWidth
                    labelText="Téléphone"
                    labelShrink
                    placeholder="647..."
                    value={state.telephone.number}
                    handleChange={handleTelNumberChange}
                    error={state.telephone.number !== "" && !telIsValid(state.telephone.number)}
                    helperText={
                        state.telephone.number !== "" &&
                        !telIsValid(state.telephone.number) &&
                        "Le numéro de téléphone doit contenir de 4 à 12 chiffres !"
                    }
                />
            </Grid>
        </Grid>
    );
}

export default AccountSharedForm;
