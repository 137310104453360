import {CircularProgress, TextField} from "@mui/material";
import { Autocomplete } from '@mui/material';
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { UserInformationsSelector } from "../../selectors/index";

/**
 * Component input with autocompletion driven by api
 * @param label input label
 * @param value input value
 * @param setValue callback to call when value change
 * @param onAutocomplete callback to call when user type in
 * @param getOptionSelected function to get options selected
 * @param getOptionLabel function to get label
 * @param errorFormAd all error statut form
 * @param helperText text to display when error find
 * @param setErrorFormAd set status of field when he was modify (remove error)
 */
function APIDriven({
	label,
	value,
	setValue,
	onAutocomplete,
	getOptionSelected,
	getOptionLabel,
	errorFormAd = false,
	helperText = null,
	setErrorFormAd = null
}) {
	const user = useSelector(UserInformationsSelector);

	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);


	let controller = useRef(new AbortController());
	const onTextChange = (text) => {
		setLoading(true);
		controller.current.abort();
		controller.current = new AbortController();
		onAutocomplete(text, user, setOptions, setLoading, controller);
	};

	const onChangeProperties = (e,v) => {
		setValue(v);
		if (setErrorFormAd !== null){
			setErrorFormAd({...errorFormAd, address: false});
		}

	}

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	return (
        <Autocomplete
			id={'address'}
			open={open && options.length > 0}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			isOptionEqualToValue={getOptionSelected}
			getOptionLabel={getOptionLabel}
			options={options}
			loading={loading}
			onChange={(e, v) => onChangeProperties(e, v)}
			value={value}
			filterOptions={(options, state) => options}
			renderOption={(props, city) => {
				return (
					<li {...props} key={Math.random()*100}>
						{getOptionLabel(city)}
					</li>
				)}}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					variant="outlined"
					onChange={(e) => onTextChange(e.target.value)}
					fullWidth
					error={errorFormAd.address}
					helperText={
						errorFormAd !== false ?
							errorFormAd.address ?
								helperText :
								"Vous pouvez cliquer sur la carte pour sélectionner votre adresse" : null
					}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
			disabled={typeof setValue != "function"}
		/>
    );
}

export default APIDriven;
