import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import theme from "../../theme";

/**
 * Component to display next, back and finish buttons
 * @param contentSize content size
 * @param index position in form pages
 * @param nextButtonIsActive if true, next button will be active
 * @param handlePrevious callback to call when click on previous button
 * @param handleNext callback to call when click on next button
 * @param handleFinish callback to call when click on finish button
 */
function ActionsComponent({
	contentSize,
	index,
	nextButtonIsActive,
	handlePrevious,
	handleNext,
	handleFinish,
	canSkip
}) {
	const isAtBeginning = index === 0;
	const isAtEnd = index === contentSize - 1;
	return (
		<Grid item container justifyContent="flex-end" spacing={3}>
			{!isAtBeginning && (
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={handlePrevious}
					>
						PRECEDENT
					</Button>
				</Grid>
			)}
			{!isAtEnd && (
				canSkip === true ? !nextButtonIsActive ? (
					<Grid item>
						<Button
							id={'skip'}
							variant="outlined"
							sx={{color: theme.palette.primary.main}}
							color="primary"
							onClick={handleNext}
						>
							PASSER
						</Button>
					</Grid>
				) : (
					<Grid item>
						<Button
							id={'next'}
							variant="contained"
							color="primary"
							onClick={handleNext}
						>
							SUIVANT
						</Button>
					</Grid>
				) : (
					<Grid item>
						<Button
							id={'next'}
							variant="contained"
							color="primary"
							onClick={handleNext}
							disabled={!nextButtonIsActive}
						>
							SUIVANT
						</Button>
					</Grid>
				)
			)}
			{isAtEnd && (
				<Grid item>
					<Button
						id={"sign-in-button"}
						variant="contained"
						color="primary"
						onClick={handleFinish}
						disabled={!nextButtonIsActive}
					>
						S'INSCRIRE
					</Button>
				</Grid>
			)}
		</Grid>
	);
}

function MultiPageForm({
	content,
	index,
	handlePrevious,
	handleNext,
	handleFinish,
}) {
	// when not valid, next button will be disabled (set by form)
	const [valid, setIsValid] = useState(false);
	const actual = content[index];

	// we add a reference to setIsValid function
	const actualComponent = React.cloneElement(actual.component, {
		setValid: setIsValid,
	});

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				{actualComponent}
			</Grid>
			{actual.buttonsAreShown && (
				<Grid item xs={12}>
					<ActionsComponent
						contentSize={content.length}
						index={index}
						nextButtonIsActive={valid}
						handlePrevious={handlePrevious}
						handleNext={handleNext}
						handleFinish={handleFinish}
						canSkip={actual.canSkip}
					/>
				</Grid>
			)}
		</Grid>
	);
}

export default MultiPageForm;
