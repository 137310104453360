import {
    CLEAR_FILTER,
    SET_FILTER_ACTIVITY,
    SET_FILTER_ANNOUNCEMENT_TYPE,
    SET_FILTER_CREATOR_TYPE,
    SET_FILTER_END_DATE,
    SET_FILTER_PLACE,
    SET_FILTER_SECONDARY_COURSE_TYPE,
    SET_FILTER_SECONDARY_PERIOD,
    SET_FILTER_SECONDARY_COURSE_LEVEL,
    SET_FILTER_SECONDARY_LANGUAGE,
    SET_FILTER_START_DATE,
    SET_FILTER_SECONDARY_COUNTRY,
    SET_FILTER_SECONDARY_CONTRACT_TYPE,
    SET_FILTER_SECONDARY_GRADUATED,
    SET_FILTER_SECONDARY_PROVIDED_OUTFIT,
    SET_FILTER_SECONDARY_PROVIDED_WORK_CLOTHES,
    SET_FILTER_SECONDARY_HOUSING, SET_FILTER_SECONDARY_CREATED_DATE
} from "../constants/ActionTypes";

const initialState = {
    createdDate: "",
    announcementType: "",
    creatorType: "",
    activity: [],
    startDate: null,
    endDate: null,
    place: null,
    courseType: "",
    period: [],
    courseLevel: "",
    language: [],
    country: ["France"],
    contractType: [],
    graduated: "",
    providedOutfit: "",
    providedWorkClothes: "",
    housing: ""
};

const FilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTER_SECONDARY_CREATED_DATE:
            return {
                ...state,
                createdDate: action.payload.createdDate,
            }
        case SET_FILTER_ANNOUNCEMENT_TYPE:
            return {
                ...state,
                announcementType: action.payload.announcementType,
            };
        case SET_FILTER_CREATOR_TYPE:
            return {
                ...state,
                creatorType: action.payload.creatorType,
            };
        case SET_FILTER_ACTIVITY:
            return {
                ...state,
                activity: action.payload.activity,
            };
        case SET_FILTER_START_DATE:
            return {
                ...state,
                startDate: action.payload.startDate,
            };
        case SET_FILTER_END_DATE:
            return {
                ...state,
                endDate: action.payload.endDate,
            };
        case SET_FILTER_PLACE:
            return {
                ...state,
                place: action.payload.place,
            };
        case SET_FILTER_SECONDARY_COURSE_TYPE:
            return {
                ...state,
                courseType: action.payload.courseType,
            };
        case SET_FILTER_SECONDARY_PERIOD:
            return {
                ...state,
                period: action.payload.period,
            };
        case SET_FILTER_SECONDARY_COURSE_LEVEL:
            return {
                ...state,
                courseLevel: action.payload.courseLevel,
            };
        case SET_FILTER_SECONDARY_LANGUAGE:
            return {
                ...state,
                language: action.payload.language,
            };
        case SET_FILTER_SECONDARY_COUNTRY:
            return {
                ...state,
                country: action.payload.country,
            };
        case SET_FILTER_SECONDARY_CONTRACT_TYPE:
            return {
                ...state,
                contractType: action.payload.contractType
            }
        case SET_FILTER_SECONDARY_GRADUATED:
            return {
                ...state,
                graduated: action.payload.graduated,
            };
        case SET_FILTER_SECONDARY_PROVIDED_OUTFIT:
            return {
                ...state,
                providedOutfit: action.payload.providedOutfit,
            }
        case SET_FILTER_SECONDARY_PROVIDED_WORK_CLOTHES:
            return {
                ...state,
                providedWorkClothes: action.payload.providedWorkClothes,
            }
        case SET_FILTER_SECONDARY_HOUSING:
            return {
                ...state,
                housing: action.payload.housing,
            }
        case CLEAR_FILTER:
            return {
                ...initialState,
                country: ["France"],
                activity: [],
                language: [],
                period: [],
                contractType: []
            };
        default:
            return state;
    }
};

export default FilterReducer;
