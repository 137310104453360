import React, {useState, lazy, Suspense, useCallback} from "react";
import {
    Avatar,
    Button,
    Grid,
    Hidden,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { MoreVertRounded } from "@mui/icons-material";
import AddCommentSharpIcon from "@mui/icons-material/AddCommentSharp";
import InputSharpIcon from "@mui/icons-material/InputSharp";
import PersonAddSharpIcon from "@mui/icons-material/PersonAddSharp";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Link, useNavigate } from "react-router-dom";
import AccountType from "../../constants/AccountType";
import { AccessAccountForms } from "../AccessAccountPopUp/AccessAccountPopUp";
import AvatarContainer from "../AvatarContainer";
import MessageIcon from "./MessageIcon";
import CircularProgress from "@mui/material/CircularProgress";
import {
    ROUTE_CHAT,
    ROUTE_CREATE_ANNOUNCEMENT,
    ROUTE_HOME,
    ROUTE_PROFESSIONAL_ANNOUNCEMENTS,
    ROUTE_PARTICULAR_ANNOUNCEMENTS, ROUTE_VERIFY
} from "../../config/routesConstant";
import SearchIcon from '@mui/icons-material/Search';
import {useDispatch} from "react-redux";
import {setRole} from "../../actions";
import roles from "../../constants/Role";
const AccessAccountPopUp = lazy(() => import("../AccessAccountPopUp/AccessAccountPopUp"));

const PREFIX = 'ToolboxComponent';

const classes = {
    title: `${PREFIX}-title`,
    logo: `${PREFIX}-logo`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.title}`]: {
        flexGrow: 1,
    },

    [`& .${classes.logo}`]: {
        marginRight: theme.spacing(1),
    }
}));


/**
 * Component to display navigation bar content
 * @param title navbar title
 * @param isConnected if true, display user informations
 * @param user current user
 * @param openDrawer 	callback to call when user open drawer
 */
function ToolboxComponent({ title, isConnected = false, user, openDrawer }) {

    const dispatch = useDispatch();
    const setRoleCallback = useCallback(
        (role) => {
            dispatch(setRole(role));
        },
        [dispatch]
    );

    const [accessAccountState, setAccessAccountState] = useState({
        isActive: false,
        activeForm: AccessAccountForms.CONNECTION,
    });

    const navigate = useNavigate();

    const [anchorElMobileMenu, setAnchorElMobileMenu] = React.useState(null);

    const handleClickMobileMenu = (event) => {
        setAnchorElMobileMenu(event.currentTarget);
    };

    const handleCloseMobileMenu = () => {
        setAnchorElMobileMenu(null);
    };

    const handleAccessAccountChangeActive = (event, newValue) => {
        setAccessAccountState({
            ...accessAccountState,
            activeForm: newValue,
        });
    };

    const handleClickConnection = () => {
        setAccessAccountState({
            isActive: true,
            activeForm: AccessAccountForms.CONNECTION,
        });
    };

    const handleClickInscription = () => {
        setAccessAccountState({
            isActive: true,
            activeForm: AccessAccountForms.INSCRIPTION,
        });
    };

    const handleCloseAccessAccountPopUp = (value) => {
        setAccessAccountState({
            ...accessAccountState,
            isActive: false,
        });
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    const [anchorElSearchNonConnected, setAnchorElSearchNonConnected] = React.useState(null);
    const openMenuSearchNonConnected = Boolean(anchorElSearchNonConnected);
    const handleClickMenuSearchNonConnected = (event) => {
        setAnchorElSearchNonConnected(event.currentTarget);
    };
    const handleCloseMenuSearchNonConnected = () => {
        setAnchorElSearchNonConnected(null);
    };

    const userItems = [
        {
            text: "Vérifier un PRO",
            onClick: () => {
                navigate(ROUTE_VERIFY);
            },
            icon: <HowToRegIcon />,
            for: [
                AccountType.INDIVIDUAL,
                AccountType.INDEPENDENT_PROFESSIONAL,
                AccountType.ORGANIZATION,
                AccountType.SPORTS_ORGANIZATION,
            ],
        },
        {
            text: "Rechercher",
            onClick: () => {
                navigate(ROUTE_PROFESSIONAL_ANNOUNCEMENTS);
            },
            icon: <SearchIcon />,
            for: [
                AccountType.INDEPENDENT_PROFESSIONAL,
                AccountType.ORGANIZATION,
                AccountType.SPORTS_ORGANIZATION,
            ],
        },
        {
            text: "Rechercher",
            onClick: () => {
                navigate(ROUTE_PARTICULAR_ANNOUNCEMENTS);
            },
            icon: <SearchIcon />,
            for: [
                AccountType.INDIVIDUAL,
            ],
        },
        {
            id: "create-ads",
            text: "Créer une annonce",
            onClick: () => {
                navigate(ROUTE_CREATE_ANNOUNCEMENT);
            },
            icon: <AddCommentSharpIcon />,
            for: [
                AccountType.INDEPENDENT_PROFESSIONAL,
                AccountType.ORGANIZATION,
                AccountType.SPORTS_ORGANIZATION,
            ],
        },
        {
            text: "Mes messages",
            onClick: () => {
                navigate(ROUTE_CHAT);
            },
            icon: <MessageIcon user={user} />,
            for: [
                AccountType.INDIVIDUAL,
                AccountType.INDEPENDENT_PROFESSIONAL,
                AccountType.ORGANIZATION,
                AccountType.SPORTS_ORGANIZATION,
            ],
        },
    ];

    const userItemsDesktop = userItems.map((item, index) => {
        if (user && item.for.includes(user.kind))
            return (
                <Grid item key={index}>
                    <Button
                        id={item.id && item.id}
                        color="inherit"
                        onClick={item.onClick}
                        startIcon={item.icon}
                    >
                        {item.text}
                    </Button>
                </Grid>
            );
        return null;
    });

    const userItemsMobile = userItems.map((item, index) => {
        if (user && item.for.includes(user.kind))
            return (
                <MenuItem
                    key={index}
                    onClick={() => {
                        item.onClick();
                        handleCloseMobileMenu();
                    }}
                >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography variant="inherit">{item.text}</Typography>
                </MenuItem>
            );
        return null;
    });

    const nonConnectedItems = [
        {
            id: "verify",
            text: "Vérifier un PRO",
            onClick: () => {
                navigate(ROUTE_VERIFY);
            },
            icon: <HowToRegIcon />,
            for: [
                AccountType.INDIVIDUAL,
                AccountType.INDEPENDENT_PROFESSIONAL,
                AccountType.ORGANIZATION,
                AccountType.SPORTS_ORGANIZATION,
            ],
        },
        {
            id: "search",
            text: "Rechercher",
            onClick: () => {
                setRoleCallback(roles.PROFESSIONAL);
                navigate(ROUTE_PROFESSIONAL_ANNOUNCEMENTS);
            },
            icon: <SearchIcon />,
            onlyMobile: false,
        },
        {
            text: "Rechercher en tant que particulier",
            onClick: () => {
                setRoleCallback(roles.INDIVIDUAL);
                navigate(ROUTE_PARTICULAR_ANNOUNCEMENTS);
            },
            icon: <SearchIcon />,
            onlyMobile: true,
        },
        {
            id: "login",
            text: "Connexion",
            onClick: () => {
                handleClickConnection();
            },
            icon: <InputSharpIcon />,
            onlyMobile: false,
        },
        {
            id: "sign-in",
            text: "Inscription",
            onClick: () => {
                handleClickInscription();
            },
            icon: <PersonAddSharpIcon />,
            onlyMobile: false,
        },
    ];

    const nonConnectedItemsDesktop = nonConnectedItems.map((item, index) => {
        if (!item.onlyMobile){
            if (item.text === "Rechercher"){
                return (
                    <Grid item key={index}>
                        <Button
                            id="search"
                            color="inherit"
                            aria-controls={openMenuSearchNonConnected ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenuSearchNonConnected ? 'true' : undefined}
                            onClick={handleClickMenuSearchNonConnected}
                            startIcon={item.icon}
                        >
                            Rechercher
                        </Button>
                        <Menu
                            id="search-menu"
                            anchorEl={anchorElSearchNonConnected}
                            open={openMenuSearchNonConnected}
                            onClose={handleCloseMenuSearchNonConnected}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem
                                id={"search-individual"}
                                onClick={() => {
                                    setRoleCallback(roles.INDIVIDUAL);
                                    handleCloseMenuSearchNonConnected();
                                    navigate(ROUTE_PARTICULAR_ANNOUNCEMENTS)

                                }}
                            >
                                En tant que Particulier
                            </MenuItem>
                            <MenuItem
                                id={"search-professional"}
                                onClick={() => {
                                    setRoleCallback(roles.PROFESSIONAL);
                                    handleCloseMenuSearchNonConnected();
                                    navigate(ROUTE_PROFESSIONAL_ANNOUNCEMENTS);
                                }}
                            >
                                En tant que Professionnel
                            </MenuItem>
                        </Menu>
                    </Grid>
                )
            } else {
                return (
                    <Grid item key={index}>
                        <Button
                            id={item.id && item.id}
                            color="inherit"
                            onClick={item.onClick}
                            startIcon={item.icon}
                        >
                            {item.text}
                        </Button>
                    </Grid>
                );
            }
        } else return null


    });

    const nonConnectedItemsMobile = nonConnectedItems.map((item, index) => {

            return (
                <MenuItem
                    key={index}
                    onClick={() => {
                        item.onClick();
                        handleCloseMobileMenu();
                    }}
                >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography variant="inherit">
                        {item.text === "Rechercher" ?
                            item.text + " en tant que professionnel" :
                            item.text
                        }
                    </Typography>
                </MenuItem>
            );
    });

    return (
        <Root>
            <Toolbar>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <div className={classes.title}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Avatar
                                component={Link}
                                to={ROUTE_HOME}
                                className={classes.logo}
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/WGY.png"
                                }
                                alt="We Guide You"
                                onClick={scrollToTop}
                            />
                            <Typography
                                component={Link}
                                color="inherit"
                                to={ROUTE_HOME}
                                variant="h5"
                                onClick={scrollToTop}
                            >
                                {title}
                            </Typography>
                        </Grid>
                    </div>
                    <div>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={5}
                        >
                            {isConnected ? (
                                <>
                                    <Hidden lgDown>
                                        {userItemsDesktop}
                                        <Grid item onClick={openDrawer} id={"user-actions"} sx={{cursor: "pointer"}}>
                                            <AvatarContainer
                                                user={user}
                                                usernameColor="white"
                                                expandIcon
                                                withText={true}
                                            />
                                        </Grid>
                                    </Hidden>
                                    <Hidden lgUp>
                                        <Grid item>
                                            <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={handleClickMobileMenu}
                                                color="inherit"
                                                size="large">
                                                <MoreVertRounded />
                                            </IconButton>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorElMobileMenu}
                                                keepMounted
                                                open={Boolean(
                                                    anchorElMobileMenu
                                                )}
                                                onClose={handleCloseMobileMenu}
                                            >
                                                {userItemsMobile}
                                            </Menu>
                                        </Grid>
                                        <Grid item onClick={openDrawer} id={"user-actions"} sx={{cursor: "pointer"}}>
                                            <AvatarContainer
                                                user={user}
                                                usernameColor="white"
                                                expandIcon
                                                withText={false}
                                            />
                                        </Grid>
                                    </Hidden>
                                </>
                            ) : (
                                <>
                                    <Hidden lgDown>
                                        {nonConnectedItemsDesktop}
                                    </Hidden>
                                    <Hidden lgUp>
                                        <Grid item>
                                            <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={handleClickMobileMenu}
                                                color="inherit"
                                                size="large">
                                                <MoreVertRounded />
                                            </IconButton>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorElMobileMenu}
                                                keepMounted
                                                open={Boolean(
                                                    anchorElMobileMenu
                                                )}
                                                onClose={handleCloseMobileMenu}
                                            >
                                                {nonConnectedItemsMobile}
                                            </Menu>
                                        </Grid>
                                    </Hidden>
                                </>
                            )}
                        </Grid>
                    </div>
                </Grid>
            </Toolbar>
            {!isConnected && (
                <Suspense fallback={ <CircularProgress sx={{position: "absolute", top: "200px", left:"50vw", height: "200px", width:"200px"}}/>}>
                    <AccessAccountPopUp
                        isOpen={accessAccountState.isActive}
                        activeSide={accessAccountState.activeForm}
                        handleChangeSide={handleAccessAccountChangeActive}
                        handleClose={handleCloseAccessAccountPopUp}
                    />
                </Suspense>
            )}
        </Root>
    );
}

export default ToolboxComponent;
