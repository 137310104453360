import { AppBar } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import DrawerComponent from "./DrawerComponent";
import ToolboxComponent from "./ToolboxComponent";

const PREFIX = 'Topbar';

const classes = {
    topbar: `${PREFIX}-topbar`,
    premium: `${PREFIX}-premium`,
    drawer: `${PREFIX}-drawer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.topbar}`]: {
		color: "white",
		fontWeight: "bold",
		backgroundColor: theme.palette.primary.main,
	},

    [`& .${classes.premium}`]: {
		color: theme.palette.primary.main,
	},

    [`& .${classes.drawer}`]: {
		paddingTop: 0,
		paddingRight: theme.spacing(4),
		paddingBottom: theme.spacing(3),
		paddingLeft: theme.spacing(2),
		width: 300,
	}
}));


/**
 * Component to display navigation bar
 * @param user current user
 */
function Topbar({ user }) {

	const [state, setState] = useState({
		profileMenuIsShown: false,
	});

	const toggleProfileMenu = (open) => (event) => {
		event.preventDefault(); // no change in url
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setState({ ...state, profileMenuIsShown: open });
	};

	let toolbox = null;
	if (user)
		toolbox = (
			<ToolboxComponent
				title={"WeGuideYou"}
				isConnected={user}
				user={user}
				openDrawer={toggleProfileMenu(true)}
			/>
		);
	else
		toolbox = (
			<ToolboxComponent
				title={"WeGuideYou"}
				openDrawer={toggleProfileMenu(true)}
			/>
		);

	return (
        <Root>
            <AppBar position="fixed" color="primary" className={classes.topbar} >
				{toolbox}
			</AppBar>
            {user && (
				<DrawerComponent
					user={user}
					isShown={state.profileMenuIsShown}
					onClose={toggleProfileMenu(false)}
					classes={classes}
				/>
			)}
        </Root>
    );
}

export default Topbar;
