import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonFR from "./locales/fr/common.json";
import settingsFR from "./locales/fr/settings.json";
import verifyFR from "./scenes/Verify/locales/fr/verify.json";
import wgyVerifyInviteFR from "./scenes/Verify/locales/fr/invite.json";

const resources = {
    fr: {
        common: commonFR,
        settings: settingsFR,
        verify: verifyFR,
        invite: wgyVerifyInviteFR
    }
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: "fr", // default language
        ns: ["settings","common"],
        defaultNS: "common",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    }).then(() => console.log("i18n is initialize"));

export default i18n;