import {ImageInputContent} from "./Inputs/Image";
import MailInputContent from "./Inputs/Mail";
import PasswordInputContent from "./Inputs/Password";
import TextInputContent from "./Inputs/Text";
import DatePickerInputContent from "./Inputs/DatePicker";
import ChoiceInputContent from "./Inputs/Choice";
import ListChoiceInputContent from "./Inputs/ListChoice";
import CheckboxInputContent from "./Inputs/Checkbox";
import RankStarInputContent from "./Inputs/RankStar";
import ImagesInputContent from "./Inputs/Images/Images.controller";
import MetaDescriptionTextInputContent from "./Inputs/Text/MetaDescriptionTextInputContent";
import LanguagesChoiceInputContent from "./Inputs/Languages";
import {CountryNameOrIndicatorChoiceInputContent} from "./Inputs/CountryNameOrIndicatorChoice";


export function MailInput({
	name,
	value,
	labelText,
	labelShrink = false,
	placeholder,
	fullWidth = false,
	margin = "none",
	handleChange,
	error,
	helperText,
	...props
}) {
	return MailInputContent({
		name,
		value,
		labelText,
		labelShrink,
		placeholder,
		fullWidth,
		margin,
		handleChange,
		error,
		helperText,
		...props
	});
}

export function PasswordInput({
	name,
	labelText,
	labelShrink = false,
	fullWidth = false,
	margin = "none",
	pswValue,
	handleChange,
	error = null,
	helperText = null,
	...props
}) {
	return PasswordInputContent({
		name,
		labelText,
		labelShrink,
		fullWidth,
		margin,
		pswValue,
		handleChange,
		error,
		helperText,
		...props
	});
}

export function TextInput({
	name,
	type = "text",
	labelText,
	labelShrink = false,
	placeholder,
	fullWidth = false,
	margin = "none",
	InputProps = null,
	value,
	handleChange,
	error = null,
	warn = null,
	helperText = null,
	disabled = false,
	required = false,
	rows = null,
	...props
}) {
	return TextInputContent({
		name,
		type,
		labelText,
		labelShrink,
		placeholder,
		fullWidth,
		margin,
		InputProps,
		value,
		handleChange,
		error,
		warn,
		helperText,
		disabled,
		required,
		rows,
		...props
	});
}

export function DatePickerInput({
	id,
	name,
	labelText,
	labelShrink = false,
	fullWidth = false,
	margin = "none",
	minDate = new Date(1940, 1, 1).getTime(),
	maxDate = new Date(2050, 1, 1).getTime(),
	value,
	handleChange,
	error,
	helperText,
	disabled = false,
	...props
}) {
	return DatePickerInputContent({
		id,
		name,
		labelText,
		labelShrink,
		fullWidth,
		margin,
		minDate,
		maxDate,
		value,
		handleChange,
		error,
		helperText,
		disabled,
		...props
	});
}

export function ChoiceInput({
	name,
	value,
	labelText,
	labelShrink = false,
	fullWidth = false,
	margin = "none",
	values,
	handleChange,
	error = null,
	helperText = null,
	disabled = false,
	...props
}) {
	return ChoiceInputContent({
		name,
		value,
		labelText,
		labelShrink,
		fullWidth,
		margin,
		values,
		handleChange,
		error,
		helperText,
		disabled,
		...props
	});
}

export function ListChoiceInput({
	id = null,
	name,
	labelText,
	labelShrink = false,
	fullWidth = false,
	margin = "none",
	value,
	values,
	handleChange,
	error = false,
	helperText = null,
	disabled = false,
}) {
	return ListChoiceInputContent({
		id,
		name,
		labelText,
		labelShrink,
		fullWidth,
		margin,
		value,
		values,
		handleChange,
		error,
		helperText,
		disabled,
	});
}

export function LanguagueChoiceInput({...props}) {
	return LanguagesChoiceInputContent({...props})
}

export const ImageInput = ({
	id,
	labelText,
	value,
	updateImage,
	removeImage,
	editWithoutUpload= false,
	displayError = false,
	isBlogImage = false,
	...props
}) => {
	return ImageInputContent({
		id,
		labelText,
		value,
		updateImage,
		removeImage,
		editWithoutUpload,
		displayError,
		isBlogImage,
		...props
	});
}

/**
 *
 * @param id | the id in the array of the image
 * @param images | the array of all images
 * @param setImage
 * @param numberImages | the number of max images
 * @param updateImages | update images array (sort or add)
 * @param removeImage | remove one image with the close button
 * @param update | compoenent call on update page, not create page
 * @param error | is on error mod
 * @param manyImages
 * @param {Boolean} isBlogImage check if image is for blogImage
 * @returns HTML
 */
export function ImagesInput({
	id,
	images,
	setImage,
	numberImages,
    updateImages,
	removeImage,
	update,
	error,
	manyImages = null,
	isBlogImage= false
}) {
	return ImagesInputContent({
		id,
		images,
		setImage,
		numberImages,
		updateImages,
		removeImage,
		update,
		error,
		manyImages,
		isBlogImage
	});
}

export function CheckboxInput({
    name,
	labelText,
	value,
	handleChange,
	helperText,
	...props
}) {
	return CheckboxInputContent({
		name,
		labelText,
		value,
		handleChange,
		helperText,
		...props
	});
}

export function RankStarInput({
    onChange = () => null,
	value = 0,
	max = 5
}) {
	return RankStarInputContent({
		onChange,
		value,
		max
	});
}

export function MetaDescriptionTextInput({
	labelText,
	value,
	name,
	handleChange,
	error,
	helperText,
}) {
	return MetaDescriptionTextInputContent({
		labelText,
		value,
		name,
		handleChange,
		error,
		helperText,
	});
}

export const CountryNameOrIndicatorChoiceInput = ({
	name,
	labelText,
	labelShrink = false,
	fullWidth,
	value,
	handleChange,
	isIndicatorInput = false,
	InputProps = null
}) => {
  	return CountryNameOrIndicatorChoiceInputContent({
	  	name,
	  	labelText,
	  	labelShrink,
	  	fullWidth,
	  	value,
	  	handleChange,
	  	isIndicatorInput,
	  	InputProps
  	})
}
