import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

// main style
export const ManyImagesDialog = styled(Dialog)(({ theme }) => ({
    'div.images-container': {
      display: "flex",
      margin: "1rem 0 1rem 0"
    },
    'div.image-container': {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    '.sub-title': {
        textAlign: "center",
        margin: "1rem"
    }
}));