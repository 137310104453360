import { useSnackbar } from "notistack";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConnection, setUserInformation, setUserToken } from "../../actions";
import ConnectionForm from "../../components/AccessAccountPopUp/ConnectionForm";
import { ConnectionSelector } from "../../selectors";
import handleConnectionAPI from "../../services/handleConnectionAPI";
import { useNavigate } from "react-router-dom";
import {globalConfigDurationNotistack} from "../../constants/globalConstant";

/**
 * Container to display connection form
 * @param handleClose handle popup close
 * @param nextPath path to redirect
 */
function ConnectionFormContainer({ handleClose, nextPath }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const callback = useCallback(
		(connection) => {
			dispatch(setConnection(connection));
		},
		[dispatch]
	);
	// to generate a popup
	const { enqueueSnackbar } = useSnackbar();

	const connection = useSelector(ConnectionSelector);

	const handleConnection = (email, password, setLoginClicked) => {
		setLoginClicked(true);
		handleConnectionAPI(email, password)
			.then(({ user, token, message }) => {
				dispatch(setUserInformation(user));
				dispatch(setUserToken(token));

				if (nextPath != null) {
					navigate(nextPath);
                }

				enqueueSnackbar(message, {
					variant: "success",
					autoHideDuration: globalConfigDurationNotistack
				});
				handleClose();
			})
			.catch((_) => {
				// variant could be success, error, warning, info, or default
				enqueueSnackbar("L'adresse mail/mot de passe est incorrect.", {
					variant: "error",
					autoHideDuration: globalConfigDurationNotistack
				});
				setLoginClicked(false);
			});
	};

	return (
		<ConnectionForm
			connectionValue={connection}
			onChange={callback}
			handleConnection={handleConnection}
		/>
	);
}

export default ConnectionFormContainer;
