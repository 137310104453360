import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import validator from "validator";
import { MailInput, PasswordInput } from "../Inputs";

/**
 * Component to display general form
 * @param accountGeneralValue form state, handle all inputs
 * @param onChange callback to call when value change
 * @param setValid change form validity
 */
function AccountGeneralForm({ accountGeneralValue, onChange, setValid }) {
	const [state, setState] = useState(accountGeneralValue);

	const mailIsValid = () => {
		return validator.isEmail(state.mail);
	};
	const pswHasEnoughCharacters = () => {
		return state.psw.length > 7;
	};
	const pswHasEnoughNumbers = () => {
		return /\d/.test(state.psw);
	};
	const pswHasEnoughLowercase = () => {
		return /[a-z]/.test(state.psw);
	};
	const pswHasEnoughUppercase = () => {
		return /[A-Z]/.test(state.psw);
	};
	const pswHasSpecialChar = () => {
		return /[-+_!@#$%^&*.,?]/.test(state.psw);
	};
	const pswIsValid = () => {
		return (
			pswHasEnoughCharacters() &&
			pswHasEnoughNumbers() &&
			pswHasEnoughLowercase() &&
			pswHasEnoughUppercase() &&
			pswHasSpecialChar()
		);
	};
	const pswHelperText = () => {
		let res = "";
		if (!pswHasEnoughCharacters()) {
			res += "Le mot de passe doit contenir au moins 8 caractères.\n";
		}
		if (!pswHasEnoughNumbers()) {
			res += "Le mot de passe doit contenir au moins un chiffre.\n";
		}
		if (!pswHasEnoughLowercase()) {
			res +=
				"Le mot de passe doit contenir au moins une lettre minuscule.\n";
		}
		if (!pswHasEnoughUppercase()) {
			res +=
				"Le mot de passe doit contenir au moins une lettre majuscule.\n";
		}
		if (!pswHasSpecialChar()) {
			res +=
				"Le mot de passe doit contenir au moins un caractère spécial.\n";
		}
		return res;
	};
	const confPswIsValid = () => {
		return state.verif_psw === state.psw;
	};

	useEffect(() => {
		setValid(mailIsValid() && pswIsValid() && confPswIsValid());
		onChange(state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, setValid]);

	const handleInputChange = async (e) => {
		const target = e.target;
		const value = target.value;
		const name = target.name;

		setState({
			...state,
			[name]: value,
		});
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<MailInput
					id={"mail"}
					name="mail"
					labelText="Adresse mail"
					labelShrink
					placeholder="johndoe@gmail.com"
					fullWidth
					value={state.mail}
					handleChange={handleInputChange}
					error={state.mail !== "" && !mailIsValid()}
					helperText={
						state.mail !== "" &&
						!mailIsValid() &&
						"L'adresse mail doit être valide !"
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<PasswordInput
					id={"password"}
					name="psw"
					labelText="Mot de passe"
					labelShrink
					fullWidth
					pswValue={state.psw}
					handleChange={handleInputChange}
					error={state.psw !== "" && !pswIsValid()}
					helperText={state.psw !== "" && pswHelperText()}
				/>
			</Grid>
			<Grid item xs={12}>
				<PasswordInput
					id={"verify-password"}
					name="verif_psw"
					labelText="Valider le mot de passe"
					labelShrink
					fullWidth
					pswValue={state.verif_psw}
					handleChange={handleInputChange}
					error={state.verif_psw !== "" && !confPswIsValid()}
					helperText={
						state.verif_psw !== "" &&
						!confPswIsValid() &&
						"Le mot de passe doit être identique dans les deux champs !"
					}
				/>
			</Grid>
		</Grid>
	);
}

export default AccountGeneralForm;
