import AccountSponsorShipForm from "../../components/AccessAccountPopUp/AccountSponsorShipForm";
import {useDispatch} from "react-redux";
import {useCallback} from "react";
import {setSponsored} from "../../actions";

/**
 * @param setValid change form validity
 * @param inscription redux store data of register
 * @returns {JSX.Element}
 * @constructor
 */
export default function AccountSponsorShipFormContainer({ setValid, inscription }) {
    const dispatch = useDispatch();
    const callback = useCallback(
        (sponsored) => {

            dispatch(setSponsored(sponsored));
        },
        [dispatch]
    );
    const sponsored = inscription.sponsored;

    return (
        <AccountSponsorShipForm
            sponsoredValue={sponsored}
            onChange={callback}
            setValid={setValid}
        />
    );
}
