/**
 * create a forgotten password request and send a email
 * @param {*} email email to send request
 * @returns
 */
const createForgottenPasswordRequestAPI = (email) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/forgotten-password/create`,
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email }),
			}
		);

		fetch(request)
			.then((res) => {
				res.json().then((resp) => {
					if (resp.success) {
						resolve();
					} else {
						reject();
					}
				});
			})
			// TODO reject a string
			.catch((err) => console.error(err));
	});
};

export default createForgottenPasswordRequestAPI;
