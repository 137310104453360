import React from "react";
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import {generateUrlImage} from "../../../utils/general/generateUrlImage";
import getArticleAlt from "../../../utils/general/getArticleAlt";
import getCreatedDate from "../../../utils/announcement/announcementGetCreatedDate";
import {useNavigate} from "react-router-dom";
import {globalConfigDurationNotistack} from "../../../constants/globalConstant";
import {useSnackbar} from "notistack";
import ShareIcon from '@mui/icons-material/Share';

const ArticleCard = ({article}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const onShare = (event) => {
        event.stopPropagation();
        navigator.clipboard.writeText(
            `${window.location.origin}/blog/${article.url}`
        );
        enqueueSnackbar('URL Copiée',
            {
                variant: "success",
                autoHideDuration: globalConfigDurationNotistack
            });
    };

    return (
        <Grid
            item
            xs={12} sm={6} lg={4}
            mb={2}
            container
            justifyContent={"center"}
        >
            <Card
                sx={{
                    width: "80%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    '&:hover':{
                        cursor: "pointer"
                    }
                }}
                onClick={() => navigate(`/blog/${article.url}`)}
            >
                <CardMedia
                    sx={{ minHeight: 140 }}
                    image={generateUrlImage(article.imageMain, false, false,true)}
                    alt={getArticleAlt(article)}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {article.title}
                    </Typography>
                    {/*<Stack direction="row" flexWrap={"wrap"} >
                        {article.tags.map((tag, index) => (
                            <Chip label={tag} key={index} sx={{mr:1, mb:1}} color={"primary"}/>
                        ))}
                    </Stack>*/}
                    <Typography variant="body2" color="text.secondary">
                        {article.shortDescription}...
                    </Typography>

                </CardContent>
                <CardActions sx={{justifyContent: "space-between", alignItems: "center", mx: 1}}>
                    <Typography variant="body2" color="text.secondary" sx={{fontStyle: "italic" }}>
                        Publié {getCreatedDate(article)}
                    </Typography>
                    <IconButton
                        size="small"
                        color={"primary"}
                       // variant={"outlined"}
                        onClick={(event) => onShare(event)}
                    >
                        <ShareIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default ArticleCard
