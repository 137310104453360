import { Badge } from "@mui/material";
import MessageSharpIcon from "@mui/icons-material/MessageSharp";
import React from "react";
import { useSelector } from "react-redux";
import { ChatRoomsSelector } from "../../selectors";

/**
 * Component to display messages notifications
 * @param user current user
 */
function MessageIcon({ user }) {
	const rooms = useSelector(ChatRoomsSelector);
	return (
		<>
			<Badge
				badgeContent={rooms.reduce((res, room) => {
					const numberNotification =
						room && room.lastRead && room.lastRead[user._id]
							? room.lastRead[user._id].number
							: 0;
					return res + numberNotification;
				}, 0)}
				color="error"
			>
				<MessageSharpIcon />
			</Badge>
		</>
	);
}

export default MessageIcon;
