import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setAccountType } from "../../actions";
import AccountTypeForm from "../../components/AccessAccountPopUp/AccountTypeForm";

/**
 * Container to display account type form
 * @param setValid change form validity
 * @param inscription redux store data of register
 */
function AccountTypeFormContainer({ setValid, inscription}) {
	const dispatch = useDispatch();
	const callback = useCallback(
		(accountType) => {
			dispatch(setAccountType(accountType));
		},
		[dispatch]
	);

	const accountType = inscription.accountType;

	return (
		<AccountTypeForm
			accountTypeValue={accountType}
			onChange={callback}
			setValid={setValid}
		/>
	);
}

export default AccountTypeFormContainer;
