import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import theme from "../../theme";
import { Button, IconButton, Box, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HomeBetaAlert() {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("homeBetaAlert", JSON.stringify(new Date()));
  };
  const style = {
    important: {
      fontSize: 24,
      color: theme.palette.primary.main,
    },
    parentheses: {
      fontWeight: "700",
      fontSize: 12,
    },
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "24px",
            fontWeight: 400,
            letterSpacing: "1.5px",
          }}
          color={theme.palette.primary.main}
        >
          We Guide You ?
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size={"large"}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon sx={{ color: theme.palette.primary.main }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              marginTop: "16px",
              marginX: "10%",
            }}
          >
            <Box component={"span"} sx={style.important}>
              Recrutez
            </Box>{" "}
            des éducateurs sportifs{" "}
            <Box component={"span"} sx={style.parentheses}>
              ( moniteurs de ski, guides de haute montagne, coachs sportifs,
              professeurs de tennis, entraîneurs… )
            </Box>{" "}
            pour votre structure.
          </DialogContentText>
          <DialogContentText
            sx={{
              marginTop: "16px",
              marginX: "10%",
            }}
          >
            <Box component={"span"} sx={style.important}>
              Trouvez
            </Box>{" "}
            un{" "}
            <Box component={"span"} sx={style.important}>
              job
            </Box>
            , une{" "}
            <Box component={"span"} sx={style.important}>
              offre d'emploi
            </Box>
            .
          </DialogContentText>
          <DialogContentText
            sx={{
              marginTop: "16px",
              marginX: "10%",
            }}
          >
            <Box component={"span"} sx={style.important}>
              Indiquez
            </Box>{" "}
            vos disponibilités{" "}
            <Box component={"span"} sx={style.parentheses}>
              ( aux recruteurs et, aux particuliers en recherche d'activités
              sportives )
            </Box>{" "}
            en tant qu'éducateurs sportifs.
          </DialogContentText>
          <DialogContentText
            sx={{
              marginTop: "16px",
              marginX: "10%",
            }}
          >
            <Box component={"span"} sx={style.important}>
              Vérifiez
            </Box>{" "}
            les documents légaux des éducateurs sportifs{" "}
            <Box component={"span"} sx={style.parentheses}>
              ( Diplômes / RC Pro / Carte professionnelle / URSSAF… ).
            </Box>
          </DialogContentText>
          <Divider sx={{ marginX: "20%", marginY: "2rem" }} />
          <DialogContentText
            style={{ textAlign: "center", textDecoration: "underline", margin: "0 10%" }}
          >
            Nous avons pour ambition de référencer tous les éducateurs sportifs
            ( BEES, DE, DEJEPS, DESJEPS, CPJEPS, BPJEPS, BAPAAT... ) pour faciliter 
            la mise en relation avec les structures sportives, les clients et entre eux.
          </DialogContentText>
          <DialogContentText style={{ margin: "16px 10%", textAlign: "center" }}>
            Ce site est totalement{" "}
            <span style={{ fontWeight: "bold" }}>gratuit</span>
          </DialogContentText>
          <DialogContentText style={{ margin: "16px 10%", textAlign: "center" }}>
            Nous comptons sur vous, pour en parler et faire grandir notre communauté. Ce site est fait pour vous et grâce à vous.
          </DialogContentText>
          <DialogContentText style={{ margin: "16px 10%", textAlign: "center" }}>
            Nous restons disponibles pour vos questions et demandes d'informations
          </DialogContentText>

          <DialogContentText
            style={{
              marginTop: "24px",
              marginBottom: "32px",
              textAlign: "center",
            }}
          >
            L'équipe de
            <span style={{ color: theme.palette.primary.main }}>
              {" "}
              We Guide You
            </span>{" "}
            <br />
            <a style={{color: "rgba(0, 0, 0, 0.6)"}} href="telto:0666009659">06 66 00 96 59</a>
            <br />
            <a style={{color: "rgba(0, 0, 0, 0.6)"}} href="mailto:contactwgy@gmail.com">contactwgy@gmail.com</a>
          </DialogContentText>
          <hr />
          <Box sx={{ textAlign: "center" }}>
            <Button
              id={"close-beta-alert"}
              variant="text"
              onClick={handleClose}
              sx={{ color: theme.palette.primary.main }}
            >
              Fermer
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
