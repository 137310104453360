import React, {useState} from "react";
import {IconButton} from "@mui/material";
import {StarBorderRounded, StarRounded} from "@mui/icons-material";

export default function RankStarInputContent ({
    onChange,
    value,
    max
}) {
    const [currentValue, setCurrentValue] = useState(value);
    const star = (n) => (
        <IconButton
            key={n}
            color="primary"
            onClick={() => {
                setCurrentValue(n);
                onChange(n);
            }}
            size="large">
            {n <= currentValue ? <StarRounded /> : <StarBorderRounded />}
        </IconButton>
    );
    let array = [];
    for (let i = 1; i <= max; ++i) array.push(star(i));
    return array;
}