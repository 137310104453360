// In this file you can find views use by the controller
// * 4 sub éléments printed in square
// * the container of all sub éléments
// * the form for show if premium option is selected
// this view call styled components

import { FormGroupImage, ImageInputContainer, ImageItem, ImageItemContainer, ImageLoader, ImageTemplate } from "./Images.style";
import { Alert, CircularProgress, Fab, FormControlLabel, Grid, IconButton, Switch } from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../theme";
import { css, keyframes } from "@emotion/css";
import { ManyImagesDialog } from "../../PopUpAlert/ManyImagesDialog/controller.ManyImagesPopUp";
import { AnnounceImagesDetail } from "../../../constants/PaidOptions";
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import {generateUrlImage} from "../../../utils/general/generateUrlImage";

// DivLoader is one of the 4 sub éléments
// when the image becomes a BLOB this component will show the user that the application is loading
export function DivLoader (i) {
    return <Grid key={i} item xs={4}>
        <ImageLoader className={"placeholder"}>
            <CircularProgress />
        </ImageLoader>
    </Grid>
}

// DivTemplate is one of the 4 sub éléments
export function DivTemplate (i) {
    return <Grid key={i} item xs={4}>
        <ImageTemplate className={"placeholder "} />
    </Grid>
}

// DivImage is one of the 4 sub éléments
export function DivImage (
    i,
    handleDragSelect,
    handleDragEnter,
    drop,
    images,
    update,
    handleRemoveimage
) {
    return <Grid key={i} item xs={4}>
        <div
            onDragStart={(e) => handleDragSelect(e, i)}
            onDragEnter={(e) => handleDragEnter(e, i)}
            onDragEnd={drop}
            id={"img-" + i}
            draggable={true}
            className={css` display: block; position: relative; `}
        >
            <Fab onClick={() => { handleRemoveimage(images[i]) }}
                size="small"
                color="primary"
                aria-label="add"
                sx={{
                    zIndex: "8",
                    position: "absolute",
                    top: "-16px",
                    right: "-16px",
                }}>
                <CloseIcon />
            </Fab>
            <ImageItemContainer>
                <ImageItem
                    alt=""
                    draggable={false}
                    src={update ?
                        typeof images[i] === "string" ?
                            generateUrlImage(images[i], false, ) :
                            URL.createObjectURL(images[i]) :
                        URL.createObjectURL(images[i])
                    }
                />
            </ImageItemContainer>
        </div>
    </Grid>
}

// DivInput is one of the 4 sub éléments
export function DivInput (
    i,
    id,
    error,
    images,
    checked,
    setChecked,
    setLoading,
    handleUpdateImages
) {
    const animation = keyframes`
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    `
    return <Grid key={i} item xs={4}>
        <ImageInputContainer sx={{ borderColor: error ? theme.palette.error.main : theme.palette.primary.main }}>
            <label htmlFor={id}>
                <AddAPhotoIcon
                    color={error ? "error" : "primary"}
                    className={css` transition: 0.5s;
                        animation: ${animation} cubic-bezier(0, 0, 0, 1) 1s;
                        &:hover {
                            cursor: pointer;
                            color: ${error ? theme.palette.error.dark : theme.palette.primary.dark};
                        }
                    `}
                />
            </label>
            <input
                className={css`display: none; height: 0;`}
                accept="image/*,.pdf,.jpg"
                onChange={(e) => handleUpdateImages(e.target.files).then(() => {
                    if (!checked && images.length > 0) setChecked(true)
                    setLoading(false);
                })}
                id={id}
                type="file"
                multiple
            />
        </ImageInputContainer>
    </Grid>
}

// ContainerImages is the main container
export function ContainerImages (content, dialogOpen, setDialogOpen, checked, handleChange, manyImages) {
    return <>
        <div>
            <Grid container spacing={3}>
                {content}
            </Grid>
            {!manyImages ?
                <FormGroupImage>
                    <FormControlLabel control={ <Switch checked={checked} onChange={handleChange} /> }
                        label={"Souscrire au pack 5 photos supplémentaires (" + AnnounceImagesDetail.price.toFixed(2) + "€)"}
                    />
                    <IconButton onClick={() => setDialogOpen(!dialogOpen)} enterdelay={1000} size="large">
                        <InfoIcon color="secondary" />
                    </IconButton>
                </FormGroupImage> :
                <Alert
                    sx={{marginTop: "1rem"}}
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success">
                    Souscription au pack 5 photos supplémentaires valide jusqu'au {new Date(manyImages.date.end).toLocaleDateString()}
                </Alert>
            }
        </div>
        {ManyImagesDialog(dialogOpen, setDialogOpen)}
    </>
}
