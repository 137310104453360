import { Typography } from "@mui/material";

export default function TitlePage({ title, subTitle = null }) {
    const id = title.toLowerCase().replaceAll(' ', '-');
    return <>
        <Typography id={`${id}-title`} sx={{marginBottom: subTitle ? "0px !important" : ""}} component="h1" variant="title">
            {title}
        </Typography>
        {subTitle && <Typography id={`${id}-subtitle`} component="h2" variant="subtitle">{subTitle}</Typography>}
    </>
}