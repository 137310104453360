/**
 * Fetch user info from server
 * @param {*} id id of chosen user
 * @returns
 */
const getUserInfoAPI = (id) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/info/get/${id}`,
			{
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		);

		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({ data: json.user });
					} else {
						reject(json.error);
						console.error(json.error);
					}
				});
			})
			.catch((err) => {
				reject(err);
				console.error(err);
			});
	});
};

export default getUserInfoAPI;
