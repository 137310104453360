import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Link,
	Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import React, { useState } from "react";
import validator from "validator";
import { MailInput } from "../Inputs";
import theme from "../../theme";

const PREFIX = 'ForgottenPassword';

const classes = {
    root: `${PREFIX}-root`,
    link: `${PREFIX}-link`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},

    [`& .${classes.link}`]: {
		textAlign: "right",
		marginTop: theme.spacing(1),
	}
}));


/**
 * Component to display forgotten password popup form
 * @param open if true, show the popup
 * @param setOpen change the visibility state
 * @param onSubmit callback to call when user send his request
 */
function ForgottenPassword({ open, setOpen, onSubmit }) {

	const [email, setEmail] = useState("");

	const mailIsValid = () => {
		return validator.isEmail(email);
	};

	return (
        <Root>
            <Typography className={clsx(classes.link, classes.root)}>
				<Link
					onClick={(event) => {
						event.preventDefault();
						setOpen(true);
					}}
					sx={{
						'&:hover':{cursor: "pointer"}
					}}
				>
					Mot de passe oublié ?
				</Link>
			</Typography>
            <Dialog
				open={open}
				onClose={() => {
					setOpen(false);
				}}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Mot de passe oublié
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Saisissez votre adresse mail. Un mail vous sera envoyé
						afin de réinitialiser votre mot de passe. <b>REGARDEZ DANS VOS SPAMS</b>
					</DialogContentText>
					<MailInput
						name="mail"
						value={email}
						labelText="Adresse mail"
						labelShrink
						placeholder="johndoe@gmail.com"
						fullWidth
						handleChange={(event) => setEmail(event.target.value)}
						error={email !== "" && !mailIsValid()}
						helperText={
							email !== "" && !mailIsValid()
								? "L'adresse mail doit être valide !"
								: " "
						}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpen(false);
						}}
						sx={{color: theme.palette.primary.main}}
					>
						Annuler
					</Button>
					<Button
						onClick={() => {
							onSubmit(email);
						}}
						sx={{color: theme.palette.primary.main}}
					>
						Envoyer
					</Button>
				</DialogActions>
			</Dialog>
        </Root>
    );
}

export default ForgottenPassword;
