import { status } from "../../constants/globalConstant";
import checkNumSiretValidity from "../../services/OfficialDoc/checkNumSiretValidity";

const numSiretValidator = (numSiret, APIstatus = status.working) => {
    return new Promise((resolve) => {
        if (/^\d{14}$/.test(numSiret)){
            //Request api to check validity of num SIRET
            if (APIstatus === status.working) {
                checkNumSiretValidity(numSiret)
                    .then((resp) => {
                        resolve(resp.success);
                    })
            } else {
                resolve(true);
            }
        } else {
            // Num siret doesn't have 14 character so is not valid
            resolve(false)
        }
     });

}

export default numSiretValidator
