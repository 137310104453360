/**
 * Check if insee api is down
 * @returns
 */
const checkIfInseeApiIsdown = () => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/insee-api/isDown`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
		);

		fetch(request)
			.then((res) => {
                if (res.status === 200) {
                    resolve('working');
                } else {
                    reject('down');
                }
			})
			.catch((err) => {
                console.error(err)
                reject('down');
            });
	});
};

export default checkIfInseeApiIsdown;
