import React from "react";
import frLocale from "date-fns/locale/fr";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";


export default function DatePickerInputContent({
    id,
    name,
    labelText,
    labelShrink,
    fullWidth,
    margin,
    minDate,
    maxDate,
    value,
    handleChange,
    error,
    helperText,
    disabled,
    ...props
}) {
    const inputLabelPropsValue = labelShrink ? { shrink: true } : null;
    if ((new Date(minDate)).getTime() > 0){
        minDate = new Date(minDate).getTime();
    }
    if ((new Date(maxDate)).getTime() > 0){
        maxDate = new Date(maxDate).getTime();
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
            <DatePicker
                {...props}
                name={name}
                componentsProps={{
                    textField: {
                        id : id,
                        fullWidth,
                        label: labelText,
                        helperText: helperText,
                        error: error
                    }
                }}
                format="dd/MM/yyyy"
                margin={margin}
                minDate={minDate}
                maxDate={maxDate}
                value={value || null}
                onChange={handleChange}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                InputLabelProps={inputLabelPropsValue}
                error={true}
                disabled={disabled}
                localeText={{
                    cancelButtonLabel: <span style={{color: "#2ab1ea"}}>Annuler</span>,
                    okButtonLabel: <span style={{color: "#2ab1ea"}}>Valider</span>
                }} />
        </LocalizationProvider>
    );
}
