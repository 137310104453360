import React, { useEffect} from "react";
import config from "./config-pub.json";
import {Box} from "@mui/material";

export default function PubDisplayVerticalextends(props) {

    const {currentPath} = props

    useEffect(() => {
        window.adsbygoogle = window.adsbygoogle || []
        window.adsbygoogle.push({})
    }, [currentPath])


    /* window.addEventListener("scroll", function() {
        const distance = window.scrollY;
        if(distance > 200) {
            //  document.querySelector("ins.adsbygoogle-vertical").style.transform = `translateY(${distance - 200}px)`
            document.querySelector("div.container-adsbygoogle-vertical-test").style.transform = `translateY(${distance - 200}px)`
        }
        if(distance < 200) {
            //  document.querySelector("ins.adsbygoogle-vertical").style.transform = `translateY(0px)`
            document.querySelector("div.container-adsbygoogle-vertical-test").style.transform = `translateY(0px)`
        }
    })*/


    return (
        <Box className="container-adsbygoogle-vertical-test">
            <ins className="adsbygoogle"
                 style={{display: "block"}}
                 data-ad-format="auto"
                 data-full-width-responsive="true"
                 data-ad-client={JSON.parse(JSON.stringify(config.client))}
                 data-ad-slot={JSON.parse(JSON.stringify(config.displaySideSlot))}/>
        </Box>
    );
}
