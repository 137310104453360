/**
 * set an email to verify email to an user
 * @param {*} userToken user token to send mail
 * @returns
 */
const sendVerificationEmailAPI = (userToken) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/verify/mail`,
			{
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: userToken,
				},
			}
		);

		fetch(request)
			.then((res) => {
				res.json().then((resp) => {
					if (resp.success) {
						resolve(resp.message);
					} else {
						reject(resp.message);
					}
				});
			})
			// TODO reject a string
			.catch((err) => console.error(err));
	});
};

export default sendVerificationEmailAPI;
