import React, {Fragment} from "react";
import {Box, Grid} from "@mui/material";
import PubDisplayInFeed from "../../ListAnnouncements/components/PubDisplayInFeed";
import ArticleCard from "./ArticleCard";

/**
 * Component list all of article
 * @param {Array<Article>} articles
 * @returns {JSX.Element}
 * @constructor
 */
const ListArticleComponent = ({articles}) => {

    const step = 6;
    return (
        <Grid container spacing={2}>
            {articles.map((article, index) => (
                index % step === 0 && index !== 0 ? (
                        <Fragment key={"announce+pub-" + article._id}>
                            <Grid item xs={12} sx={{height: "auto"}} >
                                <Box sx={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "16px",
                                    maxHeight: "200px"
                                }}>
                                    <PubDisplayInFeed/>
                                </Box>
                            </Grid>
                            <ArticleCard
                                article={article}
                                key={index}
                            />
                        </Fragment>
                    ) : (
                        <ArticleCard
                            article={article}
                            key={index}
                        />
                    )
            ))}

            {articles.length < step && (
                <Grid item xs={12} sx={{height: "auto"}}>
                    <Box sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "16px",
                        maxHeight: "200px"
                    }}>
                        <PubDisplayInFeed/>
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}

export default ListArticleComponent