export const globalConfigDurationNotistack = 5000;

export const ANNOUNCEMENT_VALIDITY_DURATION = 30;

// ---- SPONSORSHIP CONSTANT ----------
export const SPONSOR_USER_MONEY_INCREASE = 10;
export const NEW_USER_SPONSORED_MONEY_INCREASE = 5;
export const DURATION_NB_DAYS_SPONSORSHIP_AMOUNT_AVAILABLE = 365;

export const CONST_ANNOUNCEMENT_KINDS = {
    Application: "ApplicationAnnouncement",
    Course: "CourseAnnouncement",
    Offering: "OfferingAnnouncement",
}

export const DEFAULT_USER_IMAGE_PROFIL = "defaultProfileImage.svg"

export const ENUM_ISVALID_OFFICIAL_DOC_VALUE = {
    pending: "pending",
    approved: "approved",
    denied: "denied"
}

export const status = {
    working: 0,
    pending: 1,
    down: 2
}

export const multerOfficialDocs = "application/pdf, image/png, image/jpeg, image/webp";
