import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setAccountSpecific } from "../../actions";
import AccountSpecificFormManager from "../../components/AccessAccountPopUp/AccountSpecificFormManager";

/**
 * Container to display account specific form
 * @param setValid change form validity
 * @param inscription redux store data of register
 */
function AccountSpecificFormContainer({ setValid, inscription }) {
	const dispatch = useDispatch();
	const callback = useCallback(
		(accountSpecific) => {
			dispatch(setAccountSpecific(accountSpecific));
		},
		[dispatch]
	);

	const accountType = inscription.accountType;
	const accountSpecific = inscription.accountSpecific[accountType];

	return (
		<AccountSpecificFormManager
			accountType={accountType}
			accountSpecificValue={accountSpecific}
			onChange={callback}
			setValid={setValid}
		/>
	);
}

export default AccountSpecificFormContainer;
