import {
    Box, CircularProgress,
    FormControl,
    FormHelperText, Grid,
    InputAdornment,
    OutlinedInput,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Loader from "../Loader/Loader";
import Sponsorship from "../../services/sponsorship";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {generateUrlImage} from "../../utils/general/generateUrlImage";

export default function AccountSponsorShipForm({ sponsoredValue, setValid, onChange }) {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // When init state check if sponsored.value have define value
    // else check if url have param type === register & code and set by default register sponsorship code by code find in url
    // else init by empty string
    const [sponsored, setSponsored] = useState(
        typeof sponsoredValue.code !== "undefined" && sponsoredValue.code !== null ?
            sponsoredValue :
            urlParams.get('type') === "register" && urlParams.get('code') ?
                {code: urlParams.get('code')} :
                {code: ""}
    );
    const [sponsoredState, setSponsoredState] = useState("standing");
    const [loading, setLoading] = useState(true);

    const sponsoredInputProcess = (value) => {
        if ( typeof value.length !== "undefined" && value.length !== 6 ) {
            setValid(false);
            setLoading(false);
            setSponsoredState("error");
        } else {
            if (typeof value === "undefined" || value.length === 0) {
                setSponsoredState("error");
                setLoading(false);
            } else {
                setSponsoredState("loading");
                Sponsorship.get(value.toString()).then((res) => {
                    onChange({"code" : value});
                    if (res.success && res.code !== null) {
                        setSponsoredState("success");
                        setLoading(false);
                        setValid(true);
                    }
                }).catch((res) => {
                    if (res.success === false) {
                        setSponsoredState("error");
                        setLoading(false);
                    }
                });
            }
        }
    }

    const handleSponsoredInput = async (e) => {
        const value = e.target.value;
        if ( value.length <= 6 ) {
            await setSponsored({"code" : value})
            sponsoredInputProcess(value);
        }
    };



    useEffect(() => {
        // if variable undefined or null, it is invalid
        sponsoredInputProcess(sponsored.code);
        //eslint-disable-next-line
    }, []);

    if(loading === true) {
        return <Loader loading={loading}/>
    } else {
        return (
            <Grid container alignItems={"center"}>
                <Grid item xs={12} sm={4} md={6}>
                    <img src={generateUrlImage("sponsorship/sponsorshipRegister.svg", false, true)} alt={"système de parrainage"} style={{width: "100%"}}/>
                </Grid>
                <Grid item xs={12} sm={8} md={6} >
                    <Typography sx={{textAlign: "center", marginTop: "1rem"}}>
                        Gagner 1O€ avec un code parrainage
                    </Typography>
                    <Typography sx={{textAlign: "center", marginBottom: "1rem", fontSize: "12px"}}>
                        Si vous n'avez pas de code parrainage vous pouvez passer cette étape
                    </Typography>
                    <FormControl sx={{ m: 1, width: '100%'}} variant="outlined">
                        <Box sx={{margin: "-8px 16px", padding: "0 4px", background: "white", zIndex: 10, width: "fit-content"}}>
                            <Typography sx={{fontSize: "12px"}}>Code de parrainage</Typography>
                        </Box>
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            label="Code de parrainage"
                            value={sponsored.code}
                            placeholder="xxxxxx"
                            onChange={handleSponsoredInput}
                            error={sponsoredState === "error"}
                            endAdornment={
                                <InputAdornment position="end">
                                    {sponsoredState === "success" && <TaskAltIcon color="success"/>}
                                    {sponsoredState === "error" && <HighlightOffIcon color="error"/>}
                                    {sponsoredState === "loading" &&
                                        <CircularProgress sx={{height: "24px !important", width: "24px !important"}}/>
                                    }
                                </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                        />
                        <FormHelperText id="outlined-weight-helper-text">
                            La réception de votre récompense aura lieu après vérification de votre profil
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        )}
}
