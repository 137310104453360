import React from "react";
import {FormControl, FormControlLabel, Switch} from "@mui/material";

export default function CheckboxInputContent({
    name,
    labelText,
    value,
    handleChange,
    helperText,
    ...props
}) {
    return (
        <FormControl component="fieldset">
            <FormControlLabel
                control={
                    <Switch
                        name={name}
                        checked={value}
                        value={value}
                        onChange={handleChange}
                        color="primary"
                        {...props}
                    />
                }
                label={labelText}
                labelPlacement="end"
                disabled={!handleChange}
            />
        </FormControl>
    );
}