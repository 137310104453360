//Select the state of the Role stored in the Redux Store
export const RoleSelector = ({ user }) => user.role;

//Select the Announcement stored in the Redux Store
export const AnnouncementSelector = ({ announcement }) => announcement;

//Select the User stored in the Redux Store
export const UserInformationsSelector = ({ user }) => user;

//Select the state of Connection stored in the Redux Store
export const ConnectionSelector = ({ connection }) => connection;

//Select the state of Inscription stored in the Redux Store
export const InscriptionSelector = ({ inscription }) => inscription;

//Select the Filter stored in the Redux Store
export const FilterSelector = ({ filter }) => filter;

//Select the ChatRoom stored in the Redux Store
export const ChatRoomsSelector = ({ chat }) => chat.rooms;
