import {
    SET_USER_ORGANIZATION_INFO,
    SET_USER_ACTIVITIES,
    SET_USER_ADDRESS,
    SET_USER_IDENTITY_CARD,
    SET_USER_INDIVIDUAL_INFO,
    SET_USER_MAIL,
    SET_USER_PASSWORD,
    SET_USER_PROFESSIONAL_INFO,
    SET_USER_SIRET,
    SET_USER_URSAFF,
    SET_USER_WEBSITE,
    SET_USER_RCPRO
} from "../../constants/ActionTypes";

const UserSettingsSwitch = (type, state, action) => {
    const newState = state.data;
    switch (type) {
        case SET_USER_INDIVIDUAL_INFO:
            return {
                ...state,
                gender: action.payload.data.gender,
                name: action.payload.data.name,
                lastName: action.payload.data.lastName,
                birthDate: action.payload.data.birthDate,
                telephone: action.payload.data.telephone
            };
        case SET_USER_ORGANIZATION_INFO:
            return {
                ...state,
                name: action.payload.data.name,
                status: action.payload.data.status,
                managerFirstName: action.payload.data.managerFirstName,
                managerLastName: action.payload.data.managerLastName,
                telephone: action.payload.data.telephone
            };
        case SET_USER_ACTIVITIES:
            newState.activities = action.payload.data.activities;
            return state;
        case SET_USER_ADDRESS:
            newState.address = action.payload.data.address;
            newState.postcode = action.payload.data.postcode;
            newState.city = action.payload.data.city;
            newState.country = action.payload.data.country;
            return state;
        case SET_USER_WEBSITE:
            newState.websiteLink = action.payload.data.website;
            newState.websiteLinkIsVisible = action.payload.data.websiteIsVisible;
            return state;
        case SET_USER_PROFESSIONAL_INFO:
            newState.urlProfilEaps = action.payload.data.urlProfilEaps;
            newState.professionalCardExpirationDate = action.payload.data.professionalCardExpirationDate;
            newState.professionalCardGivenBy = action.payload.data.professionalCardGivenBy;
            newState.professionalCardNumber = action.payload.data.professionalCardNumber;
            newState.websiteLinkIsVisible = action.payload.data.websiteIsVisible;
            return state;
        case SET_USER_URSAFF:
            newState.siret = action.payload.data.siret;
            newState.attestationUrsaf = action.payload.data.attestationUrsaf;
            return state;
        case SET_USER_IDENTITY_CARD:
            newState.identityCard = action.payload.data.identityCard;
            return state;
        case SET_USER_MAIL:
            newState.emailIsVisible = action.payload.data.emailIsVisible;
            return state;
        case SET_USER_PASSWORD:
            newState.password = action.payload.data.password;
            return state;
        case SET_USER_SIRET:
            if (action.payload.data.siret) newState.siret = action.payload.data.siret;
            if (action.payload.data.vat) newState.vat = action.payload.data.vat;
            return state;
        case SET_USER_RCPRO:
            newState.rcpro = action.payload.data.rcpro;
            return state;
        default:
            return state;
    }
}

export default UserSettingsSwitch;