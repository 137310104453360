import React, {useEffect, useState} from "react";
import {Container, Grid, MenuItem, Pagination, Stack, TextField} from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import TitlePage from "../../../components/TitlePage";
import {Helmet} from "react-helmet";
import ListArticleComponent from "../components/ListArticleComponent";
import getArticlesByParamsAPI from "../../../services/Blog/getArticlesByParamsAPI";
import getArticleCategoriesAPI from "../../../services/Blog/getArticleCategoriesAPI";
import {ListChoiceInput} from "../../../components/Inputs";

const BlogContainer = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const [categorySelected, setCategorySelected] = useState(
        queryParameters.get("category") != null ?
            queryParameters.get("category") :
            ""
    )
    const [tagsSelected, setTagsSelected] = useState(
        queryParameters.get("tags") != null ?
            queryParameters.get("tags").split(',') :
            []
    );
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [load, setLoad] = useState(true)
    const [paginateSettings, setPaginateSettings] = useState({
        page: 1,
        totalPage: 1
    });
    const [tagsSelectable, setTagsSelectable] = useState([]);

    // On Init view, get all categories of article existed
    useEffect(() => {
        getArticleCategoriesAPI(true)
            .then((data) => {
                let totalCount = 0;
                for (let item of data.categories){
                    totalCount += item.count
                }
                data.categories.push({_id: "Tous", count: totalCount});
                setCategories(data.categories);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Fct call API to get articles by params selected
     * @param {number} page num page selected for pagination
     * @param {boolean} isCategorySelectedChange true if category selected are modified
     */
    const callAPIGetArticles = (page = 1, isCategorySelectedChange = false) => {
        const tagSend = isCategorySelectedChange ? [] : tagsSelected;
        getArticlesByParamsAPI(categorySelected, tagSend, page)
            .then((resp) => {
                setTagsSelectable(resp.tagsSelectable);
                setArticles(resp.articles);
                setPaginateSettings({
                    page: resp.currentPage,
                    totalPage: resp.totalPages
                })
                setLoad(false)
            })
            .catch(() => {
                setLoad(false)
            })
    }

    // Intercept all changement on categorySelected --> update url & init tag selected & get new list of article
     useEffect(() => {
         setTagsSelected([])
         if (categorySelected !== ""){
             window.history.pushState(null,"", `blog?category=${categorySelected}`);
         }
         callAPIGetArticles(1, true);
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [categorySelected])

    // Intercept all changement on tagsSelected --> update url & get new list of article
    useEffect(() => {
        let urlUpdate = `blog`
        if (categorySelected !== "" || tagsSelected.length > 0){
            urlUpdate += '?';
            if (categorySelected !== ""){
              urlUpdate += `category=${categorySelected}`
            }
            if (tagsSelected.length > 0){
                if (categorySelected !== ""){
                    urlUpdate += `&`
                }
                urlUpdate += `tags=${tagsSelected.toString()}`
            }
        }
        window.history.pushState(null,"", urlUpdate);

        callAPIGetArticles(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsSelected])

    const handleChangePagination = (event, value) => {
        callAPIGetArticles(value);
    }

        return (
            <>
                <Helmet>
                    <title>Blog - We Guide You</title>
                    <meta name="description" content="Consulter les articles relatif à l'emploi dans le domaine du sport"/>
                    <meta property="og:title" content="Blog - We Guide You"/>
                    <meta property="og:description" content="Consulter les articles relatif à l'emploi dans le domaine du sport"/>
                    <meta property="og:url" content={window.location.href}/>
                </Helmet>
                <Container sx={{mb: 4}}>
                    <TitlePage
                        title={"Blog"}
                        subTitle={"Consulter les articles relatifs à l'emploi dans le domaine du sport"}
                    />
                    <Grid
                        container mb={2}
                        sx={{justifyContent:{xs: "center", sm: "space-around"}}}
                        spacing={2}
                    >
                        <Grid item xs={8} sm={5} md={3}>

                            <TextField
                                name={"category"}
                                select
                                label={"Sélectionner une catégorie"}
                                fullWidth
                                type="text"
                                value={categorySelected}
                                onChange={(e) => {
                                    if (e.target.value === "Tous"){
                                        setCategorySelected("")
                                    } else {
                                        setCategorySelected(e.target.value)
                                    }
                                }}
                            >
                                {categories.map((option) => (
                                    <MenuItem key={option._id} value={option._id}>
                                        {option._id} ({option.count})
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={8} sm={5} md={4}>
                            <ListChoiceInput
                                name="Tag"
                                labelText="Sélectionner des Tags"
                                fullWidth
                                value={tagsSelected}
                                values={tagsSelectable}
                                handleChange={(event) =>
                                    setTagsSelected(event.target.value)
                                }
                            />
                        </Grid>
                    </Grid>
                    {load ?
                        <Loader /> :
                        <>
                            <ListArticleComponent articles={articles}/>
                            {paginateSettings.totalPage > 1 &&
                                <Stack spacing={2} >
                                    <Pagination
                                        count={paginateSettings.totalPage}
                                        page={paginateSettings.page}
                                        onChange={handleChangePagination}
                                        sx={{display: "flex",justifyContent: "center"}}
                                    />
                                </Stack>
                            }
                        </>
                    }
                </Container>
            </>
        )
}

export default BlogContainer
