import React from "react";
import { useSelector } from "react-redux";
import Topbar from "../components/Topbar/Navbar";
import { UserInformationsSelector } from "../selectors";

/**
 * Container for the Topbar of the app
 * @returns
 */
function TopBarContainer() {
	const user = useSelector(UserInformationsSelector);

	return <Topbar user={user.data} />;
}

export default TopBarContainer;
