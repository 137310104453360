export default function sendMailAPI(user, reason, comment, canCall) {
    return new Promise((resolve, reject) => {
        const request = new Request(
            `${process.env.REACT_APP_API_URL}/report/findbug`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: user.token,
            },
            body: JSON.stringify({
                reason,
                canCall,
                comment
            }),
        });

        fetch(request)
            .then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        resolve({ report: json.report });
                    } else {
                        reject(json.error);
                        console.error(json.error);
                    }
                });
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });
}