import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Function that scroll to top when the url change
 * @returns
 */
function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

export default ScrollToTop;
