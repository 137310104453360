import {getDateDiff} from "../general/date_manipulation";

/**
 * Fct to get since when an announcement was created on hour or nm days if > 24h
 * @param announcement
 * @returns {string}
 */
export default function getCreatedDate(announcement) {
    // Check if this announcement is valid
    if ( announcement.isActive ) {
        // Get dates
        const createdAt = new Date(announcement.publishAt ? announcement.publishAt : announcement.createdAt);
        const now = new Date();
        let days = getDateDiff(createdAt, now, "D");

        if (createdAt > now) {
          //  console.error(new Error("announce created in the future 🚗"));
            return '';
        } else {
           // Print number of days difference
           if(days >= 1) {
                if(days === 1) {
                    return ("il y a 1 jour");
                } else {
                    return ("il y a " + days + " jours");
                }
           } else {
                // Print number of hours difference
                let hours = getDateDiff(createdAt, now, "H");
                if(hours >= 1) {
                    if(hours === 1) {
                        return ("il y a 1 heure");
                    } else {
                        return ("il y a " + hours + " heures");
                    }
                } else {
                    // Print number of minutes difference
                    let minutes = getDateDiff(createdAt, now, "M");
                    if(minutes >= 1) {
                        return ("il y a " + minutes + " minutes");
                    } else {
                        return ("il y a 1 minute");
                    }
                }
            }
        }
    }
}
