import {useState} from "react";
import {Alert, Card, CardContent, Grid, IconButton, Typography} from "@mui/material";
import {DeleteForever, AddAPhoto} from "@mui/icons-material";
import theme from "../../../theme";
import {generateUrlImage} from "../../../utils/general/generateUrlImage";


export const ImageInputContent = ({
    id,
    labelText,
    value,
    updateImage,
    removeImage,
    editWithoutUpload= false,
    displayError = false,
    isBlogImage = false,
    ...props
}) => {
    const [isEditWithoutUpload, setIsEditWithoutUpload] = useState(editWithoutUpload);
    const [isLoading, setIsLoading] = useState(false);
    const handleRemoveimage = () => {
        if (isEditWithoutUpload){
            setIsEditWithoutUpload(false);
        }
        removeImage();
    }

    async function handleUpdateImage (event) {
        setIsLoading(true);
        await updateImage(event);
        if (isEditWithoutUpload){
            setIsEditWithoutUpload(false);
        }
        setIsLoading(false);
    }

    return (
        <Card variant="outlined" className={props.className} sx={{border: "none"}}>
            <CardContent>
                <Grid container align="center">
                    <Grid item xs={12}>
                        <Typography color="primary">{labelText}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <input
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(e) => handleUpdateImage(e)}
                            id={id}
                            type="file"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor={id}>
                            <IconButton
                                sx={{color: theme.palette.primary.main}}
                                aria-label="upload picture"
                                component="span"
                                size="large"
                            >
                                <AddAPhoto />
                            </IconButton>
                        </label>
                        {value !== null &&(
                            <IconButton
                                color="primary"
                                aria-label="delete picture"
                                component="span"
                                onClick={handleRemoveimage}
                                size="large">
                                <DeleteForever />
                            </IconButton>
                        )}
                        {displayError &&
                            <Alert severity="error">Veuillez sélectionner une image</Alert>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {value !== null && !isEditWithoutUpload &&
                            <img style={{maxHeight: "400px"}} alt="" src={URL.createObjectURL(value)}
                            />}
                        {value !== null && isEditWithoutUpload && !isLoading &&
                            <img style={{maxHeight: "400px"}} alt="" src={generateUrlImage(value, false, false, isBlogImage)}
                            />}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
