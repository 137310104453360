import {
  ADD_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT_FEATURED,
  ADD_CHAT_ROOM,
  ADD_MY_ANNOUNCEMENT,
  CLEAR_FILTER,
  NEW_CHAT_MESSAGE,
  REMOVE_ALL_ANNOUNCEMENT,
  REMOVE_ALL_ANNOUNCEMENT_FEATURED,
  REMOVE_MY_ANNOUNCEMENT,
  SET_ACCOUNT_GENERAL,
  SET_ACCOUNT_SHARED,
  SET_ACCOUNT_SPECIFIC,
  SET_ACCOUNT_TYPE,
  SET_CHAT_OFFER,
  SET_CHAT_ROOMS,
  SET_CONNECTION,
  SET_FILTER_ACTIVITY,
  SET_FILTER_ANNOUNCEMENT_TYPE,
  SET_FILTER_CREATOR_TYPE,
  SET_FILTER_END_DATE,
  SET_FILTER_PLACE,
  SET_FILTER_SECONDARY_COUNTRY,
  SET_FILTER_SECONDARY_COURSE_LEVEL,
  SET_FILTER_SECONDARY_COURSE_TYPE,
  SET_FILTER_SECONDARY_GRADUATED,
  SET_FILTER_SECONDARY_LANGUAGE,
  SET_FILTER_SECONDARY_PERIOD,
  SET_FILTER_SECONDARY_PROVIDED_OUTFIT,
  SET_FILTER_SECONDARY_PROVIDED_WORK_CLOTHES,
  SET_FILTER_SECONDARY_HOUSING,
  SET_FILTER_START_DATE,
  SET_READ_ALL_MESSAGE,
  SET_ROLE,
  SET_USER_DISCONNECTED,
  SET_USER_FAVOURITE,
  SET_USER_INFORMATIONS,
  SET_USER_PROFILE_INFORMATIONS,
  SET_USER_TOKEN,
  SET_CHAT_RESERVATION,
  SET_CHAT_CANCEL_RESERVATION,
  SET_CHAT_CANCEL_RESERVATION_WITHOUT_CHATROOM,
  SET_FILTER_SECONDARY_CONTRACT_TYPE,
  SET_ANNOUNCEMENT_DATA,
  SET_PAYEMENT_OPTION,
  CLEAR_ANNOUNCEMENT,
  SET_FILTER_SECONDARY_CREATED_DATE,
  SET_SPONSORED,
} from '../constants/ActionTypes';

export const setRole = (role) => ({
  type: SET_ROLE,
  payload: {
    role,
  },
});

export const setFilterAnnouncementType = (announcementType) => ({
  type: SET_FILTER_ANNOUNCEMENT_TYPE,
  payload: {
    announcementType,
  },
});

export const setFilterCreatorType = (creatorType) => ({
  type: SET_FILTER_CREATOR_TYPE,
  payload: {
    creatorType,
  },
});

export const setFilterActivity = (activity) => ({
  type: SET_FILTER_ACTIVITY,
  payload: {
    activity,
  },
});

export const setFilterStartDate = (startDate) => ({
  type: SET_FILTER_START_DATE,
  payload: {
    startDate,
  },
});

export const setFilterEndDate = (endDate) => ({
  type: SET_FILTER_END_DATE,
  payload: {
    endDate,
  },
});

export const setFilterPlace = (place) => ({
  type: SET_FILTER_PLACE,
  payload: {
    place,
  },
});

export const clearFilter = () => ({
  type: CLEAR_FILTER,
});

export const setFilterSecondaryCreatedDate = (createdDate) => ({
  type: SET_FILTER_SECONDARY_CREATED_DATE,
  payload: {
    createdDate,
  },
});

export const setFilterSecondaryCourseType = (courseType) => ({
  type: SET_FILTER_SECONDARY_COURSE_TYPE,
  payload: {
    courseType,
  },
});

export const setFilterSecondaryPeriod = (period) => ({
  type: SET_FILTER_SECONDARY_PERIOD,
  payload: {
    period,
  },
});

export const setFilterSecondaryCourseLevel = (courseLevel) => ({
  type: SET_FILTER_SECONDARY_COURSE_LEVEL,
  payload: {
    courseLevel,
  },
});

export const setFilterSecondaryContractType = (contractType) => ({
  type: SET_FILTER_SECONDARY_CONTRACT_TYPE,
  payload: {
    contractType,
  },
});

export const setFilterSecondaryLanguage = (language) => ({
  type: SET_FILTER_SECONDARY_LANGUAGE,
  payload: {
    language,
  },
});

export const setFilterSecondaryCountry = (country) => ({
  type: SET_FILTER_SECONDARY_COUNTRY,
  payload: {
    country,
  },
});

export const setFilterSecondaryGraduated = (graduated) => ({
  type: SET_FILTER_SECONDARY_GRADUATED,
  payload: {
    graduated,
  },
});

export const setFilterSecondaryProvidedOutfit = (providedOutfit) => ({
  type: SET_FILTER_SECONDARY_PROVIDED_OUTFIT,
  payload: {
    providedOutfit,
  },
});

export const setFilterSecondaryProvidedWorkClothes = (providedWorkClothes) => ({
  type: SET_FILTER_SECONDARY_PROVIDED_WORK_CLOTHES,
  payload: {
    providedWorkClothes,
  },
});

export const setFilterSecondaryHousing = (housing) => ({
  type: SET_FILTER_SECONDARY_HOUSING,
  payload: {
    housing,
  },
});

export const setConnection = (connection) => ({
  type: SET_CONNECTION,
  payload: {
    connection,
  },
});

export const setAccountType = (accountType) => ({
  type: SET_ACCOUNT_TYPE,
  payload: {
    accountType,
  },
});

export const setAccountGeneral = (accountGeneral) => ({
  type: SET_ACCOUNT_GENERAL,
  payload: {
    accountGeneral,
  },
});

export const setSponsored = (accountSponsored) => ({
  type: SET_SPONSORED,
  payload: {
    accountSponsored,
  },
});

export const setAccountShared = (accountShared) => ({
  type: SET_ACCOUNT_SHARED,
  payload: {
    accountShared,
  },
});

export const setAccountSpecific = (accountSpecific) => ({
  type: SET_ACCOUNT_SPECIFIC,
  payload: {
    accountSpecific,
  },
});

export const setUserInformation = (data) => ({
  type: SET_USER_INFORMATIONS,
  payload: { data },
});

export const setUserProfileInformation = (data) => ({
  type: SET_USER_PROFILE_INFORMATIONS,
  payload: { data },
});

export const setUserToken = (token) => ({
  type: SET_USER_TOKEN,
  payload: { token },
});

export const setUserDisconnected = () => ({
  type: SET_USER_DISCONNECTED,
});

export const setUserFavourite = (announcementId, value) => ({
  type: SET_USER_FAVOURITE,
  payload: {
    announcementId,
    value,
  },
});

export const addAnnouncement = (announcements) => ({
  type: ADD_ANNOUNCEMENT,
  payload: { announcements },
});

export const setAnnouncementData = (announcement) => ({
  type: SET_ANNOUNCEMENT_DATA,
  payload: { announcement },
});

export const setPayementOptions = (listOptionSubscribe, totalPrice) => ({
  type: SET_PAYEMENT_OPTION,
  payload: { listOptionSubscribe, totalPrice },
});

export const clearAnnouncement = () => ({
  type: CLEAR_ANNOUNCEMENT,
});

export const removeAllAnnouncement = () => ({
  type: REMOVE_ALL_ANNOUNCEMENT,
});

export const addAnnouncementFeatured = (announcements) => ({
  type: ADD_ANNOUNCEMENT_FEATURED,
  payload: { announcements },
});

export const removeAllAnnouncementFeatured = () => ({
  type: REMOVE_ALL_ANNOUNCEMENT_FEATURED,
});

export const addMyAnnouncements = (announcements) => ({
  type: ADD_MY_ANNOUNCEMENT,
  payload: { announcements },
});

export const removeMyAnnouncements = () => ({
  type: REMOVE_MY_ANNOUNCEMENT,
});

export const setChatRooms = (rooms) => ({
  type: SET_CHAT_ROOMS,
  payload: { rooms },
});
export const addChatRoom = (room) => ({
  type: ADD_CHAT_ROOM,
  payload: { room },
});

export const newChatMessage = (message, user) => ({
  type: NEW_CHAT_MESSAGE,
  payload: { message, user },
});

export const setReadAllMessage = (chatRoomId, user) => ({
  type: SET_READ_ALL_MESSAGE,
  payload: { chatRoomId, user },
});

export const setChatOffer = (chatRoomId, offer) => ({
  type: SET_CHAT_OFFER,
  payload: { chatRoomId, offer },
});

export const setNewReservation = (chatRoomId, reservation) => ({
  type: SET_CHAT_RESERVATION,
  payload: { chatRoomId, reservation },
});

export const setCancelReservation = (chatRoomId, reservation) => ({
  type: SET_CHAT_CANCEL_RESERVATION,
  payload: { chatRoomId, reservation },
});

export const setCancelReservationWithoutChatRoom = (reservation) => ({
  type: SET_CHAT_CANCEL_RESERVATION_WITHOUT_CHATROOM,
  payload: { reservation },
});
