import AccountType from "./AccountType";

export const periods = [
	{ value: "morning", key: "Matin" },
	{ value: "midday", key: "Midi" },
	{ value: "afternoon", key: "Après-midi" },
	{ value: "day", key: "Journée" },
	{ value: "evening", key: "Soir" },
	{ value: "all", key: "Tous" },
];

export const periodsFilter = [
	{ value: "morning", key: "Matin" },
	{ value: "midday", key: "Midi" },
	{ value: "afternoon", key: "Après-midi" },
	{ value: "day", key: "Journée" },
	{ value: "evening", key: "Soir" },
];

export const courseTypes = [
	{ value: "individual", key: "Individuel" },
	{ value: "collective", key: "Collectif" },
	{ value: "all", key: "Tous" },
];

export const courseTypesFilter = [
	{ value: "individual", key: "Individuel" },
	{ value: "collective", key: "Collectif" },
];

export const contractTypes = [
	{ value: "freelance", key: "Freelance" },
	{ value: "employees", key: "Employé" },
	{ value: "alternation", key: "Alternance"},
	{ value: "traineeship", key: "Stage"},
];

export const courseLevels = [
	{ value: "beginner", key: "Débutant" },
	{ value: "intermediate", key: "Intermédiaire" },
	{ value: "expert", key: "Expert" },
	{ value: "all", key: "Tous" },
];

export const courseLevelsFilter = [
	{ value: "beginner", key: "Débutant" },
	{ value: "intermediate", key: "Intermédiaire" },
	{ value: "expert", key: "Expert" },
];

export const languages = [
	{value: "Français", key: "Français", code: "FR"},
	{value: "English",  key: "Anglais", code: "GB"},
	{value: "Deutsche", key: "Allemand", code: "DE"},
	{value: "Italiano", key: "Italien", code: "IT"},
	{value: "Español", key: "Espagnol", code: "ES"},
	{value: "Português", key: "Portugais", code: "PT"},
	{value: "中文", key: "Chinois", code: "CN"},
	{value: "русский", key: "Russe", code: "RU"},
]

export const countryTypes = [
	{ value: "France", key: "France", code: "fr"},
	{ value: "Italie", key: "Italie", code: "it"},
	{ value: "Espagne", key: "Espagne", code: "es"},
	{ value: "Suisse", key: "Suisse", code: "ch"},
	{ value: "Belgique", key: "Belgique", code: "be"},
	{ value: "Portugal", key: "Portugal", code: "pt"},
	{ value: "Grande-Bretagne", key: "Grande-Bretagne", code: "gb"},
	{ value: "Irlande", key: "Irlande", code: "ie"},
	{ value: "Autriche", key: "Autriche", code: "at"},
	{ value: "Pologne", key: "Pologne", code: 'pl'},
	{ value: "République tchèque", key: "Republique-tcheque", code: 'cz'},
	{ value: "Pays-bas", key: "Pays-bas", code:'nl'},
	{ value: "Luxembourg", key: "Luxembourg", code:'lu'}
]


const INPUT_CHOICE_ANNOUNCEMENT_KIND_RECRUTE = "Je recrute un éducateur sportif";
const INPUT_CHOICE_ANNOUNCEMENT_KIND_RECHERCHE_EMPLOI = "Je propose mes éducateurs sportifs disponibles aux professionnels"; // Je recherche du travail pour PS
const INPUT_CHOICE_ANNOUNCEMENT_KIND_PROPOSE_COURS = "Je propose un cours aux particuliers";

export const announcementTypes = [
	{ value: "ApplicationAnnouncement", key: INPUT_CHOICE_ANNOUNCEMENT_KIND_RECHERCHE_EMPLOI },
	{ value: "OfferingAnnouncement", key: INPUT_CHOICE_ANNOUNCEMENT_KIND_RECRUTE },
	{ value: "CourseAnnouncement", key: INPUT_CHOICE_ANNOUNCEMENT_KIND_PROPOSE_COURS },
];

export const isGraduated = [
	{ value: true, key: "Terminé" },
	{ value: false, key: "En Cours" },
];

export const isProvidedOutfit = [
	{ value: true, key: "Oui" },
	{ value: false, key: "Non" },
];

export const isHousing = [
	{ value: true, key: "Oui" },
	{ value: false, key: "Non" },
];

export const isProvidedWorkClothes = [
	{ value: true, key: "Oui" },
	{ value: false, key: "Non" },
];

export const unitTypesCourses = [
	{ value: "per-hour", key: "€/heure" },
	{ value: "per-morning", key: "€/matin" },
	{ value: "per-afternoon", key: "€/après-midi" },
	{ value: "per-session", key: "€/séance" },
	{ value: "per-day", key: "€/jour" },
	{ value: "per-week", key: "€/semaine" },
	{ value: "total", key: "€ au total" },
];

export const unitTypesOffering = [
	{ value: "per-hour", key: "€/heure" },
	{ value: "per-morning", key: "€/matin" },
	{ value: "per-afternoon", key: "€/après-midi" },
	{ value: "per-session", key: "€/séance" },
	{ value: "per-day", key: "€/jour" },
	{ value: "per-week", key: "€/semaine" },
	{ value: "per-month", key: "€/mois" },
	{ value: "per-season", key: "€/saison" },
	{ value: "total", key: "€ au total" },
];

export const creatorTypes = [
	{ value: AccountType.INDEPENDENT_PROFESSIONAL, key: "Professionnel du Sport" },
	{ value: AccountType.SPORTS_ORGANIZATION, key: "Organisme Sportif" },
	{ value: AccountType.ORGANIZATION, key: "Organisme Non-Sportif" },
	//{ value: "all", key: "Tous" },
];

export const announcementFilterTypes = [
	{ value: "ApplicationAnnouncement", key: "recrute" },
	{ value: "OfferingAnnouncement", key: "recherche un emploi" },
];

export const CreatedDateDefaultOptions = [
	{ value: "7", key: "Moins d'une semaine" },
	{ value: "14", key: "Moins de 2 semaines" },
	{ value: "30", key: "Moins d'un mois" },
];

export const AllCountryCode = [
	{code: "AF", value: "Afghanistan"},
	{code: "ZA", value: "Afrique du Sud"},
	{code: "AL", value: "Albanie"},
	{code: "DZ", value: "Algérie"},
	{code: "DE", value: "Allemagne"},
	{code: "AD", value: "Andorre"},
	{code: "AO", value: "Angola"},
	{code: "AI", value: "Anguilla"},
	{code: "AQ", value: "Antarctique"},
	{code: "AG", value: "Antigua-et-Barbuda"},
	{code: "SA", value: "Arabie saoudite"},
	{code: "AR", value: "Argentine"},
	{code: "AM", value: "Arménie"},
	{code: "AW", value: "Aruba"},
	{code: "AU", value: "Australie"},
	{code: "AT", value: "Autriche"},
	{code: "AZ", value: "Azerbaïdjan"},
	{code: "BS", value: "Bahamas"},
	{code: "BH", value: "Bahreïn"},
	{code: "BD", value: "Bangladesh"},
	{code: "BB", value: "Barbade"},
	{code: "BY", value: "Bélarus"},
	{code: "BE", value: "Belgique"},
	{code: "BZ", value: "Belize"},
	{code: "BJ", value: "Bénin"},
	{code: "BM", value: "Bermudes"},
	{code: "BT", value: "Bhoutan"},
	{code: "BO", value: "Bolivie"},
	{code: "BA", value: "Bosnie-Herzégovine"},
	{code: "BW", value: "Botswana"},
	{code: "BR", value: "Brésil"},
	{code: "BN", value: "Brunéi Darussalam"},
	{code: "BG", value: "Bulgarie"},
	{code: "BF", value: "Burkina Faso"},
	{code: "BI", value: "Burundi"},
	{code: "KH", value: "Cambodge"},
	{code: "CM", value: "Cameroun"},
	{code: "CA", value: "Canada"},
	{code: "CV", value: "Cap-Vert"},
	{code: "CL", value: "Chili"},
	{code: "CN", value: "Chine"},
	{code: "CY", value: "Chypre"},
	{code: "CO", value: "Colombie"},
	{code: "CG", value: "Congo-Brazzaville"},
	{code: "KP", value: "Corée du Nord"},
	{code: "KR", value: "Corée du Sud"},
	{code: "CR", value: "Costa Rica"},
	{code: "CI", value: "Côte d’Ivoire"},
	{code: "HR", value: "Croatie"},
	{code: "CU", value: "Cuba"},
	{code: "DK", value: "Danemark"},
	{code: "DJ", value: "Djibouti"},
	{code: "DM", value: "Dominique"},
	{code: "EG", value: "Égypte"},
	{code: "SV", value: "El Salvador"},
	{code: "AE", value: "Émirats arabes unis"},
	{code: "EC", value: "Équateur"},
	{code: "ER", value: "Érythrée"},
	{code: "ES", value: "Espagne"},
	{code: "EE", value: "Estonie"},
	{code: "FM", value: "États fédérés de Micronésie"},
	{code: "US", value: "États-Unis"},
	{code: "ET", value: "Éthiopie"},
	{code: "FJ", value: "Fidji"},
	{code: "FI", value: "Finlande"},
	{code: "FR", value: "France"},
	{code: "GA", value: "Gabon" },
	{code: "GM", value: "Gambie"},
	{code: "GE", value: "Géorgie"},
	{code: "GS", value: "Géorgie du Sud et les îles Sandwich du Sud"},
	{code: "GH", value: "Ghana"},
	{code: "GI", value: "Gibraltar"},
	{code: "GR", value: "Grèce"},
	{code: "GD", value: "Grenade"},
	{code: "GL", value: "Groenland"},
	{code: "GP", value: "Guadeloupe"},
	{code: "GU", value: "Guam"},
	{code: "GT", value: "Guatemala"},
	{code: "GN", value: "Guinée"},
	{code: "GQ", value: "Guinée équatoriale"},
	{code: "GW", value: "Guinée-Bissau"},
	{code: "GY", value: "Guyana"},
	{code: "GF", value: "Guyane française"},
	{code: "HT", value: "Haïti"},
	{code: "HN", value: "Honduras"},
	{code: "HU", value: "Hongrie"},
	{code: "BV", value: "Île Bouvet"},
	{code: "KY", value: "Îles Caïmans"},
	{code: "CK", value: "Îles Cook"},
	{code: "FO", value: "Îles Féroé"},
	{code: "HM", value: "Îles Heard et MacDonald"},
	{code: "FK", value: "Îles Malouines"},
	{code: "MP", value: "Îles Mariannes du Nord"},
	{code: "MH", value: "Îles Marshall"},
	{code: "SB", value: "Îles Salomon"},
	{code: "TC", value: "Îles Turks et Caïques"},
	{code: "VG", value: "Îles Vierges britanniques"},
	{code: "VI", value: "Îles Vierges des États-Unis"},
	{code: "IN", value: "Inde"},
	{code: "ID", value: "Indonésie"},
	{code: "IQ", value: "Irak"},
	{code: "IR", value: "Iran"},
	{code: "IE", value: "Irlande"},
	{code: "IS", value: "Islande"},
	{code: "IL", value: "Israël"},
	{code: "IT", value: "Italie"},
	{code: "JM", value: "Jamaïque"},
	{code: "JP", value: "Japon" },
	{code: "JE", value: "Jersey"},
	{code: "JO", value: "Jordanie"},
	{code: "KZ", value: "Kazakhstan"},
	{code: "KE", value: "Kenya"},
	{code: "KG", value: "Kirghizistan"},
	{code: "KI", value: "Kiribati"},
	{code: "KW", value: "Koweït"},
	{code: "LA", value: "Laos"},
	{code: "LS", value: "Lesotho"},
	{code: "LV", value: "Lettonie"},
	{code: "LB", value: "Liban"},
	{code: "LR", value: "Libéria"},
	{code: "LY", value: "Libye"},
	{code: "LI", value: "Liechtenstein"},
	{code: "LT", value: "Lituanie"},
	{code: "LU", value: "Luxembourg"},
	{code: "MK", value: "Macédoine"},
	{code: "MG", value: "Madagascar"},
	{code: "MY", value: "Malaisie"},
	{code: "MW", value: "Malawi"},
	{code: "MV", value: "Maldives"},
	{code: "ML", value: "Mali"},
	{code: "MT", value: "Malte"},
	{code: "MA", value: "Maroc"},
	{code: "MQ", value: "Martinique"},
	{code: "MU", value: "Maurice"},
	{code: "MR", value: "Mauritanie"},
	{code: "YT", value: "Mayotte"},
	{code: "MX", value: "Mexique"},
	{code: "MD", value: "Moldavie"},
	{code: "MC", value: "Monaco"},
	{code: "MN", value: "Mongolie"},
	{code: "ME", value: "Monténégro"},
	{code: "MS", value: "Montserrat"},
	{code: "MZ", value: "Mozambique"},
	{code: "MM", value: "Myanmar"},
	{code: "NA", value: "Namibie"},
	{code: "NR", value: "Nauru"},
	{code: "NP", value: "Népal"},
	{code: "NI", value: "Nicaragua"},
	{code: "NE", value: "Niger"},
	{code: "NG", value: "Nigéria"},
	{code: "NU", value: "Niue"},
	{code: "NO", value: "Norvège"},
	{code: "NC", value: "Nouvelle-Calédonie"},
	{code: "NZ", value: "Nouvelle-Zélande"},
	{code: "OM", value: "Oman"},
	{code: "UG", value: "Ouganda"},
	{code: "UZ", value: "Ouzbékistan"},
	{code: "PK", value: "Pakistan"},
	{code: "PW", value: "Palaos"},
	{code: "PA", value: "Panama"},
	{code: "PG", value: "Papouasie-Nouvelle-Guinée"},
	{code: "PY", value: "Paraguay"},
	{code: "NL", value: "Pays-Bas"},
	{code: "PE", value: "Pérou"},
	{code: "PH", value: "Philippines"},
	{code: "PN", value: "Pitcairn"},
	{code: "PL", value: "Pologne"},
	{code: "PF", value: "Polynésie française"},
	{code: "PR", value: "Porto Rico"},
	{code: "PT", value: "Portugal"},
	{code: "QA", value: "Qatar"},
	{code: "HK", value: "R.A.S. chinoise de Hong Kong"},
	{code: "MO", value: "R.A.S. chinoise de Macao"},
	{code: "CF", value: "République centrafricaine"},
	{code: "CD", value: "République démocratique du Congo"},
	{code: "DO", value: "République dominicaine"},
	{code: "CZ", value: "République tchèque"},
	{code: "RE", value: "Réunion"},
	{code: "RO", value: "Roumanie"},
	{code: "GB", value: "Royaume-Uni"},
	{code: "RU", value: "Russie"},
	{code: "RW", value: "Rwanda"},
	{code: "EH", value: "Sahara occidental"},
	{code: "KN", value: "Saint-Kitts-et-Nevis"},
	{code: "SM", value: "Saint-Marin"},
	{code: "PM", value: "Saint-Pierre-et-Miquelon"},
	{code: "VC", value: "Saint-Vincent-et-les Grenadines"},
	{code: "SH", value: "Sainte-Hélène"},
	{code: "LC", value: "Sainte-Lucie"},
	{code: "WS", value: "Samoa"},
	{code: "ST", value: "Sao Tomé-et-Principe"},
	{code: "SN", value: "Sénégal"},
	{code: "RS", value: "Serbie"},
	{code: "SC", value: "Seychelles"},
	{code: "SL", value: "Sierra Leone"},
	{code: "SG", value: "Singapour"},
	{code: "SK", value: "Slovaquie"},
	{code: "SI", value: "Slovénie"},
	{code: "SO", value: "Somalie"},
	{code: "SD", value: "Soudan"},
	{code: "LK", value: "Sri Lanka"},
	{code: "SE", value: "Suède"},
	{code: "CH", value: "Suisse"},
	{code: "SR", value: "Surivalue"},
	{code: "SZ", value: "Swaziland"},
	{code: "SY", value: "Syrie"},
	{code: "TJ", value: "Tadjikistan"},
	{code: "TW", value: "Taïwan"},
	{code: "TZ", value: "Tanzanie"},
	{code: "TD", value: "Tchad"},
	{code: "TF", value: "Terres australes françaises"},
	{code: "IO", value: "Territoire britannique de l'océan Indien"},
	{code: "TH", value: "Thaïlande"},
	{code: "TG", value: "Togo"},
	{code: "TK", value: "Tokelau"},
	{code: "TO", value: "Tonga"},
	{code: "TT", value: "Trinité-et-Tobago"},
	{code: "TN", value: "Tunisie"},
	{code: "TM", value: "Turkménistan"},
	{code: "TR", value: "Turquie"},
	{code: "TV", value: "Tuvalu"},
	{code: "UA", value: "Ukraine"},
	{code: "UY", value: "Uruguay"},
	{code: "VU", value: "Vanuatu"},
	{code: "VE", value: "Venezuela"},
	{code: "VN", value: "Viêt Nam"},
	{code: "WF", value: "Wallis-et-Futuna"},
	{code: "YE", value: "Yémen"},
	{code: "ZM", value: "Zambie"},
	{code: "ZW", value: "Zimbabwe"}
]



