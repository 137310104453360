export function getAnnouncementAlt(announcement) {
    let subject = "";
    let city = "";
    if(announcement.city) city = " sur " + announcement.city;
    if(announcement.kind === "CourseAnnouncement") subject = "Cours de ";
    if(announcement.kind === "OfferingAnnouncement") subject = "Offre emploi moniteur ";
    if(announcement.kind === "ApplicationAnnouncement") subject = "Recherche emploi moniteur ";
    return subject + announcement.activity.name + city + " - We Guide You"

}

export function getUserAlt(user) {
    if (typeof user.lastName !== "undefined") { return user.name + " " + user.lastName; }
    else return user.name
}
