import React, {useEffect, useState} from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {Autocomplete, Checkbox, TextField} from "@mui/material";
import { languages } from "../../../constants/typeToText";

        export default function LanguagesChoiceInputContent({
    id,
    name,
    labelText,
    labelShrink,
    fullWidth,
    margin,
    value,
    values,
    handleChange,
    error,
    helperText,
    disabled,
}) {
    const inputLabelPropsValue = labelShrink ? { shrink: true } : null;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [allSelected, setAllSlected] = useState(false);

    useEffect(()=> {
        let e = {
            target: {
                value: [],
                name: name,
            }
        };
        if (value.includes("Tous")) {
            e.target.value = values;
            const indexAll = e.target.value.indexOf("Tous");
            if (indexAll !== -1){
                e.target.value.splice(indexAll, 1);
            }
            setAllSlected(true);
            handleChange(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeFeature = (e) => {
        // if All Options are selected before and user click on all remove all option selected
        if (allSelected && e.target.value.length === values.length){
            setAllSlected(false);
            e.target.value = [];
        } else if (e.target.value.length < values.length && allSelected) {
            // if All Options are selected before and user click on an option remove option selected & all option label
            const indexAll = e.target.value.indexOf("Tous");
            if (indexAll !== -1){
                e.target.value.splice(indexAll, 1);
            }
            setAllSlected(false);
        }
        // If all option selected we selected all option
        else if (e.target.value.includes("Tous")){
            e.target.value = values;
            const indexAll = e.target.value.indexOf("Tous");
            if (indexAll !== -1){
                e.target.value.splice(indexAll, 1);
            }
            setAllSlected(true);
        }
        handleChange(e);
    }

    return (
        <Autocomplete
            id={id}
            multiple
            options={values}
            getOptionLabel={(country) => country}
            renderOption={(props, country, {selected}) => {
                const value = languages.filter((item) => {
                    return item.value.toLowerCase() === country.toLowerCase() ;
                })
                const code = value[0].code
                return (
                <li {...props}>
                    <Checkbox
                        id={code.toUpperCase()}
                        className={"autocomplete-" + country}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8}}
                        checked={allSelected ? true : selected}
                    />
                    <img
                        style={{paddingRight: '0.5rem'}}
                        loading="lazy"
                        src={`https://flagcdn.com/20x15/${code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/40x30/${code.toLowerCase()}.png 2x, 
                        https://flagcdn.com/60x45/${code.toLowerCase()}.png 3x`}
                        width="20"
                        height="15"
                        alt={country} />
                    {country}
                </li>
            )}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    label={labelText}
                    fullWidth={fullWidth}
                    margin={margin}
                    InputLabelProps={inputLabelPropsValue}
                    type="text"
                    error={error}
                    helperText={helperText}
                />
            )}
            value={value}
            onChange={(e, newValue) => {
                e.target = {
                    name: name,
                    value: newValue,
                };
                //handleChange(e);
                handleChangeFeature(e);
            }}
            disabled={disabled}
        />
    );
}
