import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setAccountShared } from "../../actions";
import AccountSharedForm from "../../components/AccessAccountPopUp/AccountSharedForm";

/**
 * Container to display account shared form
 * @param setValid change form validity
 * @param inscription redux store data of register
 */
function AccountSharedFormContainer({ setValid, inscription }) {
	const dispatch = useDispatch();
	const callback = useCallback(
		(accountShared) => {
			dispatch(setAccountShared(accountShared));
		},
		[dispatch]
	);

	const accountShared = inscription.accountShared;

	// we want city to work with CityAutoComplete - needs to be an object temporary
	if (accountShared.city === "") {
		accountShared.city = null;
	}

	return (
		<AccountSharedForm
			accountSharedValue={accountShared}
			onChange={callback}
			setValid={setValid}
		/>
	);
}

export default AccountSharedFormContainer;
