/**
 * fetch information of professionnel independant from EAPS official website
 * @param {*} url url profil EAPS
 * @returns
 */
const getProIndeInfoFromEapsAPI = (url) => {
    return new Promise((resolve, reject) => {
        const request = new Request(
            `${process.env.REACT_APP_API_URL}/user/eaps/getData`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ url }),
            }
        );

        fetch(request)
            .then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        resolve({ data: json.data });
                    } else {
                        reject(json.error);
                        console.error(json.error);
                    }
                });
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    });
};

export default getProIndeInfoFromEapsAPI;
