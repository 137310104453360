import { v4 as uuidv4 } from "uuid";

// Call a mtehod threw the socket
function callMethod(method, data, socket) {
	return new Promise((resolve, reject) => {
		if (!socket) {
			reject("No socket connection.");
		} else {
			// generate an id, it allow us to get the response of this request specifically
			const id = uuidv4();
			// wait for the server to respond with the id
			socket.on(id, (res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject(res);
				}
			});

			// call the method
			socket.emit(method, { ...data, id });
		}
	});
}

export default callMethod;
