import React from "react";
import APIDriven from "./APIDriven";
import {AllCountryCode, countryTypes} from '../../constants/typeToText'
import {Box, ListItem, ListItemText} from "@mui/material";
/**
 * Component input to autocomplete city
 * @param city city value
 * @param setCity callback to call when value change
 * @param displayCountry if true, display the country
 * @param label input label
 * @param countryValue country selected
 * @param onHomeView Filter is display on HomePage or on form create and update
 * @param createAnnouncement if this display use on form to create or update announcement
 * @param errorFormAd stock all error of form
 * @param helperText
 * @param setErrorFormAd set all error of form
 */
function CityAutocomplete({
	city,
	setCity,
	displayCountry = false,
	label = null,
	countryValue,
	onHomeView = false,
	createAnnouncement = false,
	errorFormAd = false,
	helperText = null,
	setErrorFormAd = null
}) {
	const onAutocomplete = (text, user, setOptions, setLoading, controller) => {

		setLoading(true);
		controller.current.abort();
		controller.current = new AbortController();
		let codeCountry = '';
		if (countryValue.length > 0){
			let countryArray = AllCountryCode.filter((country) => {
				return countryValue.includes(country.value)
			})
			countryArray.forEach((data, index) => {
				if (index+1 === countryArray.length){
					codeCountry += data.code;
				}else {
					codeCountry += data.code+',';
				}
			})
		}else{
			countryTypes.forEach((data, index) => {
				if (index+1 === countryTypes.length){
					codeCountry += data.code;
				}else {
					codeCountry += data.code+',';
				}
			})
		}

		const request = new Request(
			`${process.env.REACT_APP_API_URL}/api/geo/autocomplete/city`,
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: user.token,
				},
				body: JSON.stringify({
					cp: text,
					country: countryValue,
					countryCode: codeCountry,
				}),
				signal: controller.current.signal,
			}
		);
		const requestWithAdress = new Request(
			`${process.env.REACT_APP_API_URL}/api/geo/autocomplete/adresse`,
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					cp: text,
					country: countryValue.length > 0 ? countryValue : ["France"],
					countryCode: codeCountry,
				}),
				signal: controller.current.signal,
			}
		);
		let uri ;
		if (createAnnouncement) {
			uri = requestWithAdress;
		} else {
			uri = request
		}

		fetch(uri)
			.then((res) => {
				res.json().then((json) => {
					if (json.features) {
						setOptions(json.features);
						setLoading(false);
					} else {
						setOptions([]);
						setLoading(false);
					}
				});
			})
			.catch((err) => console.error(err));
	};

	return (
		<div>
			<Box sx={{display: onHomeView ? "block" : {xs:"none", md: "block"}}}>
				<APIDriven
					label={createAnnouncement ? "Adresse de votre activité *" : "Code Postal / Ville"}
					value={city}
					setValue={setCity}
					onAutocomplete={onAutocomplete}
					getOptionSelected={(option, value) =>
						createAnnouncement ?
							option.properties.label  === value.properties.label:
							option.properties.city === value.properties.city
					}
					getOptionLabel={(option) =>
						createAnnouncement ?
							option.properties.label :
							option.properties.postcode + " " + option.properties.city + (displayCountry ? ", " + option.properties.country : "")
					}
					onHomeView={onHomeView}
					errorFormAd={errorFormAd}
					helperText={helperText}
					setErrorFormAd={setErrorFormAd}
				/>
			</Box>
			<Box sx={{display: onHomeView ? "none" : {xs:"block", md: "none"}}}>
				<ListItem sx={{display: "block"}}>
					<ListItemText primary="Code postal" />
					<APIDriven
						label={label ?? "Code postal"}
						value={city}
						setValue={setCity}
						onAutocomplete={onAutocomplete}
						getOptionSelected={(option, value) =>
							option.properties.city === value.properties.city
						}
						getOptionLabel={(option) => option.properties.postcode + " " + option.properties.city + (displayCountry ? ", " + option.properties.country : "")}
						onHomeView={onHomeView}
					/>
				</ListItem>
			</Box>
		</div>

	);
}

export default CityAutocomplete;
