import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import App from "./App";
import store from "./reducers";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import "./general.css";
import "./i18n";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	// Redux
	<Provider store={store}>
		{/* MUI Theme */}
		<ThemeProvider theme={theme}>
			{/* Snackbars */}
			<SnackbarProvider maxSnack={3}>
				{/* Content */}
				<App />
			</SnackbarProvider>
		</ThemeProvider>
	</Provider>
);

serviceWorker.unregister();
