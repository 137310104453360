import {
	Container,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import React from "react";
import { Link } from "react-router-dom";
import {
	ROUTE_COOKIE_POLICY,
	ROUTE_FAQ,
	ROUTE_PRIVACY_POLICY,
	ROUTE_RIGHTS_AND_OBLIGATIONS,
	ROUTE_TERMS_OF_SALE,
	ROUTE_TERMS_OF_USE
} from "../../config/routesConstant";

const PREFIX = 'Footer';

const classes = {
    root: `${PREFIX}-root`,
    footerItem: `${PREFIX}-footerItem`,
    typoSubtitle: `${PREFIX}-typoSubtitle`,
    typo: `${PREFIX}-typo`,
    copyright: `${PREFIX}-copyright`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
		width: "100%",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
	},

    [`& .${classes.footerItem}`]: {
		[theme.breakpoints.down('md')]: {
			marginBottom: theme.spacing(4),
		},
	},

    [`& .${classes.typoSubtitle}`]: {
		fontWeight: "Bold",
	},

    [`& .${classes.typo}`]: {
		fontWeight: 200,
		fontSize: 16,
		whiteSpace: "nowrap",
		color: "white",
	},

    [`& .${classes.copyright}`]: {
		[theme.breakpoints.up("md")]: {
			marginTop: theme.spacing(6),
		},
		fontSize: 16,
		textAlign: "center",
	}
}));

/**
 * Component for the footer on the website
 * @returns
 */
function Footer() {

	return (
        <Root
			sx={{
				width: '100%', backgroundColor: '#2ab1ea',
				color: 'white', pt: 6, pb: 6, position: "relative"
			}}
			id="footer"
		>
			<Container>
				<Grid container>
					<Grid
						className={classes.footerItem}
						item
						xs={12}
						md
						container
						direction="column"
						alignItems="center"
					>
						<Grid item>
							<Typography className={classes.typoSubtitle}>
								NOUS CONTACTER
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								contactwgy@gmail.com
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
							06 66 00 96 59
							</Typography>
						</Grid>
					</Grid>
					<Grid
						className={classes.footerItem}
						item
						xs={12}
						md
						container
						direction="column"
						alignItems="center"
					>
						<Grid item>
							<Typography className={classes.typoSubtitle}>
								ASSISTANCE
							</Typography>
						</Grid>
						<Grid item>
							<Link to={ROUTE_FAQ + "/premiers-pas-sur-WGY"}>
								<Typography className={classes.typo}>
									FAQ
								</Typography>
							</Link>
						</Grid>
						<Grid item>
							<Link to={ROUTE_RIGHTS_AND_OBLIGATIONS}>
								<Typography className={classes.typo}>
									Vos droits et obligations
								</Typography>
							</Link>
						</Grid>
						<Grid item>
							<Link to={ROUTE_TERMS_OF_USE}>
								<Typography className={classes.typo}>
									Conditions Générales d'Utilisation
								</Typography>
							</Link>
						</Grid>
						<Grid item>
							<Link to={ROUTE_TERMS_OF_SALE}>
								<Typography className={classes.typo}>
									Conditions Générales de Vente
								</Typography>
							</Link>
						</Grid>
						<Grid item>
							<Link to={ROUTE_COOKIE_POLICY}>
								<Typography className={classes.typo}>
									Politique des cookies
								</Typography>
							</Link>
						</Grid>

						<Grid item>
							<Link to={ROUTE_PRIVACY_POLICY}>
								<Typography className={classes.typo}>
									Charte de Confidentialité
								</Typography>
							</Link>

						</Grid>


					</Grid>
					<Grid
						className={classes.footerItem}
						item
						xs={12}
						md
						container
						direction="column"
						alignItems="center"
					>
						<Grid item>
							<Typography className={classes.typoSubtitle}>
								ACTUALITÉ
							</Typography>
						</Grid>
						<Grid item>
							<Link to={"/blog"}>
								<Typography className={classes.typo}>
									Blog
								</Typography>
							</Link>
						</Grid>
						<Grid item sx={{ mt: 2}}>
							<Typography className={classes.typoSubtitle}>
								SUIVEZ-NOUS
							</Typography>
						</Grid>

						<Grid item container justifyContent="center">
							<IconButton
                                color={"inherit"}
                                onClick={() =>
									window.open(
										`https://www.facebook.com/weguideyousport`
									)
								}
                                size="large">
								<FacebookIcon />
							</IconButton>
							<IconButton
                                color={"inherit"}
                                onClick={() =>
									window.open(
										`https://www.instagram.com/weguideyousport`
									)
								}
                                size="large">
								<InstagramIcon />
							</IconButton>
							<IconButton
                                color={"inherit"}
                                onClick={() =>
									window.open(
										`https://twitter.com/WeGuideYouSport`
									)
								}
                                size="large">
								<TwitterIcon />
							</IconButton>
							<IconButton
								color={"inherit"}
								onClick={() =>
									window.open(
										`https://www.linkedin.com/company/we-guide-you`
									)
								}
								size="large">
								<LinkedInIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item container xs={12} justifyContent="center">
						<Typography className={classes.copyright}>
							© 2020 - {new Date().getFullYear()} WeGuideYou
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Root>
    );
}

export default Footer;
